mat-card {
  background-color: #fff;
  border: 1px solid #e4e9f2;
  border-radius: 0.17rem !important;
  box-shadow: none !important;
  color: #555 !important;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.25rem;
  margin-bottom: 1.875rem;
  scrollbar-face-color: #e4e9f2;
  scrollbar-track-color: #f7f9fc;
  padding: 0 !important;
  overflow: hidden;

  .mat-card-title {
    font-weight: 700;
    font-family: Vorkurs;
    line-height: 35px;
    color: var(--secondary);
  }

  mat-card-header {
    padding: 1rem 1.25rem;
    border-bottom: 1px solid #edf1f7;
    border-top-left-radius: 0.17rem;
    border-top-right-radius: 0.17rem;

    & > .mat-card-header-text {
      width: 100% !important;
      margin: 0 !important;
    }

    .action-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .mat-card-title {
      margin: 0 !important;
      color: #3f3f3f;
      // font-family: Open Sans, sans-serif;
      font-size: 0.9375rem !important;
      font-weight: 600;
      line-height: 1.5rem;
      font-family: Vorkurs;
      .action-buttons-header {
        button {
          margin: 0 5px;
          width: 64px;
        }
      }
    }
  }

  .mat-card-content {
    flex: 1;
    -ms-flex: 1 1 auto;
    overflow: auto;
    padding: 1rem 1.25rem;
    position: relative;
    margin: 0 !important;
    scrollbar-face-color: #e4e9f2;
    scrollbar-track-color: #f7f9fc;
  }

  .mat-card-actions {
    padding: 0 !important;
    border-top: 1px solid #edf1f7;
    border-bottom-left-radius: 0.17rem;
    border-bottom-right-radius: 0.17rem;
    margin: 0 !important;
  }

  &.card-select {
    display: flex;
    padding: 0 !important;
    margin: 0;
    border: none;
    height: 80px;

    .item1 {
      padding: 0 10px 0 0;
    }

    .item2 {
      padding: 0 0 0 10px;
    }

    > div {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
    }

    @media only screen and (max-width: 767.98px) {
      flex-direction: column;
      height: auto;

      .item1,
      .item2 {
        padding: 0 !important;
      }
    }
  }
}

@media only screen and (max-width: 767.98px) {
  mat-card {
    mat-card-header {
      .action-header {
        flex-direction: column;

        .action-buttons-header {
          margin-top: 10px;
        }
      }
    }
  }

  .action-buttons-header {
    button {
      margin: 5px;
    }
  }
}
