@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
/* Colors for the ripple elements.*/
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
@import "~@fortawesome/fontawesome-free/css/all.css";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 800 35px/35px Vorkurs;
  letter-spacing: 0em;
  margin: 0 0 16px;
}

.mat-h2, .mat-title, .mat-typography h2 {
  font: 700 28px/32px Vorkurs;
  letter-spacing: 0.0075em;
  margin: 0 0 16px;
}

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 500 16px/28px Vorkurs;
  letter-spacing: 0.0094em;
  margin: 0 0 16px;
}

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 700 15px/24px Vorkurs;
  letter-spacing: 0.0067em;
  margin: 0 0 16px;
}

.mat-h5, .mat-typography h5 {
  font: 400 calc(14px * 0.83)/20px Montserrat;
  margin: 0 0 12px;
}

.mat-h6, .mat-typography h6 {
  font: 400 calc(14px * 0.67)/20px Montserrat;
  margin: 0 0 12px;
}

.mat-body-strong, .mat-body-2 {
  font: 500 14px/24px Montserrat;
  letter-spacing: 0.0179em;
}

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px/20px Montserrat;
  letter-spacing: 0.0179em;
}
.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 12px;
}

.mat-small, .mat-caption {
  font: 500 12px/20px Vorkurs;
  letter-spacing: 0.0333em;
}

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px/112px Montserrat;
  letter-spacing: -0.0134em;
  margin: 0 0 56px;
}

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px/56px Montserrat;
  letter-spacing: -0.0089em;
  margin: 0 0 64px;
}

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px/48px Montserrat;
  letter-spacing: 0em;
  margin: 0 0 64px;
}

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px/40px Montserrat;
  letter-spacing: 0.0074em;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px/20px Montserrat;
  letter-spacing: 0.0179em;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Vorkurs;
  font-size: 14px;
  font-weight: 500;
}

.mat-card-title {
  font-size: 35px;
  font-weight: 700;
}

.mat-card-header .mat-card-title {
  font-size: 28px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 700 28px/32px Vorkurs;
  letter-spacing: 0.0075em;
}

.mat-expansion-panel-header {
  font-family: Vorkurs;
  font-size: 15px;
  font-weight: 700;
}

.mat-expansion-panel-content {
  font: 400 14px/20px Montserrat;
  letter-spacing: 0.0179em;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 500;
  line-height: 1.125;
  font-family: Vorkurs;
  letter-spacing: 1.5px;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Vorkurs;
  font-size: 12px;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slider-thumb-label-text {
  font-size: 12px;
  font-weight: 500;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-label, .mat-tab-link {
  font-family: Vorkurs;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 700 28px/32px Vorkurs;
  letter-spacing: 0.0075em;
  margin: 0;
}

.mat-tooltip {
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px/24px Montserrat;
  letter-spacing: 0.0179em;
}

.mat-simple-snackbar {
  font-family: Montserrat;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

.mat-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator {
  position: relative;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff5a3a;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #032578;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f80606;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #ff5a3a;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #032578;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f80606;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge-content {
  color: white;
  background: #ff5a3a;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #032578;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #f80606;
}

.mat-badge {
  position: relative;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #ff5a3a;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #032578;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #f80606;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #ff5a3a;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #032578;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f80606;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #ff5a3a;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #032578;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #f80606;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone,
.mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #ff5a3a;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #032578;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f80606;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #ff5a3a;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #032578;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f80606;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #ff5a3a;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f80606;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #032578;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(255, 90, 58, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 90, 58, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 90, 58, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #ff5a3a;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 90, 58, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 90, 58, 0.3);
}

.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(3, 37, 120, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(3, 37, 120, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(3, 37, 120, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #032578;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(3, 37, 120, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(3, 37, 120, 0.3);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(248, 6, 6, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(248, 6, 6, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(248, 6, 6, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f80606;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(248, 6, 6, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(248, 6, 6, 0.3);
}

.mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #ff5a3a;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #032578;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #f80606;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #ff5a3a;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #032578;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f80606;
}

.mat-focused .mat-form-field-required-marker {
  color: #032578;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #ff5a3a;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #032578;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f80606;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #ff5a3a;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #032578;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f80606;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f80606;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f80606;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f80606;
}

.mat-error {
  color: #f80606;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #ff5a3a;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #032578;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f80606;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f80606;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
  color: #ff5a3a;
}
.mat-icon.mat-accent {
  color: #032578;
}
.mat-icon.mat-warn {
  color: #f80606;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #ff5a3a;
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #032578;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #f80606;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f80606;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}

.mat-list-item-disabled {
  background-color: #eeeeee;
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled], .mat-menu-item[disabled]::after,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-progress-bar-background {
  fill: #ffcec4;
}

.mat-progress-bar-buffer {
  background-color: #ffcec4;
}

.mat-progress-bar-fill::after {
  background-color: #ff5a3a;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #b3bed7;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #b3bed7;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #032578;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #fdb5b5;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #fdb5b5;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f80606;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #ff5a3a;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #032578;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #f80606;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff5a3a;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #ff5a3a;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #032578;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #032578;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f80606;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f80606;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #ff5a3a;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #032578;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f80606;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f80606;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #032578;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(3, 37, 120, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #032578;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff5a3a;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 90, 58, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #ff5a3a;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f80606;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(248, 6, 6, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f80606;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-primary .mat-slider-track-fill,
.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label {
  background-color: #ff5a3a;
}
.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.mat-primary .mat-slider-focus-ring {
  background-color: rgba(255, 90, 58, 0.2);
}

.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
  background-color: #032578;
}
.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.mat-accent .mat-slider-focus-ring {
  background-color: rgba(3, 37, 120, 0.2);
}

.mat-warn .mat-slider-track-fill,
.mat-warn .mat-slider-thumb,
.mat-warn .mat-slider-thumb-label {
  background-color: #f80606;
}
.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-warn .mat-slider-focus-ring {
  background-color: rgba(248, 6, 6, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-disabled .mat-slider-track-background,
.mat-slider-disabled .mat-slider-track-fill,
.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #ff5a3a;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #032578;
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f80606;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f80606;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #f80606;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 206, 196, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #ff5a3a;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 190, 215, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #032578;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(253, 181, 181, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f80606;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 206, 196, 0.3);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #ff5a3a;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-links .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-links .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 190, 215, 0.3);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #032578;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-links .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-links .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(253, 181, 181, 0.3);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f80606;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-links .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-links .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #ff5a3a;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #032578;
  color: white;
}
.mat-toolbar.mat-warn {
  background: #f80606;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }

  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #032578;
}

/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #ff5a3a;
  --secondary: #032578;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #f80606;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: Montserrat;
  --font-family-monospace: Montserrat;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #666;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #ff5a3a;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #ed2600;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: Montserrat;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 800;
  line-height: 1.2;
  color: #032578;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #666;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #666;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #ffd1c8;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #ffa999;
}

.table-hover .table-primary:hover {
  background-color: #ffbcaf;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #ffbcaf;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #b8c2d9;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #7c8eb9;
}

.table-hover .table-secondary:hover {
  background-color: #a7b4d0;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #a7b4d0;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fdb9b9;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #fb7e7e;
}

.table-hover .table-danger:hover {
  background-color: #fca0a0;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #fca0a0;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #ffc5ba;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 90, 58, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #666;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f80606;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(248, 6, 6, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #f80606;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23f80606' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f80606' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #f80606;
  box-shadow: 0 0 0 0.2rem rgba(248, 6, 6, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #f80606;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23f80606' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f80606' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #f80606;
  box-shadow: 0 0 0 0.2rem rgba(248, 6, 6, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #f80606;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #f80606;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #f80606;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #fa3737;
  background-color: #fa3737;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(248, 6, 6, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #f80606;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #f80606;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #f80606;
  box-shadow: 0 0 0 0.2rem rgba(248, 6, 6, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #666;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #666;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 90, 58, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #ff5a3a;
  border-color: #ff5a3a;
}
.btn-primary:hover {
  color: #fff;
  background-color: #ff3a14;
  border-color: #ff2f07;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #ff3a14;
  border-color: #ff2f07;
  box-shadow: 0 0 0 0.2rem rgba(255, 115, 88, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #ff5a3a;
  border-color: #ff5a3a;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #ff2f07;
  border-color: #f92800;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 115, 88, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #032578;
  border-color: #032578;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #021953;
  border-color: #021646;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #021953;
  border-color: #021646;
  box-shadow: 0 0 0 0.2rem rgba(41, 70, 140, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #032578;
  border-color: #032578;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #021646;
  border-color: #01123a;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(41, 70, 140, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #f80606;
  border-color: #f80606;
}
.btn-danger:hover {
  color: #fff;
  background-color: #d30505;
  border-color: #c60505;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #d30505;
  border-color: #c60505;
  box-shadow: 0 0 0 0.2rem rgba(249, 43, 43, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #f80606;
  border-color: #f80606;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c60505;
  border-color: #ba0404;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(249, 43, 43, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #ff5a3a;
  border-color: #ff5a3a;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #ff5a3a;
  border-color: #ff5a3a;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 90, 58, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #ff5a3a;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #ff5a3a;
  border-color: #ff5a3a;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 90, 58, 0.5);
}

.btn-outline-secondary {
  color: #032578;
  border-color: #032578;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #032578;
  border-color: #032578;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(3, 37, 120, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #032578;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #032578;
  border-color: #032578;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(3, 37, 120, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #f80606;
  border-color: #f80606;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #f80606;
  border-color: #f80606;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 6, 6, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #f80606;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #f80606;
  border-color: #f80606;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 6, 6, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #ff5a3a;
  text-decoration: none;
}
.btn-link:hover {
  color: #ed2600;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #666;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #ff5a3a;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #ff5a3a;
  background-color: #ff5a3a;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(255, 90, 58, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ffc5ba;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #fff0ed;
  border-color: #fff0ed;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #ff5a3a;
  background-color: #ff5a3a;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(255, 90, 58, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(255, 90, 58, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(255, 90, 58, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(255, 90, 58, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #ffc5ba;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 90, 58, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #ffc5ba;
  box-shadow: 0 0 0 0.2rem rgba(255, 90, 58, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(255, 90, 58, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(255, 90, 58, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(255, 90, 58, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #ff5a3a;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #fff0ed;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #ff5a3a;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #fff0ed;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #ff5a3a;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #fff0ed;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #ff5a3a;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjgiPjxwYXRoIGQ9Ik0yLjUgMEwxIDEuNSAzLjUgNCAxIDYuNSAyLjUgOGw0LTQtNC00eiIgZmlsbD0iY3VycmVudENvbG9yIi8+PC9zdmc+");
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #ff5a3a;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #ed2600;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 90, 58, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #ff5a3a;
  border-color: #ff5a3a;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #ff5a3a;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #ff2f07;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 90, 58, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #032578;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #021646;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(3, 37, 120, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #f80606;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #c60505;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 6, 6, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #852f1e;
  background-color: #ffded8;
  border-color: #ffd1c8;
}
.alert-primary hr {
  border-top-color: #ffbcaf;
}
.alert-primary .alert-link {
  color: #5b2015;
}

.alert-secondary {
  color: #02133e;
  background-color: #cdd3e4;
  border-color: #b8c2d9;
}
.alert-secondary hr {
  border-top-color: #a7b4d0;
}
.alert-secondary .alert-link {
  color: #00040d;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #810303;
  background-color: #fecdcd;
  border-color: #fdb9b9;
}
.alert-danger hr {
  border-top-color: #fca0a0;
}
.alert-danger .alert-link {
  color: #4f0202;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #ff5a3a;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #666;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #ff5a3a;
  border-color: #ff5a3a;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #852f1e;
  background-color: #ffd1c8;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #852f1e;
  background-color: #ffbcaf;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #852f1e;
  border-color: #852f1e;
}

.list-group-item-secondary {
  color: #02133e;
  background-color: #b8c2d9;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #02133e;
  background-color: #a7b4d0;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #02133e;
  border-color: #02133e;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #810303;
  background-color: #fdb9b9;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #810303;
  background-color: #fca0a0;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #810303;
  border-color: #810303;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #032578;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #666;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #ff5a3a !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #ff2f07 !important;
}

.bg-secondary {
  background-color: #032578 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #021646 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #f80606 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #c60505 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #ff5a3a !important;
}

.border-secondary {
  border-color: #032578 !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #f80606 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: Montserrat !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #ff5a3a !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #ed2600 !important;
}

.text-secondary {
  color: #032578 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #010e2d !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #f80606 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #ad0404 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #666 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
@keyframes mdc-ripple-fg-radius-in {
  from {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1);
  }
  to {
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
  }
}
@keyframes mdc-ripple-fg-opacity-in {
  from {
    animation-timing-function: linear;
    opacity: 0;
  }
  to {
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
}
@keyframes mdc-ripple-fg-opacity-out {
  from {
    animation-timing-function: linear;
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
  to {
    opacity: 0;
  }
}
.mdc-slider__thumb {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}

.mdc-slider__thumb::before, .mdc-slider__thumb::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-slider__thumb::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}

.mdc-slider__thumb::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}

.mdc-slider__thumb.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-slider__thumb.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.mdc-slider__thumb.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}

.mdc-slider__thumb.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.mdc-slider__thumb.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-slider__thumb::before, .mdc-slider__thumb::after {
  top: calc(50% - 50%);
  /* @noflip */
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}

.mdc-slider__thumb.mdc-ripple-upgraded::before, .mdc-slider__thumb.mdc-ripple-upgraded::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-slider__thumb.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-slider__thumb::before, .mdc-slider__thumb::after {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #6200ee));
}

.mdc-slider__thumb:hover::before, .mdc-slider__thumb.mdc-ripple-surface--hover::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

.mdc-slider__thumb.mdc-ripple-upgraded--background-focused::before, .mdc-slider__thumb:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

.mdc-slider__thumb:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}

.mdc-slider__thumb:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-slider__thumb.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-slider {
  cursor: pointer;
  height: 48px;
  margin: 0 24px;
  position: relative;
  touch-action: pan-y;
}

.mdc-slider .mdc-slider__track {
  height: 4px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.mdc-slider .mdc-slider__track--active,
.mdc-slider .mdc-slider__track--inactive {
  display: flex;
  height: 100%;
  position: absolute;
  width: 100%;
}

.mdc-slider .mdc-slider__track--active {
  border-radius: 3px;
  height: 6px;
  overflow: hidden;
  top: -1px;
}

.mdc-slider .mdc-slider__track--active_fill {
  border-top: 6px solid;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  position: relative;
  /* @noflip */
  -webkit-transform-origin: left;
  /* @noflip */
  transform-origin: left;
}

[dir=rtl] .mdc-slider .mdc-slider__track--active_fill, .mdc-slider .mdc-slider__track--active_fill[dir=rtl] {
  /* @noflip */
  -webkit-transform-origin: right;
  /* @noflip */
  transform-origin: right;
}

.mdc-slider .mdc-slider__track--inactive {
  border-radius: 2px;
  height: 4px;
  left: 0;
  top: 0;
}

.mdc-slider .mdc-slider__track--inactive::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}

.mdc-slider .mdc-slider__track--active_fill {
  border-color: #6200ee;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #6200ee);
}

.mdc-slider.mdc-slider--disabled .mdc-slider__track--active_fill {
  border-color: #000;
  /* @alternate */
  border-color: var(--mdc-theme-on-surface, #000);
}

.mdc-slider .mdc-slider__track--inactive {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #6200ee);
  opacity: 0.24;
}

.mdc-slider.mdc-slider--disabled .mdc-slider__track--inactive {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-theme-on-surface, #000);
  opacity: 0.24;
}

.mdc-slider .mdc-slider__value-indicator-container {
  bottom: 44px;
  /* @noflip */
  left: 50%;
  pointer-events: none;
  position: absolute;
  transform: translateX(-50%);
}

.mdc-slider .mdc-slider__value-indicator {
  transition: transform 100ms 0ms cubic-bezier(0.4, 0, 1, 1);
  align-items: center;
  border-radius: 4px;
  display: flex;
  height: 32px;
  padding: 0 12px;
  transform: scale(0);
  transform-origin: bottom;
}

.mdc-slider .mdc-slider__value-indicator::before {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid;
  bottom: -5px;
  content: "";
  height: 0;
  /* @noflip */
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 0;
}

.mdc-slider .mdc-slider__value-indicator::after {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}

.mdc-slider .mdc-slider__thumb--with-indicator .mdc-slider__value-indicator-container {
  pointer-events: auto;
}

.mdc-slider .mdc-slider__thumb--with-indicator .mdc-slider__value-indicator {
  transition: transform 100ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(1);
}

@media (prefers-reduced-motion) {
  .mdc-slider .mdc-slider__value-indicator,
.mdc-slider .mdc-slider__thumb--with-indicator .mdc-slider__value-indicator {
    transition: none;
  }
}
.mdc-slider .mdc-slider__value-indicator-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle2-font-size, 0.875rem);
  line-height: 1.375rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle2-line-height, 1.375rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: 0.0071428571em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, 0.0071428571em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle2-text-transform, inherit);
}

.mdc-slider .mdc-slider__value-indicator {
  background-color: #000;
  opacity: 0.6;
}

.mdc-slider .mdc-slider__value-indicator::before {
  border-top-color: #000;
}

.mdc-slider .mdc-slider__value-indicator {
  color: #fff;
  /* @alternate */
  color: var(--mdc-theme-on-primary, #fff);
}

.mdc-slider .mdc-slider__thumb {
  display: flex;
  height: 48px;
  /* @noflip */
  left: -24px;
  outline: none;
  position: absolute;
  user-select: none;
  width: 48px;
}

.mdc-slider .mdc-slider__thumb--top {
  z-index: 1;
}

.mdc-slider .mdc-slider__thumb--top .mdc-slider__thumb-knob, .mdc-slider .mdc-slider__thumb--top.mdc-slider__thumb:hover .mdc-slider__thumb-knob, .mdc-slider .mdc-slider__thumb--top.mdc-slider__thumb--focused .mdc-slider__thumb-knob {
  border: 1px solid;
  box-sizing: content-box;
  border-color: #fff;
}

.mdc-slider .mdc-slider__thumb-knob {
  /* @alternate */
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border: 10px solid;
  border-radius: 50%;
  box-sizing: border-box;
  height: 20px;
  /* @noflip */
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
}

.mdc-slider .mdc-slider__thumb-knob {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #6200ee);
  border-color: #6200ee;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #6200ee);
}

.mdc-slider.mdc-slider--disabled .mdc-slider__thumb-knob {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-theme-on-surface, #000);
  border-color: #000;
  /* @alternate */
  border-color: var(--mdc-theme-on-surface, #000);
}

.mdc-slider .mdc-slider__thumb::before, .mdc-slider .mdc-slider__thumb::after {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #6200ee));
}

.mdc-slider .mdc-slider__thumb:hover::before, .mdc-slider .mdc-slider__thumb.mdc-ripple-surface--hover::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

.mdc-slider .mdc-slider__thumb.mdc-ripple-upgraded--background-focused::before, .mdc-slider .mdc-slider__thumb:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

.mdc-slider .mdc-slider__thumb:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}

.mdc-slider .mdc-slider__thumb:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-slider .mdc-slider__thumb.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-slider .mdc-slider__tick-marks {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 0 1px;
  position: absolute;
  width: 100%;
}

.mdc-slider .mdc-slider__tick-mark--active,
.mdc-slider .mdc-slider__tick-mark--inactive {
  border-radius: 50%;
  height: 2px;
  width: 2px;
}

.mdc-slider .mdc-slider__tick-mark--active {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-on-primary, #fff);
  opacity: 0.6;
}

.mdc-slider.mdc-slider--disabled .mdc-slider__tick-mark--active {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-on-primary, #fff);
  opacity: 0.6;
}

.mdc-slider .mdc-slider__tick-mark--inactive {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #6200ee);
  opacity: 0.6;
}

.mdc-slider.mdc-slider--disabled .mdc-slider__tick-mark--inactive {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-theme-on-surface, #000);
  opacity: 0.6;
}

.mdc-slider.mdc-slider--disabled {
  opacity: 0.38;
  cursor: auto;
}

.mdc-slider.mdc-slider--disabled .mdc-slider__thumb {
  pointer-events: none;
}

.mdc-slider--discrete .mdc-slider__thumb,
.mdc-slider--discrete .mdc-slider__track--active_fill {
  transition: transform 80ms ease;
}

@media (prefers-reduced-motion) {
  .mdc-slider--discrete .mdc-slider__thumb,
.mdc-slider--discrete .mdc-slider__track--active_fill {
    transition: none;
  }
}
.mdc-slider__input {
  cursor: pointer;
  left: 0;
  margin: 0;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}

mat-card {
  background-color: #fff;
  border: 1px solid #e4e9f2;
  border-radius: 0.17rem !important;
  box-shadow: none !important;
  color: #555 !important;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.25rem;
  margin-bottom: 1.875rem;
  scrollbar-face-color: #e4e9f2;
  scrollbar-track-color: #f7f9fc;
  padding: 0 !important;
  overflow: hidden;
}
mat-card .mat-card-title {
  font-weight: 700;
  font-family: Vorkurs;
  line-height: 35px;
  color: var(--secondary);
}
mat-card mat-card-header {
  padding: 1rem 1.25rem;
  border-bottom: 1px solid #edf1f7;
  border-top-left-radius: 0.17rem;
  border-top-right-radius: 0.17rem;
}
mat-card mat-card-header > .mat-card-header-text {
  width: 100% !important;
  margin: 0 !important;
}
mat-card mat-card-header .action-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
mat-card mat-card-header .mat-card-title {
  margin: 0 !important;
  color: #3f3f3f;
  font-size: 0.9375rem !important;
  font-weight: 600;
  line-height: 1.5rem;
  font-family: Vorkurs;
}
mat-card mat-card-header .mat-card-title .action-buttons-header button {
  margin: 0 5px;
  width: 64px;
}
mat-card .mat-card-content {
  flex: 1;
  -ms-flex: 1 1 auto;
  overflow: auto;
  padding: 1rem 1.25rem;
  position: relative;
  margin: 0 !important;
  scrollbar-face-color: #e4e9f2;
  scrollbar-track-color: #f7f9fc;
}
mat-card .mat-card-actions {
  padding: 0 !important;
  border-top: 1px solid #edf1f7;
  border-bottom-left-radius: 0.17rem;
  border-bottom-right-radius: 0.17rem;
  margin: 0 !important;
}
mat-card.card-select {
  display: flex;
  padding: 0 !important;
  margin: 0;
  border: none;
  height: 80px;
}
mat-card.card-select .item1 {
  padding: 0 10px 0 0;
}
mat-card.card-select .item2 {
  padding: 0 0 0 10px;
}
mat-card.card-select > div {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}
@media only screen and (max-width: 767.98px) {
  mat-card.card-select {
    flex-direction: column;
    height: auto;
  }
  mat-card.card-select .item1,
mat-card.card-select .item2 {
    padding: 0 !important;
  }
}

@media only screen and (max-width: 767.98px) {
  mat-card mat-card-header .action-header {
    flex-direction: column;
  }
  mat-card mat-card-header .action-header .action-buttons-header {
    margin-top: 10px;
  }

  .action-buttons-header button {
    margin: 5px;
  }
}
.element-inline {
  display: flex;
  align-items: center;
}
.element-inline h3 {
  margin-bottom: 0 !important;
}
.element-inline mat-form-field,
.element-inline mat-slide-toggle {
  flex: 1;
}
.element-inline mat-form-field + mat-slide-toggle,
.element-inline mat-form-field + mat-form-field {
  margin-left: 15px;
}
@media screen and (max-width: 767.98px) {
  .element-inline {
    flex-direction: column;
  }
  .element-inline mat-form-field + mat-slide-toggle,
.element-inline mat-form-field + mat-form-field {
    margin-left: 0;
  }
}

mat-form-field {
  width: 100%;
}
mat-form-field .mat-form-field-wrapper {
  padding-bottom: -10px;
}

mat-card.grid-filters {
  margin-bottom: 0px;
}
mat-card .mat-form-field-flex > .mat-form-field-infix {
  padding: 0.4em 0px !important;
  height: 53px;
}
mat-card .mat-form-field-flex .mat-form-field-suffix svg:not(.mat-datepicker-toggle-default-icon) {
  width: 1rem;
  fill: rgba(0, 0, 0, 0.54);
  position: absolute;
  bottom: -5px;
  left: -11px;
}
mat-card .mat-form-field-flex .mat-form-field-suffix mat-icon {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.54);
  position: absolute;
  bottom: -11px;
  left: -18px;
}
mat-card .mat-form-field-wrapper {
  padding-bottom: 0.5em;
}
mat-card .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  transform: translateY(-1.1em) scale(0.75);
  width: 133.33333%;
}
mat-card mat-card-actions {
  text-align: right;
}

.timepicker__header {
  background: #032578;
  background: linear-gradient(60deg, #032578 0%, #ff5a3a 100%);
}

.clock-face__clock-hand {
  background-color: #ff5a3a !important;
}
.clock-face__clock-hand::before {
  border: 4px solid #ff5a3a !important;
}

.clock-face__number .active {
  background-color: #ff5a3a !important;
}

.timepicker-button,
.clock-hand-color {
  color: #ff5a3a !important;
}

.element-inline {
  display: flex;
  align-items: center;
}
.element-inline h3 {
  margin-bottom: 0 !important;
}
.element-inline mat-form-field,
.element-inline mat-slide-toggle {
  flex: 1;
}
.element-inline mat-form-field + mat-slide-toggle,
.element-inline mat-form-field + mat-form-field {
  margin-left: 15px;
}
@media screen and (max-width: 767.98px) {
  .element-inline {
    flex-direction: column;
  }
  .element-inline mat-form-field + mat-slide-toggle,
.element-inline mat-form-field + mat-form-field {
    margin-left: 0;
  }
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.no-padding {
  padding: 0 !important;
}

html,
body {
  width: 100%;
  height: 100%;
}

main {
  background: white;
}

header .mat-expansion-panel-header-description,
header .mat-expansion-indicator::after {
  color: white;
}

.mat-tab-label-content {
  font-family: "Vorkurs";
}

body main .mat-sidenav-content {
  background: white;
}
body app-header {
  position: relative;
  z-index: 9;
}
body app-header .expansion-body {
  margin: -39px 0 0 0 !important;
  position: absolute !important;
  padding: 10px 0px 12px 10px;
  border-radius: 0 !important;
  border: 0 !important;
  box-shadow: 8px -7px 9px 2px #6384bf3b !important;
  background: #053abe;
}
body :focus {
  outline: 0 !important;
}
body table td {
  vertical-align: middle !important;
}
body .mat-h2 {
  color: #455a64;
}

.breadcrumb {
  font-size: 12px;
  padding: 0;
  border-radius: 0;
  background: transparent;
  margin: 0px;
}

.no-padding {
  padding: 0 !important;
}

mat-icon.help-icon {
  color: var(--primary);
  margin: 0 10px;
  cursor: pointer !important;
}

.position-help-icon {
  z-index: 999;
}

ngx-avatar .avatar-content {
  border-radius: 100% !important;
}

.sidebar-accordion .mat-expansion-panel-content > .mat-expansion-panel-body {
  padding: 0 !important;
}
.sidebar-accordion .mat-expansion-panel-header .mat-content {
  overflow: unset !important;
}
.sidebar-accordion .mat-expansion-panel-header:hover .mat-expansion-indicator:after {
  color: var(--secondary);
}
.sidebar-accordion .mat-expansion-panel-header .mat-expansion-indicator:after {
  color: var(--white);
}

.forum .pergunta-container .mat-content {
  padding: 10px 0;
}

.danger-snackbar {
  background-color: #ef5350 !important;
  white-space: pre-wrap;
}
.danger-snackbar .mat-simple-snackbar-action {
  color: #fff !important;
}

.success-snackbar {
  background-color: #28a745 !important;
  white-space: pre-wrap;
}
.success-snackbar .mat-simple-snackbar-action {
  color: #fff !important;
}

.warning-snackbar {
  background-color: var(--primary);
  white-space: pre-wrap;
  color: white;
}
.warning-snackbar .mat-simple-snackbar-action {
  color: white !important;
}

.mat-card .cotacao-filtros .mat-form-field-wrapper {
  padding: 0;
}

tr.mat-header-row {
  height: 33px;
}

@media only screen and (min-width: 768px) {
  .mat-drawer-content {
    min-height: calc(100vh - 70px) !important;
  }
}
.mat-drawer-side {
  border: 0;
}

.ps__rail-y {
  z-index: 10 !important;
}

.ng2-carouselamos {
  padding-left: 40px;
}

.ng2-carouselamos-wrapper {
  width: 100% !important;
}

.timepicker-backdrop-overlay,
.timepicker-overlay {
  z-index: 1000 !important;
}

.mat-dialog-content {
  padding: 10px 24px !important;
}

.ativo-inativo {
  width: 15px;
  height: 15px;
  border-radius: 100%;
}

.mat-column-ativo .mat-sort-header-container,
.mat-column-contabilizaHora .mat-sort-header-container,
.mat-column-clienteId .mat-sort-header-container,
.mat-column-admin .mat-sort-header-container,
.mat-column-progresso .mat-sort-header-container,
.mat-column-valor .mat-sort-header-container,
.mat-column-status .mat-sort-header-container,
.mat-column-dataSolicitacao .mat-sort-header-container,
.mat-column-dataPagamento .mat-sort-header-container {
  justify-content: center !important;
}

.cdk-overlay-container {
  z-index: 500 !important;
}

.ng2-carouselamos {
  padding-left: 39px;
}

.text-selection-none {
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.e-tooltip-wrap,
.e-tip-content {
  border-radius: 5px !important;
}

.quick-release .mat-form-field-subscript-wrapper {
  margin-top: 1.2em !important;
}

.devops-tfs .ng2-carouselamos-wrapper,
.apontamento .ng2-carouselamos-wrapper {
  background-color: lightgray;
}
.devops-tfs .controls,
.apontamento .controls {
  height: 100% !important;
}
.devops-tfs .controls button,
.apontamento .controls button {
  height: 100%;
}
.devops-tfs .mat-slider-thumb-label-text,
.apontamento .mat-slider-thumb-label-text {
  font-size: 9px;
}
.devops-tfs input[type=range],
.apontamento input[type=range] {
  pointer-events: none;
}
.devops-tfs input[type=range]::-webkit-slider-thumb,
.apontamento input[type=range]::-webkit-slider-thumb {
  pointer-events: all;
}
.devops-tfs input[type=range]::-moz-range-thumb,
.apontamento input[type=range]::-moz-range-thumb {
  pointer-events: all;
}
.devops-tfs input[type=range]::-ms-thumb,
.apontamento input[type=range]::-ms-thumb {
  pointer-events: all;
}

.mat-expansion-panel-header-description {
  color: rgba(0, 0, 0, 0.87);
}

mat-card .mat-form-field-flex > .mat-form-field-infix {
  padding: 0.5em 0px !important;
}

.mat-expansion-panel-body {
  position: relative;
}

.mat-expansion-panel-description {
  align-items: center !important;
}

.td-field {
  padding: 0;
}
.td-field .mat-form-field-wrapper {
  margin: 0;
}
.td-field.td-data {
  width: 200px;
}
.td-field.td-data .mat-form-field-infix {
  width: 135px;
}

.table-field .mat-form-field-outline-start,
.table-field .mat-form-field-outline-gap,
.table-field .mat-form-field-outline-end {
  border: none !important;
}
.table-field > div {
  padding: 0 !important;
}
.table-field .mat-checkbox-layout {
  margin-bottom: 0 !important;
}

.filtros-apontamento .mat-form-field-flex {
  max-width: 150px;
}

.mat-form-field-subscript-wrapper {
  padding: 0 !important;
}

.multiline-tooltip {
  white-space: pre-line;
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("~material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.eot");
  /* For IE6-8 */
  src: local("☺"), url("~material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.woff2") format("woff2"), url("~material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.woff") format("woff"), url("~material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.ttf") format("truetype");
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  /* Respect document layout direction */
  direction: inherit;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}
.material-icons._10k:before {
  content: "\e951";
}
.material-icons._10mp:before {
  content: "\e952";
}
.material-icons._11mp:before {
  content: "\e953";
}
.material-icons._123:before {
  content: "\eb8d";
}
.material-icons._12mp:before {
  content: "\e954";
}
.material-icons._13mp:before {
  content: "\e955";
}
.material-icons._14mp:before {
  content: "\e956";
}
.material-icons._15mp:before {
  content: "\e957";
}
.material-icons._16mp:before {
  content: "\e958";
}
.material-icons._17mp:before {
  content: "\e959";
}
.material-icons._18_up_rating:before {
  content: "\f8fd";
}
.material-icons._18mp:before {
  content: "\e95a";
}
.material-icons._19mp:before {
  content: "\e95b";
}
.material-icons._1k:before {
  content: "\e95c";
}
.material-icons._1k_plus:before {
  content: "\e95d";
}
.material-icons._1x_mobiledata:before {
  content: "\efcd";
}
.material-icons._20mp:before {
  content: "\e95e";
}
.material-icons._21mp:before {
  content: "\e95f";
}
.material-icons._22mp:before {
  content: "\e960";
}
.material-icons._23mp:before {
  content: "\e961";
}
.material-icons._24mp:before {
  content: "\e962";
}
.material-icons._2k:before {
  content: "\e963";
}
.material-icons._2k_plus:before {
  content: "\e964";
}
.material-icons._2mp:before {
  content: "\e965";
}
.material-icons._30fps:before {
  content: "\efce";
}
.material-icons._30fps_select:before {
  content: "\efcf";
}
.material-icons._360:before {
  content: "\e577";
}
.material-icons._3d_rotation:before {
  content: "\e84d";
}
.material-icons._3g_mobiledata:before {
  content: "\efd0";
}
.material-icons._3k:before {
  content: "\e966";
}
.material-icons._3k_plus:before {
  content: "\e967";
}
.material-icons._3mp:before {
  content: "\e968";
}
.material-icons._3p:before {
  content: "\efd1";
}
.material-icons._4g_mobiledata:before {
  content: "\efd2";
}
.material-icons._4g_plus_mobiledata:before {
  content: "\efd3";
}
.material-icons._4k:before {
  content: "\e072";
}
.material-icons._4k_plus:before {
  content: "\e969";
}
.material-icons._4mp:before {
  content: "\e96a";
}
.material-icons._5g:before {
  content: "\ef38";
}
.material-icons._5k:before {
  content: "\e96b";
}
.material-icons._5k_plus:before {
  content: "\e96c";
}
.material-icons._5mp:before {
  content: "\e96d";
}
.material-icons._60fps:before {
  content: "\efd4";
}
.material-icons._60fps_select:before {
  content: "\efd5";
}
.material-icons._6_ft_apart:before {
  content: "\f21e";
}
.material-icons._6k:before {
  content: "\e96e";
}
.material-icons._6k_plus:before {
  content: "\e96f";
}
.material-icons._6mp:before {
  content: "\e970";
}
.material-icons._7k:before {
  content: "\e971";
}
.material-icons._7k_plus:before {
  content: "\e972";
}
.material-icons._7mp:before {
  content: "\e973";
}
.material-icons._8k:before {
  content: "\e974";
}
.material-icons._8k_plus:before {
  content: "\e975";
}
.material-icons._8mp:before {
  content: "\e976";
}
.material-icons._9k:before {
  content: "\e977";
}
.material-icons._9k_plus:before {
  content: "\e978";
}
.material-icons._9mp:before {
  content: "\e979";
}
.material-icons.abc:before {
  content: "\eb94";
}
.material-icons.ac_unit:before {
  content: "\eb3b";
}
.material-icons.access_alarm:before {
  content: "\e190";
}
.material-icons.access_alarms:before {
  content: "\e191";
}
.material-icons.access_time:before {
  content: "\e192";
}
.material-icons.access_time_filled:before {
  content: "\efd6";
}
.material-icons.accessibility:before {
  content: "\e84e";
}
.material-icons.accessibility_new:before {
  content: "\e92c";
}
.material-icons.accessible:before {
  content: "\e914";
}
.material-icons.accessible_forward:before {
  content: "\e934";
}
.material-icons.account_balance:before {
  content: "\e84f";
}
.material-icons.account_balance_wallet:before {
  content: "\e850";
}
.material-icons.account_box:before {
  content: "\e851";
}
.material-icons.account_circle:before {
  content: "\e853";
}
.material-icons.account_tree:before {
  content: "\e97a";
}
.material-icons.ad_units:before {
  content: "\ef39";
}
.material-icons.adb:before {
  content: "\e60e";
}
.material-icons.add:before {
  content: "\e145";
}
.material-icons.add_a_photo:before {
  content: "\e439";
}
.material-icons.add_alarm:before {
  content: "\e193";
}
.material-icons.add_alert:before {
  content: "\e003";
}
.material-icons.add_box:before {
  content: "\e146";
}
.material-icons.add_business:before {
  content: "\e729";
}
.material-icons.add_call:before {
  content: "\e0e8";
}
.material-icons.add_card:before {
  content: "\eb86";
}
.material-icons.add_chart:before {
  content: "\e97b";
}
.material-icons.add_circle:before {
  content: "\e147";
}
.material-icons.add_circle_outline:before {
  content: "\e148";
}
.material-icons.add_comment:before {
  content: "\e266";
}
.material-icons.add_home:before {
  content: "\f8eb";
}
.material-icons.add_home_work:before {
  content: "\f8ed";
}
.material-icons.add_ic_call:before {
  content: "\e97c";
}
.material-icons.add_link:before {
  content: "\e178";
}
.material-icons.add_location:before {
  content: "\e567";
}
.material-icons.add_location_alt:before {
  content: "\ef3a";
}
.material-icons.add_moderator:before {
  content: "\e97d";
}
.material-icons.add_photo_alternate:before {
  content: "\e43e";
}
.material-icons.add_reaction:before {
  content: "\e1d3";
}
.material-icons.add_road:before {
  content: "\ef3b";
}
.material-icons.add_shopping_cart:before {
  content: "\e854";
}
.material-icons.add_task:before {
  content: "\f23a";
}
.material-icons.add_to_drive:before {
  content: "\e65c";
}
.material-icons.add_to_home_screen:before {
  content: "\e1fe";
}
.material-icons.add_to_photos:before {
  content: "\e39d";
}
.material-icons.add_to_queue:before {
  content: "\e05c";
}
.material-icons.addchart:before {
  content: "\ef3c";
}
.material-icons.adf_scanner:before {
  content: "\eada";
}
.material-icons.adjust:before {
  content: "\e39e";
}
.material-icons.admin_panel_settings:before {
  content: "\ef3d";
}
.material-icons.adobe:before {
  content: "\ea96";
}
.material-icons.ads_click:before {
  content: "\e762";
}
.material-icons.agriculture:before {
  content: "\ea79";
}
.material-icons.air:before {
  content: "\efd8";
}
.material-icons.airline_seat_flat:before {
  content: "\e630";
}
.material-icons.airline_seat_flat_angled:before {
  content: "\e631";
}
.material-icons.airline_seat_individual_suite:before {
  content: "\e632";
}
.material-icons.airline_seat_legroom_extra:before {
  content: "\e633";
}
.material-icons.airline_seat_legroom_normal:before {
  content: "\e634";
}
.material-icons.airline_seat_legroom_reduced:before {
  content: "\e635";
}
.material-icons.airline_seat_recline_extra:before {
  content: "\e636";
}
.material-icons.airline_seat_recline_normal:before {
  content: "\e637";
}
.material-icons.airline_stops:before {
  content: "\e7d0";
}
.material-icons.airlines:before {
  content: "\e7ca";
}
.material-icons.airplane_ticket:before {
  content: "\efd9";
}
.material-icons.airplanemode_active:before {
  content: "\e195";
}
.material-icons.airplanemode_inactive:before {
  content: "\e194";
}
.material-icons.airplanemode_off:before {
  content: "\e194";
}
.material-icons.airplanemode_on:before {
  content: "\e195";
}
.material-icons.airplay:before {
  content: "\e055";
}
.material-icons.airport_shuttle:before {
  content: "\eb3c";
}
.material-icons.alarm:before {
  content: "\e855";
}
.material-icons.alarm_add:before {
  content: "\e856";
}
.material-icons.alarm_off:before {
  content: "\e857";
}
.material-icons.alarm_on:before {
  content: "\e858";
}
.material-icons.album:before {
  content: "\e019";
}
.material-icons.align_horizontal_center:before {
  content: "\e00f";
}
.material-icons.align_horizontal_left:before {
  content: "\e00d";
}
.material-icons.align_horizontal_right:before {
  content: "\e010";
}
.material-icons.align_vertical_bottom:before {
  content: "\e015";
}
.material-icons.align_vertical_center:before {
  content: "\e011";
}
.material-icons.align_vertical_top:before {
  content: "\e00c";
}
.material-icons.all_inbox:before {
  content: "\e97f";
}
.material-icons.all_inclusive:before {
  content: "\eb3d";
}
.material-icons.all_out:before {
  content: "\e90b";
}
.material-icons.alt_route:before {
  content: "\f184";
}
.material-icons.alternate_email:before {
  content: "\e0e6";
}
.material-icons.amp_stories:before {
  content: "\ea13";
}
.material-icons.analytics:before {
  content: "\ef3e";
}
.material-icons.anchor:before {
  content: "\f1cd";
}
.material-icons.android:before {
  content: "\e859";
}
.material-icons.animation:before {
  content: "\e71c";
}
.material-icons.announcement:before {
  content: "\e85a";
}
.material-icons.aod:before {
  content: "\efda";
}
.material-icons.apartment:before {
  content: "\ea40";
}
.material-icons.api:before {
  content: "\f1b7";
}
.material-icons.app_blocking:before {
  content: "\ef3f";
}
.material-icons.app_registration:before {
  content: "\ef40";
}
.material-icons.app_settings_alt:before {
  content: "\ef41";
}
.material-icons.app_shortcut:before {
  content: "\eae4";
}
.material-icons.apple:before {
  content: "\ea80";
}
.material-icons.approval:before {
  content: "\e982";
}
.material-icons.apps:before {
  content: "\e5c3";
}
.material-icons.apps_outage:before {
  content: "\e7cc";
}
.material-icons.architecture:before {
  content: "\ea3b";
}
.material-icons.archive:before {
  content: "\e149";
}
.material-icons.area_chart:before {
  content: "\e770";
}
.material-icons.arrow_back:before {
  content: "\e5c4";
}
.material-icons.arrow_back_ios:before {
  content: "\e5e0";
}
.material-icons.arrow_back_ios_new:before {
  content: "\e2ea";
}
.material-icons.arrow_circle_down:before {
  content: "\f181";
}
.material-icons.arrow_circle_left:before {
  content: "\eaa7";
}
.material-icons.arrow_circle_right:before {
  content: "\eaaa";
}
.material-icons.arrow_circle_up:before {
  content: "\f182";
}
.material-icons.arrow_downward:before {
  content: "\e5db";
}
.material-icons.arrow_drop_down:before {
  content: "\e5c5";
}
.material-icons.arrow_drop_down_circle:before {
  content: "\e5c6";
}
.material-icons.arrow_drop_up:before {
  content: "\e5c7";
}
.material-icons.arrow_forward:before {
  content: "\e5c8";
}
.material-icons.arrow_forward_ios:before {
  content: "\e5e1";
}
.material-icons.arrow_left:before {
  content: "\e5de";
}
.material-icons.arrow_right:before {
  content: "\e5df";
}
.material-icons.arrow_right_alt:before {
  content: "\e941";
}
.material-icons.arrow_upward:before {
  content: "\e5d8";
}
.material-icons.art_track:before {
  content: "\e060";
}
.material-icons.article:before {
  content: "\ef42";
}
.material-icons.aspect_ratio:before {
  content: "\e85b";
}
.material-icons.assessment:before {
  content: "\e85c";
}
.material-icons.assignment:before {
  content: "\e85d";
}
.material-icons.assignment_ind:before {
  content: "\e85e";
}
.material-icons.assignment_late:before {
  content: "\e85f";
}
.material-icons.assignment_return:before {
  content: "\e860";
}
.material-icons.assignment_returned:before {
  content: "\e861";
}
.material-icons.assignment_turned_in:before {
  content: "\e862";
}
.material-icons.assistant:before {
  content: "\e39f";
}
.material-icons.assistant_direction:before {
  content: "\e988";
}
.material-icons.assistant_navigation:before {
  content: "\e989";
}
.material-icons.assistant_photo:before {
  content: "\e3a0";
}
.material-icons.assured_workload:before {
  content: "\eb6f";
}
.material-icons.atm:before {
  content: "\e573";
}
.material-icons.attach_email:before {
  content: "\ea5e";
}
.material-icons.attach_file:before {
  content: "\e226";
}
.material-icons.attach_money:before {
  content: "\e227";
}
.material-icons.attachment:before {
  content: "\e2bc";
}
.material-icons.attractions:before {
  content: "\ea52";
}
.material-icons.attribution:before {
  content: "\efdb";
}
.material-icons.audio_file:before {
  content: "\eb82";
}
.material-icons.audiotrack:before {
  content: "\e3a1";
}
.material-icons.auto_awesome:before {
  content: "\e65f";
}
.material-icons.auto_awesome_mosaic:before {
  content: "\e660";
}
.material-icons.auto_awesome_motion:before {
  content: "\e661";
}
.material-icons.auto_delete:before {
  content: "\ea4c";
}
.material-icons.auto_fix_high:before {
  content: "\e663";
}
.material-icons.auto_fix_normal:before {
  content: "\e664";
}
.material-icons.auto_fix_off:before {
  content: "\e665";
}
.material-icons.auto_graph:before {
  content: "\e4fb";
}
.material-icons.auto_mode:before {
  content: "\ec20";
}
.material-icons.auto_stories:before {
  content: "\e666";
}
.material-icons.autofps_select:before {
  content: "\efdc";
}
.material-icons.autorenew:before {
  content: "\e863";
}
.material-icons.av_timer:before {
  content: "\e01b";
}
.material-icons.baby_changing_station:before {
  content: "\f19b";
}
.material-icons.back_hand:before {
  content: "\e764";
}
.material-icons.backpack:before {
  content: "\f19c";
}
.material-icons.backspace:before {
  content: "\e14a";
}
.material-icons.backup:before {
  content: "\e864";
}
.material-icons.backup_table:before {
  content: "\ef43";
}
.material-icons.badge:before {
  content: "\ea67";
}
.material-icons.bakery_dining:before {
  content: "\ea53";
}
.material-icons.balance:before {
  content: "\eaf6";
}
.material-icons.balcony:before {
  content: "\e58f";
}
.material-icons.ballot:before {
  content: "\e172";
}
.material-icons.bar_chart:before {
  content: "\e26b";
}
.material-icons.batch_prediction:before {
  content: "\f0f5";
}
.material-icons.bathroom:before {
  content: "\efdd";
}
.material-icons.bathtub:before {
  content: "\ea41";
}
.material-icons.battery_0_bar:before {
  content: "\ebdc";
}
.material-icons.battery_1_bar:before {
  content: "\ebd9";
}
.material-icons.battery_2_bar:before {
  content: "\ebe0";
}
.material-icons.battery_3_bar:before {
  content: "\ebdd";
}
.material-icons.battery_4_bar:before {
  content: "\ebe2";
}
.material-icons.battery_5_bar:before {
  content: "\ebd4";
}
.material-icons.battery_6_bar:before {
  content: "\ebd2";
}
.material-icons.battery_alert:before {
  content: "\e19c";
}
.material-icons.battery_charging_full:before {
  content: "\e1a3";
}
.material-icons.battery_full:before {
  content: "\e1a4";
}
.material-icons.battery_saver:before {
  content: "\efde";
}
.material-icons.battery_std:before {
  content: "\e1a5";
}
.material-icons.battery_unknown:before {
  content: "\e1a6";
}
.material-icons.beach_access:before {
  content: "\eb3e";
}
.material-icons.bed:before {
  content: "\efdf";
}
.material-icons.bedroom_baby:before {
  content: "\efe0";
}
.material-icons.bedroom_child:before {
  content: "\efe1";
}
.material-icons.bedroom_parent:before {
  content: "\efe2";
}
.material-icons.bedtime:before {
  content: "\ef44";
}
.material-icons.bedtime_off:before {
  content: "\eb76";
}
.material-icons.beenhere:before {
  content: "\e52d";
}
.material-icons.bento:before {
  content: "\f1f4";
}
.material-icons.bike_scooter:before {
  content: "\ef45";
}
.material-icons.biotech:before {
  content: "\ea3a";
}
.material-icons.blender:before {
  content: "\efe3";
}
.material-icons.blinds:before {
  content: "\e286";
}
.material-icons.blinds_closed:before {
  content: "\ec1f";
}
.material-icons.block:before {
  content: "\e14b";
}
.material-icons.block_flipped:before {
  content: "\ef46";
}
.material-icons.bloodtype:before {
  content: "\efe4";
}
.material-icons.bluetooth:before {
  content: "\e1a7";
}
.material-icons.bluetooth_audio:before {
  content: "\e60f";
}
.material-icons.bluetooth_connected:before {
  content: "\e1a8";
}
.material-icons.bluetooth_disabled:before {
  content: "\e1a9";
}
.material-icons.bluetooth_drive:before {
  content: "\efe5";
}
.material-icons.bluetooth_searching:before {
  content: "\e1aa";
}
.material-icons.blur_circular:before {
  content: "\e3a2";
}
.material-icons.blur_linear:before {
  content: "\e3a3";
}
.material-icons.blur_off:before {
  content: "\e3a4";
}
.material-icons.blur_on:before {
  content: "\e3a5";
}
.material-icons.bolt:before {
  content: "\ea0b";
}
.material-icons.book:before {
  content: "\e865";
}
.material-icons.book_online:before {
  content: "\f217";
}
.material-icons.bookmark:before {
  content: "\e866";
}
.material-icons.bookmark_add:before {
  content: "\e598";
}
.material-icons.bookmark_added:before {
  content: "\e599";
}
.material-icons.bookmark_border:before {
  content: "\e867";
}
.material-icons.bookmark_outline:before {
  content: "\e867";
}
.material-icons.bookmark_remove:before {
  content: "\e59a";
}
.material-icons.bookmarks:before {
  content: "\e98b";
}
.material-icons.border_all:before {
  content: "\e228";
}
.material-icons.border_bottom:before {
  content: "\e229";
}
.material-icons.border_clear:before {
  content: "\e22a";
}
.material-icons.border_color:before {
  content: "\e22b";
}
.material-icons.border_horizontal:before {
  content: "\e22c";
}
.material-icons.border_inner:before {
  content: "\e22d";
}
.material-icons.border_left:before {
  content: "\e22e";
}
.material-icons.border_outer:before {
  content: "\e22f";
}
.material-icons.border_right:before {
  content: "\e230";
}
.material-icons.border_style:before {
  content: "\e231";
}
.material-icons.border_top:before {
  content: "\e232";
}
.material-icons.border_vertical:before {
  content: "\e233";
}
.material-icons.boy:before {
  content: "\eb67";
}
.material-icons.branding_watermark:before {
  content: "\e06b";
}
.material-icons.breakfast_dining:before {
  content: "\ea54";
}
.material-icons.brightness_1:before {
  content: "\e3a6";
}
.material-icons.brightness_2:before {
  content: "\e3a7";
}
.material-icons.brightness_3:before {
  content: "\e3a8";
}
.material-icons.brightness_4:before {
  content: "\e3a9";
}
.material-icons.brightness_5:before {
  content: "\e3aa";
}
.material-icons.brightness_6:before {
  content: "\e3ab";
}
.material-icons.brightness_7:before {
  content: "\e3ac";
}
.material-icons.brightness_auto:before {
  content: "\e1ab";
}
.material-icons.brightness_high:before {
  content: "\e1ac";
}
.material-icons.brightness_low:before {
  content: "\e1ad";
}
.material-icons.brightness_medium:before {
  content: "\e1ae";
}
.material-icons.broadcast_on_home:before {
  content: "\f8f8";
}
.material-icons.broadcast_on_personal:before {
  content: "\f8f9";
}
.material-icons.broken_image:before {
  content: "\e3ad";
}
.material-icons.browse_gallery:before {
  content: "\ebd1";
}
.material-icons.browser_not_supported:before {
  content: "\ef47";
}
.material-icons.browser_updated:before {
  content: "\e7cf";
}
.material-icons.brunch_dining:before {
  content: "\ea73";
}
.material-icons.brush:before {
  content: "\e3ae";
}
.material-icons.bubble_chart:before {
  content: "\e6dd";
}
.material-icons.bug_report:before {
  content: "\e868";
}
.material-icons.build:before {
  content: "\e869";
}
.material-icons.build_circle:before {
  content: "\ef48";
}
.material-icons.bungalow:before {
  content: "\e591";
}
.material-icons.burst_mode:before {
  content: "\e43c";
}
.material-icons.bus_alert:before {
  content: "\e98f";
}
.material-icons.business:before {
  content: "\e0af";
}
.material-icons.business_center:before {
  content: "\eb3f";
}
.material-icons.cabin:before {
  content: "\e589";
}
.material-icons.cable:before {
  content: "\efe6";
}
.material-icons.cached:before {
  content: "\e86a";
}
.material-icons.cake:before {
  content: "\e7e9";
}
.material-icons.calculate:before {
  content: "\ea5f";
}
.material-icons.calendar_month:before {
  content: "\ebcc";
}
.material-icons.calendar_today:before {
  content: "\e935";
}
.material-icons.calendar_view_day:before {
  content: "\e936";
}
.material-icons.calendar_view_month:before {
  content: "\efe7";
}
.material-icons.calendar_view_week:before {
  content: "\efe8";
}
.material-icons.call:before {
  content: "\e0b0";
}
.material-icons.call_end:before {
  content: "\e0b1";
}
.material-icons.call_made:before {
  content: "\e0b2";
}
.material-icons.call_merge:before {
  content: "\e0b3";
}
.material-icons.call_missed:before {
  content: "\e0b4";
}
.material-icons.call_missed_outgoing:before {
  content: "\e0e4";
}
.material-icons.call_received:before {
  content: "\e0b5";
}
.material-icons.call_split:before {
  content: "\e0b6";
}
.material-icons.call_to_action:before {
  content: "\e06c";
}
.material-icons.camera:before {
  content: "\e3af";
}
.material-icons.camera_alt:before {
  content: "\e3b0";
}
.material-icons.camera_enhance:before {
  content: "\e8fc";
}
.material-icons.camera_front:before {
  content: "\e3b1";
}
.material-icons.camera_indoor:before {
  content: "\efe9";
}
.material-icons.camera_outdoor:before {
  content: "\efea";
}
.material-icons.camera_rear:before {
  content: "\e3b2";
}
.material-icons.camera_roll:before {
  content: "\e3b3";
}
.material-icons.cameraswitch:before {
  content: "\efeb";
}
.material-icons.campaign:before {
  content: "\ef49";
}
.material-icons.cancel:before {
  content: "\e5c9";
}
.material-icons.cancel_presentation:before {
  content: "\e0e9";
}
.material-icons.cancel_schedule_send:before {
  content: "\ea39";
}
.material-icons.candlestick_chart:before {
  content: "\ead4";
}
.material-icons.car_crash:before {
  content: "\ebf2";
}
.material-icons.car_rental:before {
  content: "\ea55";
}
.material-icons.car_repair:before {
  content: "\ea56";
}
.material-icons.card_giftcard:before {
  content: "\e8f6";
}
.material-icons.card_membership:before {
  content: "\e8f7";
}
.material-icons.card_travel:before {
  content: "\e8f8";
}
.material-icons.carpenter:before {
  content: "\f1f8";
}
.material-icons.cases:before {
  content: "\e992";
}
.material-icons.casino:before {
  content: "\eb40";
}
.material-icons.cast:before {
  content: "\e307";
}
.material-icons.cast_connected:before {
  content: "\e308";
}
.material-icons.cast_for_education:before {
  content: "\efec";
}
.material-icons.castle:before {
  content: "\eab1";
}
.material-icons.catching_pokemon:before {
  content: "\e508";
}
.material-icons.category:before {
  content: "\e574";
}
.material-icons.celebration:before {
  content: "\ea65";
}
.material-icons.cell_tower:before {
  content: "\ebba";
}
.material-icons.cell_wifi:before {
  content: "\e0ec";
}
.material-icons.center_focus_strong:before {
  content: "\e3b4";
}
.material-icons.center_focus_weak:before {
  content: "\e3b5";
}
.material-icons.chair:before {
  content: "\efed";
}
.material-icons.chair_alt:before {
  content: "\efee";
}
.material-icons.chalet:before {
  content: "\e585";
}
.material-icons.change_circle:before {
  content: "\e2e7";
}
.material-icons.change_history:before {
  content: "\e86b";
}
.material-icons.charging_station:before {
  content: "\f19d";
}
.material-icons.chat:before {
  content: "\e0b7";
}
.material-icons.chat_bubble:before {
  content: "\e0ca";
}
.material-icons.chat_bubble_outline:before {
  content: "\e0cb";
}
.material-icons.check:before {
  content: "\e5ca";
}
.material-icons.check_box:before {
  content: "\e834";
}
.material-icons.check_box_outline_blank:before {
  content: "\e835";
}
.material-icons.check_circle:before {
  content: "\e86c";
}
.material-icons.check_circle_outline:before {
  content: "\e92d";
}
.material-icons.checklist:before {
  content: "\e6b1";
}
.material-icons.checklist_rtl:before {
  content: "\e6b3";
}
.material-icons.checkroom:before {
  content: "\f19e";
}
.material-icons.chevron_left:before {
  content: "\e5cb";
}
.material-icons.chevron_right:before {
  content: "\e5cc";
}
.material-icons.child_care:before {
  content: "\eb41";
}
.material-icons.child_friendly:before {
  content: "\eb42";
}
.material-icons.chrome_reader_mode:before {
  content: "\e86d";
}
.material-icons.church:before {
  content: "\eaae";
}
.material-icons.circle:before {
  content: "\ef4a";
}
.material-icons.circle_notifications:before {
  content: "\e994";
}
.material-icons.class:before {
  content: "\e86e";
}
.material-icons.clean_hands:before {
  content: "\f21f";
}
.material-icons.cleaning_services:before {
  content: "\f0ff";
}
.material-icons.clear:before {
  content: "\e14c";
}
.material-icons.clear_all:before {
  content: "\e0b8";
}
.material-icons.close:before {
  content: "\e5cd";
}
.material-icons.close_fullscreen:before {
  content: "\f1cf";
}
.material-icons.closed_caption:before {
  content: "\e01c";
}
.material-icons.closed_caption_disabled:before {
  content: "\f1dc";
}
.material-icons.closed_caption_off:before {
  content: "\e996";
}
.material-icons.cloud:before {
  content: "\e2bd";
}
.material-icons.cloud_circle:before {
  content: "\e2be";
}
.material-icons.cloud_done:before {
  content: "\e2bf";
}
.material-icons.cloud_download:before {
  content: "\e2c0";
}
.material-icons.cloud_off:before {
  content: "\e2c1";
}
.material-icons.cloud_queue:before {
  content: "\e2c2";
}
.material-icons.cloud_sync:before {
  content: "\eb5a";
}
.material-icons.cloud_upload:before {
  content: "\e2c3";
}
.material-icons.cloudy_snowing:before {
  content: "\e810";
}
.material-icons.co2:before {
  content: "\e7b0";
}
.material-icons.co_present:before {
  content: "\eaf0";
}
.material-icons.code:before {
  content: "\e86f";
}
.material-icons.code_off:before {
  content: "\e4f3";
}
.material-icons.coffee:before {
  content: "\efef";
}
.material-icons.coffee_maker:before {
  content: "\eff0";
}
.material-icons.collections:before {
  content: "\e3b6";
}
.material-icons.collections_bookmark:before {
  content: "\e431";
}
.material-icons.color_lens:before {
  content: "\e3b7";
}
.material-icons.colorize:before {
  content: "\e3b8";
}
.material-icons.comment:before {
  content: "\e0b9";
}
.material-icons.comment_bank:before {
  content: "\ea4e";
}
.material-icons.comments_disabled:before {
  content: "\e7a2";
}
.material-icons.commit:before {
  content: "\eaf5";
}
.material-icons.commute:before {
  content: "\e940";
}
.material-icons.compare:before {
  content: "\e3b9";
}
.material-icons.compare_arrows:before {
  content: "\e915";
}
.material-icons.compass_calibration:before {
  content: "\e57c";
}
.material-icons.compost:before {
  content: "\e761";
}
.material-icons.compress:before {
  content: "\e94d";
}
.material-icons.computer:before {
  content: "\e30a";
}
.material-icons.confirmation_num:before {
  content: "\e638";
}
.material-icons.confirmation_number:before {
  content: "\e638";
}
.material-icons.connect_without_contact:before {
  content: "\f223";
}
.material-icons.connected_tv:before {
  content: "\e998";
}
.material-icons.connecting_airports:before {
  content: "\e7c9";
}
.material-icons.construction:before {
  content: "\ea3c";
}
.material-icons.contact_mail:before {
  content: "\e0d0";
}
.material-icons.contact_page:before {
  content: "\f22e";
}
.material-icons.contact_phone:before {
  content: "\e0cf";
}
.material-icons.contact_support:before {
  content: "\e94c";
}
.material-icons.contactless:before {
  content: "\ea71";
}
.material-icons.contacts:before {
  content: "\e0ba";
}
.material-icons.content_copy:before {
  content: "\e14d";
}
.material-icons.content_cut:before {
  content: "\e14e";
}
.material-icons.content_paste:before {
  content: "\e14f";
}
.material-icons.content_paste_go:before {
  content: "\ea8e";
}
.material-icons.content_paste_off:before {
  content: "\e4f8";
}
.material-icons.content_paste_search:before {
  content: "\ea9b";
}
.material-icons.contrast:before {
  content: "\eb37";
}
.material-icons.control_camera:before {
  content: "\e074";
}
.material-icons.control_point:before {
  content: "\e3ba";
}
.material-icons.control_point_duplicate:before {
  content: "\e3bb";
}
.material-icons.cookie:before {
  content: "\eaac";
}
.material-icons.copy_all:before {
  content: "\e2ec";
}
.material-icons.copyright:before {
  content: "\e90c";
}
.material-icons.coronavirus:before {
  content: "\f221";
}
.material-icons.corporate_fare:before {
  content: "\f1d0";
}
.material-icons.cottage:before {
  content: "\e587";
}
.material-icons.countertops:before {
  content: "\f1f7";
}
.material-icons.create:before {
  content: "\e150";
}
.material-icons.create_new_folder:before {
  content: "\e2cc";
}
.material-icons.credit_card:before {
  content: "\e870";
}
.material-icons.credit_card_off:before {
  content: "\e4f4";
}
.material-icons.credit_score:before {
  content: "\eff1";
}
.material-icons.crib:before {
  content: "\e588";
}
.material-icons.crisis_alert:before {
  content: "\ebe9";
}
.material-icons.crop:before {
  content: "\e3be";
}
.material-icons.crop_16_9:before {
  content: "\e3bc";
}
.material-icons.crop_3_2:before {
  content: "\e3bd";
}
.material-icons.crop_5_4:before {
  content: "\e3bf";
}
.material-icons.crop_7_5:before {
  content: "\e3c0";
}
.material-icons.crop_din:before {
  content: "\e3c1";
}
.material-icons.crop_free:before {
  content: "\e3c2";
}
.material-icons.crop_landscape:before {
  content: "\e3c3";
}
.material-icons.crop_original:before {
  content: "\e3c4";
}
.material-icons.crop_portrait:before {
  content: "\e3c5";
}
.material-icons.crop_rotate:before {
  content: "\e437";
}
.material-icons.crop_square:before {
  content: "\e3c6";
}
.material-icons.cruelty_free:before {
  content: "\e799";
}
.material-icons.css:before {
  content: "\eb93";
}
.material-icons.currency_bitcoin:before {
  content: "\ebc5";
}
.material-icons.currency_exchange:before {
  content: "\eb70";
}
.material-icons.currency_franc:before {
  content: "\eafa";
}
.material-icons.currency_lira:before {
  content: "\eaef";
}
.material-icons.currency_pound:before {
  content: "\eaf1";
}
.material-icons.currency_ruble:before {
  content: "\eaec";
}
.material-icons.currency_rupee:before {
  content: "\eaf7";
}
.material-icons.currency_yen:before {
  content: "\eafb";
}
.material-icons.currency_yuan:before {
  content: "\eaf9";
}
.material-icons.curtains:before {
  content: "\ec1e";
}
.material-icons.curtains_closed:before {
  content: "\ec1d";
}
.material-icons.cyclone:before {
  content: "\ebd5";
}
.material-icons.dangerous:before {
  content: "\e99a";
}
.material-icons.dark_mode:before {
  content: "\e51c";
}
.material-icons.dashboard:before {
  content: "\e871";
}
.material-icons.dashboard_customize:before {
  content: "\e99b";
}
.material-icons.data_array:before {
  content: "\ead1";
}
.material-icons.data_exploration:before {
  content: "\e76f";
}
.material-icons.data_object:before {
  content: "\ead3";
}
.material-icons.data_saver_off:before {
  content: "\eff2";
}
.material-icons.data_saver_on:before {
  content: "\eff3";
}
.material-icons.data_thresholding:before {
  content: "\eb9f";
}
.material-icons.data_usage:before {
  content: "\e1af";
}
.material-icons.dataset:before {
  content: "\f8ee";
}
.material-icons.dataset_linked:before {
  content: "\f8ef";
}
.material-icons.date_range:before {
  content: "\e916";
}
.material-icons.deblur:before {
  content: "\eb77";
}
.material-icons.deck:before {
  content: "\ea42";
}
.material-icons.dehaze:before {
  content: "\e3c7";
}
.material-icons.delete:before {
  content: "\e872";
}
.material-icons.delete_forever:before {
  content: "\e92b";
}
.material-icons.delete_outline:before {
  content: "\e92e";
}
.material-icons.delete_sweep:before {
  content: "\e16c";
}
.material-icons.delivery_dining:before {
  content: "\ea72";
}
.material-icons.density_large:before {
  content: "\eba9";
}
.material-icons.density_medium:before {
  content: "\eb9e";
}
.material-icons.density_small:before {
  content: "\eba8";
}
.material-icons.departure_board:before {
  content: "\e576";
}
.material-icons.description:before {
  content: "\e873";
}
.material-icons.deselect:before {
  content: "\ebb6";
}
.material-icons.design_services:before {
  content: "\f10a";
}
.material-icons.desk:before {
  content: "\f8f4";
}
.material-icons.desktop_access_disabled:before {
  content: "\e99d";
}
.material-icons.desktop_mac:before {
  content: "\e30b";
}
.material-icons.desktop_windows:before {
  content: "\e30c";
}
.material-icons.details:before {
  content: "\e3c8";
}
.material-icons.developer_board:before {
  content: "\e30d";
}
.material-icons.developer_board_off:before {
  content: "\e4ff";
}
.material-icons.developer_mode:before {
  content: "\e1b0";
}
.material-icons.device_hub:before {
  content: "\e335";
}
.material-icons.device_thermostat:before {
  content: "\e1ff";
}
.material-icons.device_unknown:before {
  content: "\e339";
}
.material-icons.devices:before {
  content: "\e1b1";
}
.material-icons.devices_fold:before {
  content: "\ebde";
}
.material-icons.devices_other:before {
  content: "\e337";
}
.material-icons.dialer_sip:before {
  content: "\e0bb";
}
.material-icons.dialpad:before {
  content: "\e0bc";
}
.material-icons.diamond:before {
  content: "\ead5";
}
.material-icons.difference:before {
  content: "\eb7d";
}
.material-icons.dining:before {
  content: "\eff4";
}
.material-icons.dinner_dining:before {
  content: "\ea57";
}
.material-icons.directions:before {
  content: "\e52e";
}
.material-icons.directions_bike:before {
  content: "\e52f";
}
.material-icons.directions_boat:before {
  content: "\e532";
}
.material-icons.directions_boat_filled:before {
  content: "\eff5";
}
.material-icons.directions_bus:before {
  content: "\e530";
}
.material-icons.directions_bus_filled:before {
  content: "\eff6";
}
.material-icons.directions_car:before {
  content: "\e531";
}
.material-icons.directions_car_filled:before {
  content: "\eff7";
}
.material-icons.directions_ferry:before {
  content: "\e532";
}
.material-icons.directions_off:before {
  content: "\f10f";
}
.material-icons.directions_railway:before {
  content: "\e534";
}
.material-icons.directions_railway_filled:before {
  content: "\eff8";
}
.material-icons.directions_run:before {
  content: "\e566";
}
.material-icons.directions_subway:before {
  content: "\e533";
}
.material-icons.directions_subway_filled:before {
  content: "\eff9";
}
.material-icons.directions_train:before {
  content: "\e534";
}
.material-icons.directions_transit:before {
  content: "\e535";
}
.material-icons.directions_transit_filled:before {
  content: "\effa";
}
.material-icons.directions_walk:before {
  content: "\e536";
}
.material-icons.dirty_lens:before {
  content: "\ef4b";
}
.material-icons.disabled_by_default:before {
  content: "\f230";
}
.material-icons.disabled_visible:before {
  content: "\e76e";
}
.material-icons.disc_full:before {
  content: "\e610";
}
.material-icons.discord:before {
  content: "\ea6c";
}
.material-icons.discount:before {
  content: "\ebc9";
}
.material-icons.display_settings:before {
  content: "\eb97";
}
.material-icons.dnd_forwardslash:before {
  content: "\e611";
}
.material-icons.dns:before {
  content: "\e875";
}
.material-icons.do_disturb:before {
  content: "\f08c";
}
.material-icons.do_disturb_alt:before {
  content: "\f08d";
}
.material-icons.do_disturb_off:before {
  content: "\f08e";
}
.material-icons.do_disturb_on:before {
  content: "\f08f";
}
.material-icons.do_not_disturb:before {
  content: "\e612";
}
.material-icons.do_not_disturb_alt:before {
  content: "\e611";
}
.material-icons.do_not_disturb_off:before {
  content: "\e643";
}
.material-icons.do_not_disturb_on:before {
  content: "\e644";
}
.material-icons.do_not_disturb_on_total_silence:before {
  content: "\effb";
}
.material-icons.do_not_step:before {
  content: "\f19f";
}
.material-icons.do_not_touch:before {
  content: "\f1b0";
}
.material-icons.dock:before {
  content: "\e30e";
}
.material-icons.document_scanner:before {
  content: "\e5fa";
}
.material-icons.domain:before {
  content: "\e7ee";
}
.material-icons.domain_add:before {
  content: "\eb62";
}
.material-icons.domain_disabled:before {
  content: "\e0ef";
}
.material-icons.domain_verification:before {
  content: "\ef4c";
}
.material-icons.done:before {
  content: "\e876";
}
.material-icons.done_all:before {
  content: "\e877";
}
.material-icons.done_outline:before {
  content: "\e92f";
}
.material-icons.donut_large:before {
  content: "\e917";
}
.material-icons.donut_small:before {
  content: "\e918";
}
.material-icons.door_back:before {
  content: "\effc";
}
.material-icons.door_front:before {
  content: "\effd";
}
.material-icons.door_sliding:before {
  content: "\effe";
}
.material-icons.doorbell:before {
  content: "\efff";
}
.material-icons.double_arrow:before {
  content: "\ea50";
}
.material-icons.downhill_skiing:before {
  content: "\e509";
}
.material-icons.download:before {
  content: "\f090";
}
.material-icons.download_done:before {
  content: "\f091";
}
.material-icons.download_for_offline:before {
  content: "\f000";
}
.material-icons.downloading:before {
  content: "\f001";
}
.material-icons.drafts:before {
  content: "\e151";
}
.material-icons.drag_handle:before {
  content: "\e25d";
}
.material-icons.drag_indicator:before {
  content: "\e945";
}
.material-icons.draw:before {
  content: "\e746";
}
.material-icons.drive_eta:before {
  content: "\e613";
}
.material-icons.drive_file_move:before {
  content: "\e675";
}
.material-icons.drive_file_move_outline:before {
  content: "\e9a1";
}
.material-icons.drive_file_move_rtl:before {
  content: "\e76d";
}
.material-icons.drive_file_rename_outline:before {
  content: "\e9a2";
}
.material-icons.drive_folder_upload:before {
  content: "\e9a3";
}
.material-icons.dry:before {
  content: "\f1b3";
}
.material-icons.dry_cleaning:before {
  content: "\ea58";
}
.material-icons.duo:before {
  content: "\e9a5";
}
.material-icons.dvr:before {
  content: "\e1b2";
}
.material-icons.dynamic_feed:before {
  content: "\ea14";
}
.material-icons.dynamic_form:before {
  content: "\f1bf";
}
.material-icons.e_mobiledata:before {
  content: "\f002";
}
.material-icons.earbuds:before {
  content: "\f003";
}
.material-icons.earbuds_battery:before {
  content: "\f004";
}
.material-icons.east:before {
  content: "\f1df";
}
.material-icons.eco:before {
  content: "\ea35";
}
.material-icons.edgesensor_high:before {
  content: "\f005";
}
.material-icons.edgesensor_low:before {
  content: "\f006";
}
.material-icons.edit:before {
  content: "\e3c9";
}
.material-icons.edit_attributes:before {
  content: "\e578";
}
.material-icons.edit_calendar:before {
  content: "\e742";
}
.material-icons.edit_location:before {
  content: "\e568";
}
.material-icons.edit_location_alt:before {
  content: "\e1c5";
}
.material-icons.edit_note:before {
  content: "\e745";
}
.material-icons.edit_notifications:before {
  content: "\e525";
}
.material-icons.edit_off:before {
  content: "\e950";
}
.material-icons.edit_road:before {
  content: "\ef4d";
}
.material-icons.egg:before {
  content: "\eacc";
}
.material-icons.egg_alt:before {
  content: "\eac8";
}
.material-icons.eject:before {
  content: "\e8fb";
}
.material-icons.elderly:before {
  content: "\f21a";
}
.material-icons.elderly_woman:before {
  content: "\eb69";
}
.material-icons.electric_bike:before {
  content: "\eb1b";
}
.material-icons.electric_bolt:before {
  content: "\ec1c";
}
.material-icons.electric_car:before {
  content: "\eb1c";
}
.material-icons.electric_meter:before {
  content: "\ec1b";
}
.material-icons.electric_moped:before {
  content: "\eb1d";
}
.material-icons.electric_rickshaw:before {
  content: "\eb1e";
}
.material-icons.electric_scooter:before {
  content: "\eb1f";
}
.material-icons.electrical_services:before {
  content: "\f102";
}
.material-icons.elevator:before {
  content: "\f1a0";
}
.material-icons.email:before {
  content: "\e0be";
}
.material-icons.emergency:before {
  content: "\e1eb";
}
.material-icons.emergency_recording:before {
  content: "\ebf4";
}
.material-icons.emergency_share:before {
  content: "\ebf6";
}
.material-icons.emoji_emotions:before {
  content: "\ea22";
}
.material-icons.emoji_events:before {
  content: "\ea23";
}
.material-icons.emoji_flags:before {
  content: "\ea1a";
}
.material-icons.emoji_food_beverage:before {
  content: "\ea1b";
}
.material-icons.emoji_nature:before {
  content: "\ea1c";
}
.material-icons.emoji_objects:before {
  content: "\ea24";
}
.material-icons.emoji_people:before {
  content: "\ea1d";
}
.material-icons.emoji_symbols:before {
  content: "\ea1e";
}
.material-icons.emoji_transportation:before {
  content: "\ea1f";
}
.material-icons.energy_savings_leaf:before {
  content: "\ec1a";
}
.material-icons.engineering:before {
  content: "\ea3d";
}
.material-icons.enhance_photo_translate:before {
  content: "\e8fc";
}
.material-icons.enhanced_encryption:before {
  content: "\e63f";
}
.material-icons.equalizer:before {
  content: "\e01d";
}
.material-icons.error:before {
  content: "\e000";
}
.material-icons.error_outline:before {
  content: "\e001";
}
.material-icons.escalator:before {
  content: "\f1a1";
}
.material-icons.escalator_warning:before {
  content: "\f1ac";
}
.material-icons.euro:before {
  content: "\ea15";
}
.material-icons.euro_symbol:before {
  content: "\e926";
}
.material-icons.ev_station:before {
  content: "\e56d";
}
.material-icons.event:before {
  content: "\e878";
}
.material-icons.event_available:before {
  content: "\e614";
}
.material-icons.event_busy:before {
  content: "\e615";
}
.material-icons.event_note:before {
  content: "\e616";
}
.material-icons.event_repeat:before {
  content: "\eb7b";
}
.material-icons.event_seat:before {
  content: "\e903";
}
.material-icons.exit_to_app:before {
  content: "\e879";
}
.material-icons.expand:before {
  content: "\e94f";
}
.material-icons.expand_circle_down:before {
  content: "\e7cd";
}
.material-icons.expand_less:before {
  content: "\e5ce";
}
.material-icons.expand_more:before {
  content: "\e5cf";
}
.material-icons.explicit:before {
  content: "\e01e";
}
.material-icons.explore:before {
  content: "\e87a";
}
.material-icons.explore_off:before {
  content: "\e9a8";
}
.material-icons.exposure:before {
  content: "\e3ca";
}
.material-icons.exposure_minus_1:before {
  content: "\e3cb";
}
.material-icons.exposure_minus_2:before {
  content: "\e3cc";
}
.material-icons.exposure_neg_1:before {
  content: "\e3cb";
}
.material-icons.exposure_neg_2:before {
  content: "\e3cc";
}
.material-icons.exposure_plus_1:before {
  content: "\e3cd";
}
.material-icons.exposure_plus_2:before {
  content: "\e3ce";
}
.material-icons.exposure_zero:before {
  content: "\e3cf";
}
.material-icons.extension:before {
  content: "\e87b";
}
.material-icons.extension_off:before {
  content: "\e4f5";
}
.material-icons.face:before {
  content: "\e87c";
}
.material-icons.face_retouching_natural:before {
  content: "\ef4e";
}
.material-icons.face_retouching_off:before {
  content: "\f007";
}
.material-icons.facebook:before {
  content: "\f234";
}
.material-icons.fact_check:before {
  content: "\f0c5";
}
.material-icons.factory:before {
  content: "\ebbc";
}
.material-icons.family_restroom:before {
  content: "\f1a2";
}
.material-icons.fast_forward:before {
  content: "\e01f";
}
.material-icons.fast_rewind:before {
  content: "\e020";
}
.material-icons.fastfood:before {
  content: "\e57a";
}
.material-icons.favorite:before {
  content: "\e87d";
}
.material-icons.favorite_border:before {
  content: "\e87e";
}
.material-icons.favorite_outline:before {
  content: "\e87e";
}
.material-icons.fax:before {
  content: "\ead8";
}
.material-icons.featured_play_list:before {
  content: "\e06d";
}
.material-icons.featured_video:before {
  content: "\e06e";
}
.material-icons.feed:before {
  content: "\f009";
}
.material-icons.feedback:before {
  content: "\e87f";
}
.material-icons.female:before {
  content: "\e590";
}
.material-icons.fence:before {
  content: "\f1f6";
}
.material-icons.festival:before {
  content: "\ea68";
}
.material-icons.fiber_dvr:before {
  content: "\e05d";
}
.material-icons.fiber_manual_record:before {
  content: "\e061";
}
.material-icons.fiber_new:before {
  content: "\e05e";
}
.material-icons.fiber_pin:before {
  content: "\e06a";
}
.material-icons.fiber_smart_record:before {
  content: "\e062";
}
.material-icons.file_copy:before {
  content: "\e173";
}
.material-icons.file_download:before {
  content: "\e2c4";
}
.material-icons.file_download_done:before {
  content: "\e9aa";
}
.material-icons.file_download_off:before {
  content: "\e4fe";
}
.material-icons.file_open:before {
  content: "\eaf3";
}
.material-icons.file_present:before {
  content: "\ea0e";
}
.material-icons.file_upload:before {
  content: "\e2c6";
}
.material-icons.filter:before {
  content: "\e3d3";
}
.material-icons.filter_1:before {
  content: "\e3d0";
}
.material-icons.filter_2:before {
  content: "\e3d1";
}
.material-icons.filter_3:before {
  content: "\e3d2";
}
.material-icons.filter_4:before {
  content: "\e3d4";
}
.material-icons.filter_5:before {
  content: "\e3d5";
}
.material-icons.filter_6:before {
  content: "\e3d6";
}
.material-icons.filter_7:before {
  content: "\e3d7";
}
.material-icons.filter_8:before {
  content: "\e3d8";
}
.material-icons.filter_9:before {
  content: "\e3d9";
}
.material-icons.filter_9_plus:before {
  content: "\e3da";
}
.material-icons.filter_alt:before {
  content: "\ef4f";
}
.material-icons.filter_alt_off:before {
  content: "\eb32";
}
.material-icons.filter_b_and_w:before {
  content: "\e3db";
}
.material-icons.filter_center_focus:before {
  content: "\e3dc";
}
.material-icons.filter_drama:before {
  content: "\e3dd";
}
.material-icons.filter_frames:before {
  content: "\e3de";
}
.material-icons.filter_hdr:before {
  content: "\e3df";
}
.material-icons.filter_list:before {
  content: "\e152";
}
.material-icons.filter_list_alt:before {
  content: "\e94e";
}
.material-icons.filter_list_off:before {
  content: "\eb57";
}
.material-icons.filter_none:before {
  content: "\e3e0";
}
.material-icons.filter_tilt_shift:before {
  content: "\e3e2";
}
.material-icons.filter_vintage:before {
  content: "\e3e3";
}
.material-icons.find_in_page:before {
  content: "\e880";
}
.material-icons.find_replace:before {
  content: "\e881";
}
.material-icons.fingerprint:before {
  content: "\e90d";
}
.material-icons.fire_extinguisher:before {
  content: "\f1d8";
}
.material-icons.fire_hydrant:before {
  content: "\f1a3";
}
.material-icons.fire_hydrant_alt:before {
  content: "\f8f1";
}
.material-icons.fire_truck:before {
  content: "\f8f2";
}
.material-icons.fireplace:before {
  content: "\ea43";
}
.material-icons.first_page:before {
  content: "\e5dc";
}
.material-icons.fit_screen:before {
  content: "\ea10";
}
.material-icons.fitbit:before {
  content: "\e82b";
}
.material-icons.fitness_center:before {
  content: "\eb43";
}
.material-icons.flag:before {
  content: "\e153";
}
.material-icons.flag_circle:before {
  content: "\eaf8";
}
.material-icons.flaky:before {
  content: "\ef50";
}
.material-icons.flare:before {
  content: "\e3e4";
}
.material-icons.flash_auto:before {
  content: "\e3e5";
}
.material-icons.flash_off:before {
  content: "\e3e6";
}
.material-icons.flash_on:before {
  content: "\e3e7";
}
.material-icons.flashlight_off:before {
  content: "\f00a";
}
.material-icons.flashlight_on:before {
  content: "\f00b";
}
.material-icons.flatware:before {
  content: "\f00c";
}
.material-icons.flight:before {
  content: "\e539";
}
.material-icons.flight_class:before {
  content: "\e7cb";
}
.material-icons.flight_land:before {
  content: "\e904";
}
.material-icons.flight_takeoff:before {
  content: "\e905";
}
.material-icons.flip:before {
  content: "\e3e8";
}
.material-icons.flip_camera_android:before {
  content: "\ea37";
}
.material-icons.flip_camera_ios:before {
  content: "\ea38";
}
.material-icons.flip_to_back:before {
  content: "\e882";
}
.material-icons.flip_to_front:before {
  content: "\e883";
}
.material-icons.flood:before {
  content: "\ebe6";
}
.material-icons.flourescent:before {
  content: "\f00d";
}
.material-icons.flutter_dash:before {
  content: "\e00b";
}
.material-icons.fmd_bad:before {
  content: "\f00e";
}
.material-icons.fmd_good:before {
  content: "\f00f";
}
.material-icons.foggy:before {
  content: "\e818";
}
.material-icons.folder:before {
  content: "\e2c7";
}
.material-icons.folder_copy:before {
  content: "\ebbd";
}
.material-icons.folder_delete:before {
  content: "\eb34";
}
.material-icons.folder_off:before {
  content: "\eb83";
}
.material-icons.folder_open:before {
  content: "\e2c8";
}
.material-icons.folder_shared:before {
  content: "\e2c9";
}
.material-icons.folder_special:before {
  content: "\e617";
}
.material-icons.folder_zip:before {
  content: "\eb2c";
}
.material-icons.follow_the_signs:before {
  content: "\f222";
}
.material-icons.font_download:before {
  content: "\e167";
}
.material-icons.font_download_off:before {
  content: "\e4f9";
}
.material-icons.food_bank:before {
  content: "\f1f2";
}
.material-icons.forest:before {
  content: "\ea99";
}
.material-icons.fork_left:before {
  content: "\eba0";
}
.material-icons.fork_right:before {
  content: "\ebac";
}
.material-icons.format_align_center:before {
  content: "\e234";
}
.material-icons.format_align_justify:before {
  content: "\e235";
}
.material-icons.format_align_left:before {
  content: "\e236";
}
.material-icons.format_align_right:before {
  content: "\e237";
}
.material-icons.format_bold:before {
  content: "\e238";
}
.material-icons.format_clear:before {
  content: "\e239";
}
.material-icons.format_color_fill:before {
  content: "\e23a";
}
.material-icons.format_color_reset:before {
  content: "\e23b";
}
.material-icons.format_color_text:before {
  content: "\e23c";
}
.material-icons.format_indent_decrease:before {
  content: "\e23d";
}
.material-icons.format_indent_increase:before {
  content: "\e23e";
}
.material-icons.format_italic:before {
  content: "\e23f";
}
.material-icons.format_line_spacing:before {
  content: "\e240";
}
.material-icons.format_list_bulleted:before {
  content: "\e241";
}
.material-icons.format_list_numbered:before {
  content: "\e242";
}
.material-icons.format_list_numbered_rtl:before {
  content: "\e267";
}
.material-icons.format_overline:before {
  content: "\eb65";
}
.material-icons.format_paint:before {
  content: "\e243";
}
.material-icons.format_quote:before {
  content: "\e244";
}
.material-icons.format_shapes:before {
  content: "\e25e";
}
.material-icons.format_size:before {
  content: "\e245";
}
.material-icons.format_strikethrough:before {
  content: "\e246";
}
.material-icons.format_textdirection_l_to_r:before {
  content: "\e247";
}
.material-icons.format_textdirection_r_to_l:before {
  content: "\e248";
}
.material-icons.format_underline:before {
  content: "\e249";
}
.material-icons.format_underlined:before {
  content: "\e249";
}
.material-icons.fort:before {
  content: "\eaad";
}
.material-icons.forum:before {
  content: "\e0bf";
}
.material-icons.forward:before {
  content: "\e154";
}
.material-icons.forward_10:before {
  content: "\e056";
}
.material-icons.forward_30:before {
  content: "\e057";
}
.material-icons.forward_5:before {
  content: "\e058";
}
.material-icons.forward_to_inbox:before {
  content: "\f187";
}
.material-icons.foundation:before {
  content: "\f200";
}
.material-icons.free_breakfast:before {
  content: "\eb44";
}
.material-icons.free_cancellation:before {
  content: "\e748";
}
.material-icons.front_hand:before {
  content: "\e769";
}
.material-icons.fullscreen:before {
  content: "\e5d0";
}
.material-icons.fullscreen_exit:before {
  content: "\e5d1";
}
.material-icons.functions:before {
  content: "\e24a";
}
.material-icons.g_mobiledata:before {
  content: "\f010";
}
.material-icons.g_translate:before {
  content: "\e927";
}
.material-icons.gamepad:before {
  content: "\e30f";
}
.material-icons.games:before {
  content: "\e021";
}
.material-icons.garage:before {
  content: "\f011";
}
.material-icons.gas_meter:before {
  content: "\ec19";
}
.material-icons.gavel:before {
  content: "\e90e";
}
.material-icons.generating_tokens:before {
  content: "\e749";
}
.material-icons.gesture:before {
  content: "\e155";
}
.material-icons.get_app:before {
  content: "\e884";
}
.material-icons.gif:before {
  content: "\e908";
}
.material-icons.gif_box:before {
  content: "\e7a3";
}
.material-icons.girl:before {
  content: "\eb68";
}
.material-icons.gite:before {
  content: "\e58b";
}
.material-icons.goat:before {
  content: "\ebff";
}
.material-icons.golf_course:before {
  content: "\eb45";
}
.material-icons.gpp_bad:before {
  content: "\f012";
}
.material-icons.gpp_good:before {
  content: "\f013";
}
.material-icons.gpp_maybe:before {
  content: "\f014";
}
.material-icons.gps_fixed:before {
  content: "\e1b3";
}
.material-icons.gps_not_fixed:before {
  content: "\e1b4";
}
.material-icons.gps_off:before {
  content: "\e1b5";
}
.material-icons.grade:before {
  content: "\e885";
}
.material-icons.gradient:before {
  content: "\e3e9";
}
.material-icons.grading:before {
  content: "\ea4f";
}
.material-icons.grain:before {
  content: "\e3ea";
}
.material-icons.graphic_eq:before {
  content: "\e1b8";
}
.material-icons.grass:before {
  content: "\f205";
}
.material-icons.grid_3x3:before {
  content: "\f015";
}
.material-icons.grid_4x4:before {
  content: "\f016";
}
.material-icons.grid_goldenratio:before {
  content: "\f017";
}
.material-icons.grid_off:before {
  content: "\e3eb";
}
.material-icons.grid_on:before {
  content: "\e3ec";
}
.material-icons.grid_view:before {
  content: "\e9b0";
}
.material-icons.group:before {
  content: "\e7ef";
}
.material-icons.group_add:before {
  content: "\e7f0";
}
.material-icons.group_off:before {
  content: "\e747";
}
.material-icons.group_remove:before {
  content: "\e7ad";
}
.material-icons.group_work:before {
  content: "\e886";
}
.material-icons.groups:before {
  content: "\f233";
}
.material-icons.h_mobiledata:before {
  content: "\f018";
}
.material-icons.h_plus_mobiledata:before {
  content: "\f019";
}
.material-icons.hail:before {
  content: "\e9b1";
}
.material-icons.handshake:before {
  content: "\ebcb";
}
.material-icons.handyman:before {
  content: "\f10b";
}
.material-icons.hardware:before {
  content: "\ea59";
}
.material-icons.hd:before {
  content: "\e052";
}
.material-icons.hdr_auto:before {
  content: "\f01a";
}
.material-icons.hdr_auto_select:before {
  content: "\f01b";
}
.material-icons.hdr_enhanced_select:before {
  content: "\ef51";
}
.material-icons.hdr_off:before {
  content: "\e3ed";
}
.material-icons.hdr_off_select:before {
  content: "\f01c";
}
.material-icons.hdr_on:before {
  content: "\e3ee";
}
.material-icons.hdr_on_select:before {
  content: "\f01d";
}
.material-icons.hdr_plus:before {
  content: "\f01e";
}
.material-icons.hdr_strong:before {
  content: "\e3f1";
}
.material-icons.hdr_weak:before {
  content: "\e3f2";
}
.material-icons.headphones:before {
  content: "\f01f";
}
.material-icons.headphones_battery:before {
  content: "\f020";
}
.material-icons.headset:before {
  content: "\e310";
}
.material-icons.headset_mic:before {
  content: "\e311";
}
.material-icons.headset_off:before {
  content: "\e33a";
}
.material-icons.healing:before {
  content: "\e3f3";
}
.material-icons.health_and_safety:before {
  content: "\e1d5";
}
.material-icons.hearing:before {
  content: "\e023";
}
.material-icons.hearing_disabled:before {
  content: "\f104";
}
.material-icons.heart_broken:before {
  content: "\eac2";
}
.material-icons.heat_pump:before {
  content: "\ec18";
}
.material-icons.height:before {
  content: "\ea16";
}
.material-icons.help:before {
  content: "\e887";
}
.material-icons.help_center:before {
  content: "\f1c0";
}
.material-icons.help_outline:before {
  content: "\e8fd";
}
.material-icons.hevc:before {
  content: "\f021";
}
.material-icons.hexagon:before {
  content: "\eb39";
}
.material-icons.hide_image:before {
  content: "\f022";
}
.material-icons.hide_source:before {
  content: "\f023";
}
.material-icons.high_quality:before {
  content: "\e024";
}
.material-icons.highlight:before {
  content: "\e25f";
}
.material-icons.highlight_alt:before {
  content: "\ef52";
}
.material-icons.highlight_off:before {
  content: "\e888";
}
.material-icons.highlight_remove:before {
  content: "\e888";
}
.material-icons.hiking:before {
  content: "\e50a";
}
.material-icons.history:before {
  content: "\e889";
}
.material-icons.history_edu:before {
  content: "\ea3e";
}
.material-icons.history_toggle_off:before {
  content: "\f17d";
}
.material-icons.hive:before {
  content: "\eaa6";
}
.material-icons.hls:before {
  content: "\eb8a";
}
.material-icons.hls_off:before {
  content: "\eb8c";
}
.material-icons.holiday_village:before {
  content: "\e58a";
}
.material-icons.home:before {
  content: "\e88a";
}
.material-icons.home_filled:before {
  content: "\e9b2";
}
.material-icons.home_max:before {
  content: "\f024";
}
.material-icons.home_mini:before {
  content: "\f025";
}
.material-icons.home_repair_service:before {
  content: "\f100";
}
.material-icons.home_work:before {
  content: "\ea09";
}
.material-icons.horizontal_distribute:before {
  content: "\e014";
}
.material-icons.horizontal_rule:before {
  content: "\f108";
}
.material-icons.horizontal_split:before {
  content: "\e947";
}
.material-icons.hot_tub:before {
  content: "\eb46";
}
.material-icons.hotel:before {
  content: "\e53a";
}
.material-icons.hotel_class:before {
  content: "\e743";
}
.material-icons.hourglass_bottom:before {
  content: "\ea5c";
}
.material-icons.hourglass_disabled:before {
  content: "\ef53";
}
.material-icons.hourglass_empty:before {
  content: "\e88b";
}
.material-icons.hourglass_full:before {
  content: "\e88c";
}
.material-icons.hourglass_top:before {
  content: "\ea5b";
}
.material-icons.house:before {
  content: "\ea44";
}
.material-icons.house_siding:before {
  content: "\f202";
}
.material-icons.houseboat:before {
  content: "\e584";
}
.material-icons.how_to_reg:before {
  content: "\e174";
}
.material-icons.how_to_vote:before {
  content: "\e175";
}
.material-icons.html:before {
  content: "\eb7e";
}
.material-icons.http:before {
  content: "\e902";
}
.material-icons.https:before {
  content: "\e88d";
}
.material-icons.hub:before {
  content: "\e9f4";
}
.material-icons.hvac:before {
  content: "\f10e";
}
.material-icons.ice_skating:before {
  content: "\e50b";
}
.material-icons.icecream:before {
  content: "\ea69";
}
.material-icons.image:before {
  content: "\e3f4";
}
.material-icons.image_aspect_ratio:before {
  content: "\e3f5";
}
.material-icons.image_not_supported:before {
  content: "\f116";
}
.material-icons.image_search:before {
  content: "\e43f";
}
.material-icons.imagesearch_roller:before {
  content: "\e9b4";
}
.material-icons.import_contacts:before {
  content: "\e0e0";
}
.material-icons.import_export:before {
  content: "\e0c3";
}
.material-icons.important_devices:before {
  content: "\e912";
}
.material-icons.inbox:before {
  content: "\e156";
}
.material-icons.incomplete_circle:before {
  content: "\e79b";
}
.material-icons.indeterminate_check_box:before {
  content: "\e909";
}
.material-icons.info:before {
  content: "\e88e";
}
.material-icons.info_outline:before {
  content: "\e88f";
}
.material-icons.input:before {
  content: "\e890";
}
.material-icons.insert_chart:before {
  content: "\e24b";
}
.material-icons.insert_chart_outlined:before {
  content: "\e26a";
}
.material-icons.insert_comment:before {
  content: "\e24c";
}
.material-icons.insert_drive_file:before {
  content: "\e24d";
}
.material-icons.insert_emoticon:before {
  content: "\e24e";
}
.material-icons.insert_invitation:before {
  content: "\e24f";
}
.material-icons.insert_link:before {
  content: "\e250";
}
.material-icons.insert_page_break:before {
  content: "\eaca";
}
.material-icons.insert_photo:before {
  content: "\e251";
}
.material-icons.insights:before {
  content: "\f092";
}
.material-icons.install_desktop:before {
  content: "\eb71";
}
.material-icons.install_mobile:before {
  content: "\eb72";
}
.material-icons.integration_instructions:before {
  content: "\ef54";
}
.material-icons.interests:before {
  content: "\e7c8";
}
.material-icons.interpreter_mode:before {
  content: "\e83b";
}
.material-icons.inventory:before {
  content: "\e179";
}
.material-icons.inventory_2:before {
  content: "\e1a1";
}
.material-icons.invert_colors:before {
  content: "\e891";
}
.material-icons.invert_colors_off:before {
  content: "\e0c4";
}
.material-icons.invert_colors_on:before {
  content: "\e891";
}
.material-icons.ios_share:before {
  content: "\e6b8";
}
.material-icons.iron:before {
  content: "\e583";
}
.material-icons.iso:before {
  content: "\e3f6";
}
.material-icons.javascript:before {
  content: "\eb7c";
}
.material-icons.join_full:before {
  content: "\eaeb";
}
.material-icons.join_inner:before {
  content: "\eaf4";
}
.material-icons.join_left:before {
  content: "\eaf2";
}
.material-icons.join_right:before {
  content: "\eaea";
}
.material-icons.kayaking:before {
  content: "\e50c";
}
.material-icons.kebab_dining:before {
  content: "\e842";
}
.material-icons.key:before {
  content: "\e73c";
}
.material-icons.key_off:before {
  content: "\eb84";
}
.material-icons.keyboard:before {
  content: "\e312";
}
.material-icons.keyboard_alt:before {
  content: "\f028";
}
.material-icons.keyboard_arrow_down:before {
  content: "\e313";
}
.material-icons.keyboard_arrow_left:before {
  content: "\e314";
}
.material-icons.keyboard_arrow_right:before {
  content: "\e315";
}
.material-icons.keyboard_arrow_up:before {
  content: "\e316";
}
.material-icons.keyboard_backspace:before {
  content: "\e317";
}
.material-icons.keyboard_capslock:before {
  content: "\e318";
}
.material-icons.keyboard_command:before {
  content: "\eae0";
}
.material-icons.keyboard_command_key:before {
  content: "\eae7";
}
.material-icons.keyboard_control:before {
  content: "\e5d3";
}
.material-icons.keyboard_control_key:before {
  content: "\eae6";
}
.material-icons.keyboard_double_arrow_down:before {
  content: "\ead0";
}
.material-icons.keyboard_double_arrow_left:before {
  content: "\eac3";
}
.material-icons.keyboard_double_arrow_right:before {
  content: "\eac9";
}
.material-icons.keyboard_double_arrow_up:before {
  content: "\eacf";
}
.material-icons.keyboard_hide:before {
  content: "\e31a";
}
.material-icons.keyboard_option:before {
  content: "\eadf";
}
.material-icons.keyboard_option_key:before {
  content: "\eae8";
}
.material-icons.keyboard_return:before {
  content: "\e31b";
}
.material-icons.keyboard_tab:before {
  content: "\e31c";
}
.material-icons.keyboard_voice:before {
  content: "\e31d";
}
.material-icons.king_bed:before {
  content: "\ea45";
}
.material-icons.kitchen:before {
  content: "\eb47";
}
.material-icons.kitesurfing:before {
  content: "\e50d";
}
.material-icons.label:before {
  content: "\e892";
}
.material-icons.label_important:before {
  content: "\e937";
}
.material-icons.label_important_outline:before {
  content: "\e948";
}
.material-icons.label_off:before {
  content: "\e9b6";
}
.material-icons.label_outline:before {
  content: "\e893";
}
.material-icons.lan:before {
  content: "\eb2f";
}
.material-icons.landscape:before {
  content: "\e3f7";
}
.material-icons.landslide:before {
  content: "\ebd7";
}
.material-icons.language:before {
  content: "\e894";
}
.material-icons.laptop:before {
  content: "\e31e";
}
.material-icons.laptop_chromebook:before {
  content: "\e31f";
}
.material-icons.laptop_mac:before {
  content: "\e320";
}
.material-icons.laptop_windows:before {
  content: "\e321";
}
.material-icons.last_page:before {
  content: "\e5dd";
}
.material-icons.launch:before {
  content: "\e895";
}
.material-icons.layers:before {
  content: "\e53b";
}
.material-icons.layers_clear:before {
  content: "\e53c";
}
.material-icons.leaderboard:before {
  content: "\f20c";
}
.material-icons.leak_add:before {
  content: "\e3f8";
}
.material-icons.leak_remove:before {
  content: "\e3f9";
}
.material-icons.leave_bags_at_home:before {
  content: "\f21b";
}
.material-icons.legend_toggle:before {
  content: "\f11b";
}
.material-icons.lens:before {
  content: "\e3fa";
}
.material-icons.lens_blur:before {
  content: "\f029";
}
.material-icons.library_add:before {
  content: "\e02e";
}
.material-icons.library_add_check:before {
  content: "\e9b7";
}
.material-icons.library_books:before {
  content: "\e02f";
}
.material-icons.library_music:before {
  content: "\e030";
}
.material-icons.light:before {
  content: "\f02a";
}
.material-icons.light_mode:before {
  content: "\e518";
}
.material-icons.lightbulb:before {
  content: "\e0f0";
}
.material-icons.lightbulb_circle:before {
  content: "\ebfe";
}
.material-icons.lightbulb_outline:before {
  content: "\e90f";
}
.material-icons.line_axis:before {
  content: "\ea9a";
}
.material-icons.line_style:before {
  content: "\e919";
}
.material-icons.line_weight:before {
  content: "\e91a";
}
.material-icons.linear_scale:before {
  content: "\e260";
}
.material-icons.link:before {
  content: "\e157";
}
.material-icons.link_off:before {
  content: "\e16f";
}
.material-icons.linked_camera:before {
  content: "\e438";
}
.material-icons.liquor:before {
  content: "\ea60";
}
.material-icons.list:before {
  content: "\e896";
}
.material-icons.list_alt:before {
  content: "\e0ee";
}
.material-icons.live_help:before {
  content: "\e0c6";
}
.material-icons.live_tv:before {
  content: "\e639";
}
.material-icons.living:before {
  content: "\f02b";
}
.material-icons.local_activity:before {
  content: "\e53f";
}
.material-icons.local_airport:before {
  content: "\e53d";
}
.material-icons.local_atm:before {
  content: "\e53e";
}
.material-icons.local_attraction:before {
  content: "\e53f";
}
.material-icons.local_bar:before {
  content: "\e540";
}
.material-icons.local_cafe:before {
  content: "\e541";
}
.material-icons.local_car_wash:before {
  content: "\e542";
}
.material-icons.local_convenience_store:before {
  content: "\e543";
}
.material-icons.local_dining:before {
  content: "\e556";
}
.material-icons.local_drink:before {
  content: "\e544";
}
.material-icons.local_fire_department:before {
  content: "\ef55";
}
.material-icons.local_florist:before {
  content: "\e545";
}
.material-icons.local_gas_station:before {
  content: "\e546";
}
.material-icons.local_grocery_store:before {
  content: "\e547";
}
.material-icons.local_hospital:before {
  content: "\e548";
}
.material-icons.local_hotel:before {
  content: "\e549";
}
.material-icons.local_laundry_service:before {
  content: "\e54a";
}
.material-icons.local_library:before {
  content: "\e54b";
}
.material-icons.local_mall:before {
  content: "\e54c";
}
.material-icons.local_movies:before {
  content: "\e54d";
}
.material-icons.local_offer:before {
  content: "\e54e";
}
.material-icons.local_parking:before {
  content: "\e54f";
}
.material-icons.local_pharmacy:before {
  content: "\e550";
}
.material-icons.local_phone:before {
  content: "\e551";
}
.material-icons.local_pizza:before {
  content: "\e552";
}
.material-icons.local_play:before {
  content: "\e553";
}
.material-icons.local_police:before {
  content: "\ef56";
}
.material-icons.local_post_office:before {
  content: "\e554";
}
.material-icons.local_print_shop:before {
  content: "\e555";
}
.material-icons.local_printshop:before {
  content: "\e555";
}
.material-icons.local_restaurant:before {
  content: "\e556";
}
.material-icons.local_see:before {
  content: "\e557";
}
.material-icons.local_shipping:before {
  content: "\e558";
}
.material-icons.local_taxi:before {
  content: "\e559";
}
.material-icons.location_city:before {
  content: "\e7f1";
}
.material-icons.location_disabled:before {
  content: "\e1b6";
}
.material-icons.location_history:before {
  content: "\e55a";
}
.material-icons.location_off:before {
  content: "\e0c7";
}
.material-icons.location_on:before {
  content: "\e0c8";
}
.material-icons.location_pin:before {
  content: "\f1db";
}
.material-icons.location_searching:before {
  content: "\e1b7";
}
.material-icons.lock:before {
  content: "\e897";
}
.material-icons.lock_clock:before {
  content: "\ef57";
}
.material-icons.lock_open:before {
  content: "\e898";
}
.material-icons.lock_outline:before {
  content: "\e899";
}
.material-icons.lock_person:before {
  content: "\f8f3";
}
.material-icons.lock_reset:before {
  content: "\eade";
}
.material-icons.login:before {
  content: "\ea77";
}
.material-icons.logo_dev:before {
  content: "\ead6";
}
.material-icons.logout:before {
  content: "\e9ba";
}
.material-icons.looks:before {
  content: "\e3fc";
}
.material-icons.looks_3:before {
  content: "\e3fb";
}
.material-icons.looks_4:before {
  content: "\e3fd";
}
.material-icons.looks_5:before {
  content: "\e3fe";
}
.material-icons.looks_6:before {
  content: "\e3ff";
}
.material-icons.looks_one:before {
  content: "\e400";
}
.material-icons.looks_two:before {
  content: "\e401";
}
.material-icons.loop:before {
  content: "\e028";
}
.material-icons.loupe:before {
  content: "\e402";
}
.material-icons.low_priority:before {
  content: "\e16d";
}
.material-icons.loyalty:before {
  content: "\e89a";
}
.material-icons.lte_mobiledata:before {
  content: "\f02c";
}
.material-icons.lte_plus_mobiledata:before {
  content: "\f02d";
}
.material-icons.luggage:before {
  content: "\f235";
}
.material-icons.lunch_dining:before {
  content: "\ea61";
}
.material-icons.lyrics:before {
  content: "\ec0b";
}
.material-icons.mail:before {
  content: "\e158";
}
.material-icons.mail_lock:before {
  content: "\ec0a";
}
.material-icons.mail_outline:before {
  content: "\e0e1";
}
.material-icons.male:before {
  content: "\e58e";
}
.material-icons.man:before {
  content: "\e4eb";
}
.material-icons.manage_accounts:before {
  content: "\f02e";
}
.material-icons.manage_history:before {
  content: "\ebe7";
}
.material-icons.manage_search:before {
  content: "\f02f";
}
.material-icons.map:before {
  content: "\e55b";
}
.material-icons.maps_home_work:before {
  content: "\f030";
}
.material-icons.maps_ugc:before {
  content: "\ef58";
}
.material-icons.margin:before {
  content: "\e9bb";
}
.material-icons.mark_as_unread:before {
  content: "\e9bc";
}
.material-icons.mark_chat_read:before {
  content: "\f18b";
}
.material-icons.mark_chat_unread:before {
  content: "\f189";
}
.material-icons.mark_email_read:before {
  content: "\f18c";
}
.material-icons.mark_email_unread:before {
  content: "\f18a";
}
.material-icons.mark_unread_chat_alt:before {
  content: "\eb9d";
}
.material-icons.markunread:before {
  content: "\e159";
}
.material-icons.markunread_mailbox:before {
  content: "\e89b";
}
.material-icons.masks:before {
  content: "\f218";
}
.material-icons.maximize:before {
  content: "\e930";
}
.material-icons.media_bluetooth_off:before {
  content: "\f031";
}
.material-icons.media_bluetooth_on:before {
  content: "\f032";
}
.material-icons.mediation:before {
  content: "\efa7";
}
.material-icons.medical_information:before {
  content: "\ebed";
}
.material-icons.medical_services:before {
  content: "\f109";
}
.material-icons.medication:before {
  content: "\f033";
}
.material-icons.medication_liquid:before {
  content: "\ea87";
}
.material-icons.meeting_room:before {
  content: "\eb4f";
}
.material-icons.memory:before {
  content: "\e322";
}
.material-icons.menu:before {
  content: "\e5d2";
}
.material-icons.menu_book:before {
  content: "\ea19";
}
.material-icons.menu_open:before {
  content: "\e9bd";
}
.material-icons.merge:before {
  content: "\eb98";
}
.material-icons.merge_type:before {
  content: "\e252";
}
.material-icons.message:before {
  content: "\e0c9";
}
.material-icons.messenger:before {
  content: "\e0ca";
}
.material-icons.messenger_outline:before {
  content: "\e0cb";
}
.material-icons.mic:before {
  content: "\e029";
}
.material-icons.mic_external_off:before {
  content: "\ef59";
}
.material-icons.mic_external_on:before {
  content: "\ef5a";
}
.material-icons.mic_none:before {
  content: "\e02a";
}
.material-icons.mic_off:before {
  content: "\e02b";
}
.material-icons.microwave:before {
  content: "\f204";
}
.material-icons.military_tech:before {
  content: "\ea3f";
}
.material-icons.minimize:before {
  content: "\e931";
}
.material-icons.minor_crash:before {
  content: "\ebf1";
}
.material-icons.miscellaneous_services:before {
  content: "\f10c";
}
.material-icons.missed_video_call:before {
  content: "\e073";
}
.material-icons.mms:before {
  content: "\e618";
}
.material-icons.mobile_friendly:before {
  content: "\e200";
}
.material-icons.mobile_off:before {
  content: "\e201";
}
.material-icons.mobile_screen_share:before {
  content: "\e0e7";
}
.material-icons.mobiledata_off:before {
  content: "\f034";
}
.material-icons.mode:before {
  content: "\f097";
}
.material-icons.mode_comment:before {
  content: "\e253";
}
.material-icons.mode_edit:before {
  content: "\e254";
}
.material-icons.mode_edit_outline:before {
  content: "\f035";
}
.material-icons.mode_fan_off:before {
  content: "\ec17";
}
.material-icons.mode_night:before {
  content: "\f036";
}
.material-icons.mode_of_travel:before {
  content: "\e7ce";
}
.material-icons.mode_standby:before {
  content: "\f037";
}
.material-icons.model_training:before {
  content: "\f0cf";
}
.material-icons.monetization_on:before {
  content: "\e263";
}
.material-icons.money:before {
  content: "\e57d";
}
.material-icons.money_off:before {
  content: "\e25c";
}
.material-icons.money_off_csred:before {
  content: "\f038";
}
.material-icons.monitor:before {
  content: "\ef5b";
}
.material-icons.monitor_heart:before {
  content: "\eaa2";
}
.material-icons.monitor_weight:before {
  content: "\f039";
}
.material-icons.monochrome_photos:before {
  content: "\e403";
}
.material-icons.mood:before {
  content: "\e7f2";
}
.material-icons.mood_bad:before {
  content: "\e7f3";
}
.material-icons.moped:before {
  content: "\eb28";
}
.material-icons.more:before {
  content: "\e619";
}
.material-icons.more_horiz:before {
  content: "\e5d3";
}
.material-icons.more_time:before {
  content: "\ea5d";
}
.material-icons.more_vert:before {
  content: "\e5d4";
}
.material-icons.mosque:before {
  content: "\eab2";
}
.material-icons.motion_photos_auto:before {
  content: "\f03a";
}
.material-icons.motion_photos_off:before {
  content: "\e9c0";
}
.material-icons.motion_photos_on:before {
  content: "\e9c1";
}
.material-icons.motion_photos_pause:before {
  content: "\f227";
}
.material-icons.motion_photos_paused:before {
  content: "\e9c2";
}
.material-icons.motorcycle:before {
  content: "\e91b";
}
.material-icons.mouse:before {
  content: "\e323";
}
.material-icons.move_down:before {
  content: "\eb61";
}
.material-icons.move_to_inbox:before {
  content: "\e168";
}
.material-icons.move_up:before {
  content: "\eb64";
}
.material-icons.movie:before {
  content: "\e02c";
}
.material-icons.movie_creation:before {
  content: "\e404";
}
.material-icons.movie_filter:before {
  content: "\e43a";
}
.material-icons.moving:before {
  content: "\e501";
}
.material-icons.mp:before {
  content: "\e9c3";
}
.material-icons.multiline_chart:before {
  content: "\e6df";
}
.material-icons.multiple_stop:before {
  content: "\f1b9";
}
.material-icons.multitrack_audio:before {
  content: "\e1b8";
}
.material-icons.museum:before {
  content: "\ea36";
}
.material-icons.music_note:before {
  content: "\e405";
}
.material-icons.music_off:before {
  content: "\e440";
}
.material-icons.music_video:before {
  content: "\e063";
}
.material-icons.my_library_add:before {
  content: "\e02e";
}
.material-icons.my_library_books:before {
  content: "\e02f";
}
.material-icons.my_library_music:before {
  content: "\e030";
}
.material-icons.my_location:before {
  content: "\e55c";
}
.material-icons.nat:before {
  content: "\ef5c";
}
.material-icons.nature:before {
  content: "\e406";
}
.material-icons.nature_people:before {
  content: "\e407";
}
.material-icons.navigate_before:before {
  content: "\e408";
}
.material-icons.navigate_next:before {
  content: "\e409";
}
.material-icons.navigation:before {
  content: "\e55d";
}
.material-icons.near_me:before {
  content: "\e569";
}
.material-icons.near_me_disabled:before {
  content: "\f1ef";
}
.material-icons.nearby_error:before {
  content: "\f03b";
}
.material-icons.nearby_off:before {
  content: "\f03c";
}
.material-icons.nest_cam_wired_stand:before {
  content: "\ec16";
}
.material-icons.network_cell:before {
  content: "\e1b9";
}
.material-icons.network_check:before {
  content: "\e640";
}
.material-icons.network_locked:before {
  content: "\e61a";
}
.material-icons.network_ping:before {
  content: "\ebca";
}
.material-icons.network_wifi:before {
  content: "\e1ba";
}
.material-icons.network_wifi_1_bar:before {
  content: "\ebe4";
}
.material-icons.network_wifi_2_bar:before {
  content: "\ebd6";
}
.material-icons.network_wifi_3_bar:before {
  content: "\ebe1";
}
.material-icons.new_label:before {
  content: "\e609";
}
.material-icons.new_releases:before {
  content: "\e031";
}
.material-icons.newspaper:before {
  content: "\eb81";
}
.material-icons.next_plan:before {
  content: "\ef5d";
}
.material-icons.next_week:before {
  content: "\e16a";
}
.material-icons.nfc:before {
  content: "\e1bb";
}
.material-icons.night_shelter:before {
  content: "\f1f1";
}
.material-icons.nightlife:before {
  content: "\ea62";
}
.material-icons.nightlight:before {
  content: "\f03d";
}
.material-icons.nightlight_round:before {
  content: "\ef5e";
}
.material-icons.nights_stay:before {
  content: "\ea46";
}
.material-icons.no_accounts:before {
  content: "\f03e";
}
.material-icons.no_adult_content:before {
  content: "\f8fe";
}
.material-icons.no_backpack:before {
  content: "\f237";
}
.material-icons.no_cell:before {
  content: "\f1a4";
}
.material-icons.no_crash:before {
  content: "\ebf0";
}
.material-icons.no_drinks:before {
  content: "\f1a5";
}
.material-icons.no_encryption:before {
  content: "\e641";
}
.material-icons.no_encryption_gmailerrorred:before {
  content: "\f03f";
}
.material-icons.no_flash:before {
  content: "\f1a6";
}
.material-icons.no_food:before {
  content: "\f1a7";
}
.material-icons.no_luggage:before {
  content: "\f23b";
}
.material-icons.no_meals:before {
  content: "\f1d6";
}
.material-icons.no_meals_ouline:before {
  content: "\f229";
}
.material-icons.no_meeting_room:before {
  content: "\eb4e";
}
.material-icons.no_photography:before {
  content: "\f1a8";
}
.material-icons.no_sim:before {
  content: "\e0cc";
}
.material-icons.no_stroller:before {
  content: "\f1af";
}
.material-icons.no_transfer:before {
  content: "\f1d5";
}
.material-icons.noise_aware:before {
  content: "\ebec";
}
.material-icons.noise_control_off:before {
  content: "\ebf3";
}
.material-icons.nordic_walking:before {
  content: "\e50e";
}
.material-icons.north:before {
  content: "\f1e0";
}
.material-icons.north_east:before {
  content: "\f1e1";
}
.material-icons.north_west:before {
  content: "\f1e2";
}
.material-icons.not_accessible:before {
  content: "\f0fe";
}
.material-icons.not_interested:before {
  content: "\e033";
}
.material-icons.not_listed_location:before {
  content: "\e575";
}
.material-icons.not_started:before {
  content: "\f0d1";
}
.material-icons.note:before {
  content: "\e06f";
}
.material-icons.note_add:before {
  content: "\e89c";
}
.material-icons.note_alt:before {
  content: "\f040";
}
.material-icons.notes:before {
  content: "\e26c";
}
.material-icons.notification_add:before {
  content: "\e399";
}
.material-icons.notification_important:before {
  content: "\e004";
}
.material-icons.notifications:before {
  content: "\e7f4";
}
.material-icons.notifications_active:before {
  content: "\e7f7";
}
.material-icons.notifications_none:before {
  content: "\e7f5";
}
.material-icons.notifications_off:before {
  content: "\e7f6";
}
.material-icons.notifications_on:before {
  content: "\e7f7";
}
.material-icons.notifications_paused:before {
  content: "\e7f8";
}
.material-icons.now_wallpaper:before {
  content: "\e1bc";
}
.material-icons.now_widgets:before {
  content: "\e1bd";
}
.material-icons.numbers:before {
  content: "\eac7";
}
.material-icons.offline_bolt:before {
  content: "\e932";
}
.material-icons.offline_pin:before {
  content: "\e90a";
}
.material-icons.offline_share:before {
  content: "\e9c5";
}
.material-icons.oil_barrel:before {
  content: "\ec15";
}
.material-icons.on_device_training:before {
  content: "\ebfd";
}
.material-icons.ondemand_video:before {
  content: "\e63a";
}
.material-icons.online_prediction:before {
  content: "\f0eb";
}
.material-icons.opacity:before {
  content: "\e91c";
}
.material-icons.open_in_browser:before {
  content: "\e89d";
}
.material-icons.open_in_full:before {
  content: "\f1ce";
}
.material-icons.open_in_new:before {
  content: "\e89e";
}
.material-icons.open_in_new_off:before {
  content: "\e4f6";
}
.material-icons.open_with:before {
  content: "\e89f";
}
.material-icons.other_houses:before {
  content: "\e58c";
}
.material-icons.outbond:before {
  content: "\f228";
}
.material-icons.outbound:before {
  content: "\e1ca";
}
.material-icons.outbox:before {
  content: "\ef5f";
}
.material-icons.outdoor_grill:before {
  content: "\ea47";
}
.material-icons.outgoing_mail:before {
  content: "\f0d2";
}
.material-icons.outlet:before {
  content: "\f1d4";
}
.material-icons.outlined_flag:before {
  content: "\e16e";
}
.material-icons.output:before {
  content: "\ebbe";
}
.material-icons.padding:before {
  content: "\e9c8";
}
.material-icons.pages:before {
  content: "\e7f9";
}
.material-icons.pageview:before {
  content: "\e8a0";
}
.material-icons.paid:before {
  content: "\f041";
}
.material-icons.palette:before {
  content: "\e40a";
}
.material-icons.pan_tool:before {
  content: "\e925";
}
.material-icons.pan_tool_alt:before {
  content: "\ebb9";
}
.material-icons.panorama:before {
  content: "\e40b";
}
.material-icons.panorama_fish_eye:before {
  content: "\e40c";
}
.material-icons.panorama_fisheye:before {
  content: "\e40c";
}
.material-icons.panorama_horizontal:before {
  content: "\e40d";
}
.material-icons.panorama_horizontal_select:before {
  content: "\ef60";
}
.material-icons.panorama_photosphere:before {
  content: "\e9c9";
}
.material-icons.panorama_photosphere_select:before {
  content: "\e9ca";
}
.material-icons.panorama_vertical:before {
  content: "\e40e";
}
.material-icons.panorama_vertical_select:before {
  content: "\ef61";
}
.material-icons.panorama_wide_angle:before {
  content: "\e40f";
}
.material-icons.panorama_wide_angle_select:before {
  content: "\ef62";
}
.material-icons.paragliding:before {
  content: "\e50f";
}
.material-icons.park:before {
  content: "\ea63";
}
.material-icons.party_mode:before {
  content: "\e7fa";
}
.material-icons.password:before {
  content: "\f042";
}
.material-icons.pattern:before {
  content: "\f043";
}
.material-icons.pause:before {
  content: "\e034";
}
.material-icons.pause_circle:before {
  content: "\e1a2";
}
.material-icons.pause_circle_filled:before {
  content: "\e035";
}
.material-icons.pause_circle_outline:before {
  content: "\e036";
}
.material-icons.pause_presentation:before {
  content: "\e0ea";
}
.material-icons.payment:before {
  content: "\e8a1";
}
.material-icons.payments:before {
  content: "\ef63";
}
.material-icons.paypal:before {
  content: "\ea8d";
}
.material-icons.pedal_bike:before {
  content: "\eb29";
}
.material-icons.pending:before {
  content: "\ef64";
}
.material-icons.pending_actions:before {
  content: "\f1bb";
}
.material-icons.pentagon:before {
  content: "\eb50";
}
.material-icons.people:before {
  content: "\e7fb";
}
.material-icons.people_alt:before {
  content: "\ea21";
}
.material-icons.people_outline:before {
  content: "\e7fc";
}
.material-icons.percent:before {
  content: "\eb58";
}
.material-icons.perm_camera_mic:before {
  content: "\e8a2";
}
.material-icons.perm_contact_cal:before {
  content: "\e8a3";
}
.material-icons.perm_contact_calendar:before {
  content: "\e8a3";
}
.material-icons.perm_data_setting:before {
  content: "\e8a4";
}
.material-icons.perm_device_info:before {
  content: "\e8a5";
}
.material-icons.perm_device_information:before {
  content: "\e8a5";
}
.material-icons.perm_identity:before {
  content: "\e8a6";
}
.material-icons.perm_media:before {
  content: "\e8a7";
}
.material-icons.perm_phone_msg:before {
  content: "\e8a8";
}
.material-icons.perm_scan_wifi:before {
  content: "\e8a9";
}
.material-icons.person:before {
  content: "\e7fd";
}
.material-icons.person_add:before {
  content: "\e7fe";
}
.material-icons.person_add_alt:before {
  content: "\ea4d";
}
.material-icons.person_add_alt_1:before {
  content: "\ef65";
}
.material-icons.person_add_disabled:before {
  content: "\e9cb";
}
.material-icons.person_off:before {
  content: "\e510";
}
.material-icons.person_outline:before {
  content: "\e7ff";
}
.material-icons.person_pin:before {
  content: "\e55a";
}
.material-icons.person_pin_circle:before {
  content: "\e56a";
}
.material-icons.person_remove:before {
  content: "\ef66";
}
.material-icons.person_remove_alt_1:before {
  content: "\ef67";
}
.material-icons.person_search:before {
  content: "\f106";
}
.material-icons.personal_injury:before {
  content: "\e6da";
}
.material-icons.personal_video:before {
  content: "\e63b";
}
.material-icons.pest_control:before {
  content: "\f0fa";
}
.material-icons.pest_control_rodent:before {
  content: "\f0fd";
}
.material-icons.pets:before {
  content: "\e91d";
}
.material-icons.phishing:before {
  content: "\ead7";
}
.material-icons.phone:before {
  content: "\e0cd";
}
.material-icons.phone_android:before {
  content: "\e324";
}
.material-icons.phone_bluetooth_speaker:before {
  content: "\e61b";
}
.material-icons.phone_callback:before {
  content: "\e649";
}
.material-icons.phone_disabled:before {
  content: "\e9cc";
}
.material-icons.phone_enabled:before {
  content: "\e9cd";
}
.material-icons.phone_forwarded:before {
  content: "\e61c";
}
.material-icons.phone_in_talk:before {
  content: "\e61d";
}
.material-icons.phone_iphone:before {
  content: "\e325";
}
.material-icons.phone_locked:before {
  content: "\e61e";
}
.material-icons.phone_missed:before {
  content: "\e61f";
}
.material-icons.phone_paused:before {
  content: "\e620";
}
.material-icons.phonelink:before {
  content: "\e326";
}
.material-icons.phonelink_erase:before {
  content: "\e0db";
}
.material-icons.phonelink_lock:before {
  content: "\e0dc";
}
.material-icons.phonelink_off:before {
  content: "\e327";
}
.material-icons.phonelink_ring:before {
  content: "\e0dd";
}
.material-icons.phonelink_setup:before {
  content: "\e0de";
}
.material-icons.photo:before {
  content: "\e410";
}
.material-icons.photo_album:before {
  content: "\e411";
}
.material-icons.photo_camera:before {
  content: "\e412";
}
.material-icons.photo_camera_back:before {
  content: "\ef68";
}
.material-icons.photo_camera_front:before {
  content: "\ef69";
}
.material-icons.photo_filter:before {
  content: "\e43b";
}
.material-icons.photo_library:before {
  content: "\e413";
}
.material-icons.photo_size_select_actual:before {
  content: "\e432";
}
.material-icons.photo_size_select_large:before {
  content: "\e433";
}
.material-icons.photo_size_select_small:before {
  content: "\e434";
}
.material-icons.php:before {
  content: "\eb8f";
}
.material-icons.piano:before {
  content: "\e521";
}
.material-icons.piano_off:before {
  content: "\e520";
}
.material-icons.picture_as_pdf:before {
  content: "\e415";
}
.material-icons.picture_in_picture:before {
  content: "\e8aa";
}
.material-icons.picture_in_picture_alt:before {
  content: "\e911";
}
.material-icons.pie_chart:before {
  content: "\e6c4";
}
.material-icons.pie_chart_outline:before {
  content: "\f044";
}
.material-icons.pie_chart_outlined:before {
  content: "\e6c5";
}
.material-icons.pin:before {
  content: "\f045";
}
.material-icons.pin_drop:before {
  content: "\e55e";
}
.material-icons.pin_end:before {
  content: "\e767";
}
.material-icons.pin_invoke:before {
  content: "\e763";
}
.material-icons.pinch:before {
  content: "\eb38";
}
.material-icons.pivot_table_chart:before {
  content: "\e9ce";
}
.material-icons.pix:before {
  content: "\eaa3";
}
.material-icons.place:before {
  content: "\e55f";
}
.material-icons.plagiarism:before {
  content: "\ea5a";
}
.material-icons.play_arrow:before {
  content: "\e037";
}
.material-icons.play_circle:before {
  content: "\e1c4";
}
.material-icons.play_circle_fill:before {
  content: "\e038";
}
.material-icons.play_circle_filled:before {
  content: "\e038";
}
.material-icons.play_circle_outline:before {
  content: "\e039";
}
.material-icons.play_disabled:before {
  content: "\ef6a";
}
.material-icons.play_for_work:before {
  content: "\e906";
}
.material-icons.play_lesson:before {
  content: "\f047";
}
.material-icons.playlist_add:before {
  content: "\e03b";
}
.material-icons.playlist_add_check:before {
  content: "\e065";
}
.material-icons.playlist_add_check_circle:before {
  content: "\e7e6";
}
.material-icons.playlist_add_circle:before {
  content: "\e7e5";
}
.material-icons.playlist_play:before {
  content: "\e05f";
}
.material-icons.playlist_remove:before {
  content: "\eb80";
}
.material-icons.plumbing:before {
  content: "\f107";
}
.material-icons.plus_one:before {
  content: "\e800";
}
.material-icons.podcasts:before {
  content: "\f048";
}
.material-icons.point_of_sale:before {
  content: "\f17e";
}
.material-icons.policy:before {
  content: "\ea17";
}
.material-icons.poll:before {
  content: "\e801";
}
.material-icons.polyline:before {
  content: "\ebbb";
}
.material-icons.polymer:before {
  content: "\e8ab";
}
.material-icons.pool:before {
  content: "\eb48";
}
.material-icons.portable_wifi_off:before {
  content: "\e0ce";
}
.material-icons.portrait:before {
  content: "\e416";
}
.material-icons.post_add:before {
  content: "\ea20";
}
.material-icons.power:before {
  content: "\e63c";
}
.material-icons.power_input:before {
  content: "\e336";
}
.material-icons.power_off:before {
  content: "\e646";
}
.material-icons.power_settings_new:before {
  content: "\e8ac";
}
.material-icons.precision_manufacturing:before {
  content: "\f049";
}
.material-icons.pregnant_woman:before {
  content: "\e91e";
}
.material-icons.present_to_all:before {
  content: "\e0df";
}
.material-icons.preview:before {
  content: "\f1c5";
}
.material-icons.price_change:before {
  content: "\f04a";
}
.material-icons.price_check:before {
  content: "\f04b";
}
.material-icons.print:before {
  content: "\e8ad";
}
.material-icons.print_disabled:before {
  content: "\e9cf";
}
.material-icons.priority_high:before {
  content: "\e645";
}
.material-icons.privacy_tip:before {
  content: "\f0dc";
}
.material-icons.private_connectivity:before {
  content: "\e744";
}
.material-icons.production_quantity_limits:before {
  content: "\e1d1";
}
.material-icons.propane:before {
  content: "\ec14";
}
.material-icons.propane_tank:before {
  content: "\ec13";
}
.material-icons.psychology:before {
  content: "\ea4a";
}
.material-icons.psychology_alt:before {
  content: "\f8ea";
}
.material-icons.public:before {
  content: "\e80b";
}
.material-icons.public_off:before {
  content: "\f1ca";
}
.material-icons.publish:before {
  content: "\e255";
}
.material-icons.published_with_changes:before {
  content: "\f232";
}
.material-icons.punch_clock:before {
  content: "\eaa8";
}
.material-icons.push_pin:before {
  content: "\f10d";
}
.material-icons.qr_code:before {
  content: "\ef6b";
}
.material-icons.qr_code_2:before {
  content: "\e00a";
}
.material-icons.qr_code_scanner:before {
  content: "\f206";
}
.material-icons.query_builder:before {
  content: "\e8ae";
}
.material-icons.query_stats:before {
  content: "\e4fc";
}
.material-icons.question_answer:before {
  content: "\e8af";
}
.material-icons.question_mark:before {
  content: "\eb8b";
}
.material-icons.queue:before {
  content: "\e03c";
}
.material-icons.queue_music:before {
  content: "\e03d";
}
.material-icons.queue_play_next:before {
  content: "\e066";
}
.material-icons.quick_contacts_dialer:before {
  content: "\e0cf";
}
.material-icons.quick_contacts_mail:before {
  content: "\e0d0";
}
.material-icons.quickreply:before {
  content: "\ef6c";
}
.material-icons.quiz:before {
  content: "\f04c";
}
.material-icons.quora:before {
  content: "\ea98";
}
.material-icons.r_mobiledata:before {
  content: "\f04d";
}
.material-icons.radar:before {
  content: "\f04e";
}
.material-icons.radio:before {
  content: "\e03e";
}
.material-icons.radio_button_checked:before {
  content: "\e837";
}
.material-icons.radio_button_off:before {
  content: "\e836";
}
.material-icons.radio_button_on:before {
  content: "\e837";
}
.material-icons.radio_button_unchecked:before {
  content: "\e836";
}
.material-icons.railway_alert:before {
  content: "\e9d1";
}
.material-icons.ramen_dining:before {
  content: "\ea64";
}
.material-icons.ramp_left:before {
  content: "\eb9c";
}
.material-icons.ramp_right:before {
  content: "\eb96";
}
.material-icons.rate_review:before {
  content: "\e560";
}
.material-icons.raw_off:before {
  content: "\f04f";
}
.material-icons.raw_on:before {
  content: "\f050";
}
.material-icons.read_more:before {
  content: "\ef6d";
}
.material-icons.real_estate_agent:before {
  content: "\e73a";
}
.material-icons.receipt:before {
  content: "\e8b0";
}
.material-icons.receipt_long:before {
  content: "\ef6e";
}
.material-icons.recent_actors:before {
  content: "\e03f";
}
.material-icons.recommend:before {
  content: "\e9d2";
}
.material-icons.record_voice_over:before {
  content: "\e91f";
}
.material-icons.rectangle:before {
  content: "\eb54";
}
.material-icons.recycling:before {
  content: "\e760";
}
.material-icons.reddit:before {
  content: "\eaa0";
}
.material-icons.redeem:before {
  content: "\e8b1";
}
.material-icons.redo:before {
  content: "\e15a";
}
.material-icons.reduce_capacity:before {
  content: "\f21c";
}
.material-icons.refresh:before {
  content: "\e5d5";
}
.material-icons.remember_me:before {
  content: "\f051";
}
.material-icons.remove:before {
  content: "\e15b";
}
.material-icons.remove_circle:before {
  content: "\e15c";
}
.material-icons.remove_circle_outline:before {
  content: "\e15d";
}
.material-icons.remove_done:before {
  content: "\e9d3";
}
.material-icons.remove_from_queue:before {
  content: "\e067";
}
.material-icons.remove_moderator:before {
  content: "\e9d4";
}
.material-icons.remove_red_eye:before {
  content: "\e417";
}
.material-icons.remove_road:before {
  content: "\ebfc";
}
.material-icons.remove_shopping_cart:before {
  content: "\e928";
}
.material-icons.reorder:before {
  content: "\e8fe";
}
.material-icons.repartition:before {
  content: "\f8e8";
}
.material-icons.repeat:before {
  content: "\e040";
}
.material-icons.repeat_on:before {
  content: "\e9d6";
}
.material-icons.repeat_one:before {
  content: "\e041";
}
.material-icons.repeat_one_on:before {
  content: "\e9d7";
}
.material-icons.replay:before {
  content: "\e042";
}
.material-icons.replay_10:before {
  content: "\e059";
}
.material-icons.replay_30:before {
  content: "\e05a";
}
.material-icons.replay_5:before {
  content: "\e05b";
}
.material-icons.replay_circle_filled:before {
  content: "\e9d8";
}
.material-icons.reply:before {
  content: "\e15e";
}
.material-icons.reply_all:before {
  content: "\e15f";
}
.material-icons.report:before {
  content: "\e160";
}
.material-icons.report_gmailerrorred:before {
  content: "\f052";
}
.material-icons.report_off:before {
  content: "\e170";
}
.material-icons.report_problem:before {
  content: "\e8b2";
}
.material-icons.request_page:before {
  content: "\f22c";
}
.material-icons.request_quote:before {
  content: "\f1b6";
}
.material-icons.reset_tv:before {
  content: "\e9d9";
}
.material-icons.restart_alt:before {
  content: "\f053";
}
.material-icons.restaurant:before {
  content: "\e56c";
}
.material-icons.restaurant_menu:before {
  content: "\e561";
}
.material-icons.restore:before {
  content: "\e8b3";
}
.material-icons.restore_from_trash:before {
  content: "\e938";
}
.material-icons.restore_page:before {
  content: "\e929";
}
.material-icons.reviews:before {
  content: "\f054";
}
.material-icons.rice_bowl:before {
  content: "\f1f5";
}
.material-icons.ring_volume:before {
  content: "\e0d1";
}
.material-icons.rocket:before {
  content: "\eba5";
}
.material-icons.rocket_launch:before {
  content: "\eb9b";
}
.material-icons.roller_shades:before {
  content: "\ec12";
}
.material-icons.roller_shades_closed:before {
  content: "\ec11";
}
.material-icons.roller_skating:before {
  content: "\ebcd";
}
.material-icons.roofing:before {
  content: "\f201";
}
.material-icons.room:before {
  content: "\e8b4";
}
.material-icons.room_preferences:before {
  content: "\f1b8";
}
.material-icons.room_service:before {
  content: "\eb49";
}
.material-icons.rotate_90_degrees_ccw:before {
  content: "\e418";
}
.material-icons.rotate_90_degrees_cw:before {
  content: "\eaab";
}
.material-icons.rotate_left:before {
  content: "\e419";
}
.material-icons.rotate_right:before {
  content: "\e41a";
}
.material-icons.roundabout_left:before {
  content: "\eb99";
}
.material-icons.roundabout_right:before {
  content: "\eba3";
}
.material-icons.rounded_corner:before {
  content: "\e920";
}
.material-icons.route:before {
  content: "\eacd";
}
.material-icons.router:before {
  content: "\e328";
}
.material-icons.rowing:before {
  content: "\e921";
}
.material-icons.rss_feed:before {
  content: "\e0e5";
}
.material-icons.rsvp:before {
  content: "\f055";
}
.material-icons.rtt:before {
  content: "\e9ad";
}
.material-icons.rule:before {
  content: "\f1c2";
}
.material-icons.rule_folder:before {
  content: "\f1c9";
}
.material-icons.run_circle:before {
  content: "\ef6f";
}
.material-icons.running_with_errors:before {
  content: "\e51d";
}
.material-icons.rv_hookup:before {
  content: "\e642";
}
.material-icons.safety_check:before {
  content: "\ebef";
}
.material-icons.safety_divider:before {
  content: "\e1cc";
}
.material-icons.sailing:before {
  content: "\e502";
}
.material-icons.sanitizer:before {
  content: "\f21d";
}
.material-icons.satellite:before {
  content: "\e562";
}
.material-icons.satellite_alt:before {
  content: "\eb3a";
}
.material-icons.save:before {
  content: "\e161";
}
.material-icons.save_alt:before {
  content: "\e171";
}
.material-icons.save_as:before {
  content: "\eb60";
}
.material-icons.saved_search:before {
  content: "\ea11";
}
.material-icons.savings:before {
  content: "\e2eb";
}
.material-icons.scale:before {
  content: "\eb5f";
}
.material-icons.scanner:before {
  content: "\e329";
}
.material-icons.scatter_plot:before {
  content: "\e268";
}
.material-icons.schedule:before {
  content: "\e8b5";
}
.material-icons.schedule_send:before {
  content: "\ea0a";
}
.material-icons.schema:before {
  content: "\e4fd";
}
.material-icons.school:before {
  content: "\e80c";
}
.material-icons.science:before {
  content: "\ea4b";
}
.material-icons.score:before {
  content: "\e269";
}
.material-icons.scoreboard:before {
  content: "\ebd0";
}
.material-icons.screen_lock_landscape:before {
  content: "\e1be";
}
.material-icons.screen_lock_portrait:before {
  content: "\e1bf";
}
.material-icons.screen_lock_rotation:before {
  content: "\e1c0";
}
.material-icons.screen_rotation:before {
  content: "\e1c1";
}
.material-icons.screen_rotation_alt:before {
  content: "\ebee";
}
.material-icons.screen_search_desktop:before {
  content: "\ef70";
}
.material-icons.screen_share:before {
  content: "\e0e2";
}
.material-icons.screenshot:before {
  content: "\f056";
}
.material-icons.screenshot_monitor:before {
  content: "\ec08";
}
.material-icons.scuba_diving:before {
  content: "\ebce";
}
.material-icons.sd:before {
  content: "\e9dd";
}
.material-icons.sd_card:before {
  content: "\e623";
}
.material-icons.sd_card_alert:before {
  content: "\f057";
}
.material-icons.sd_storage:before {
  content: "\e1c2";
}
.material-icons.search:before {
  content: "\e8b6";
}
.material-icons.search_off:before {
  content: "\ea76";
}
.material-icons.security:before {
  content: "\e32a";
}
.material-icons.security_update:before {
  content: "\f058";
}
.material-icons.security_update_good:before {
  content: "\f059";
}
.material-icons.security_update_warning:before {
  content: "\f05a";
}
.material-icons.segment:before {
  content: "\e94b";
}
.material-icons.select_all:before {
  content: "\e162";
}
.material-icons.self_improvement:before {
  content: "\ea78";
}
.material-icons.sell:before {
  content: "\f05b";
}
.material-icons.send:before {
  content: "\e163";
}
.material-icons.send_and_archive:before {
  content: "\ea0c";
}
.material-icons.send_time_extension:before {
  content: "\eadb";
}
.material-icons.send_to_mobile:before {
  content: "\f05c";
}
.material-icons.sensor_door:before {
  content: "\f1b5";
}
.material-icons.sensor_occupied:before {
  content: "\ec10";
}
.material-icons.sensor_window:before {
  content: "\f1b4";
}
.material-icons.sensors:before {
  content: "\e51e";
}
.material-icons.sensors_off:before {
  content: "\e51f";
}
.material-icons.sentiment_dissatisfied:before {
  content: "\e811";
}
.material-icons.sentiment_neutral:before {
  content: "\e812";
}
.material-icons.sentiment_satisfied:before {
  content: "\e813";
}
.material-icons.sentiment_satisfied_alt:before {
  content: "\e0ed";
}
.material-icons.sentiment_very_dissatisfied:before {
  content: "\e814";
}
.material-icons.sentiment_very_satisfied:before {
  content: "\e815";
}
.material-icons.set_meal:before {
  content: "\f1ea";
}
.material-icons.settings:before {
  content: "\e8b8";
}
.material-icons.settings_accessibility:before {
  content: "\f05d";
}
.material-icons.settings_applications:before {
  content: "\e8b9";
}
.material-icons.settings_backup_restore:before {
  content: "\e8ba";
}
.material-icons.settings_bluetooth:before {
  content: "\e8bb";
}
.material-icons.settings_brightness:before {
  content: "\e8bd";
}
.material-icons.settings_cell:before {
  content: "\e8bc";
}
.material-icons.settings_display:before {
  content: "\e8bd";
}
.material-icons.settings_ethernet:before {
  content: "\e8be";
}
.material-icons.settings_input_antenna:before {
  content: "\e8bf";
}
.material-icons.settings_input_component:before {
  content: "\e8c0";
}
.material-icons.settings_input_composite:before {
  content: "\e8c1";
}
.material-icons.settings_input_hdmi:before {
  content: "\e8c2";
}
.material-icons.settings_input_svideo:before {
  content: "\e8c3";
}
.material-icons.settings_overscan:before {
  content: "\e8c4";
}
.material-icons.settings_phone:before {
  content: "\e8c5";
}
.material-icons.settings_power:before {
  content: "\e8c6";
}
.material-icons.settings_remote:before {
  content: "\e8c7";
}
.material-icons.settings_suggest:before {
  content: "\f05e";
}
.material-icons.settings_system_daydream:before {
  content: "\e1c3";
}
.material-icons.settings_voice:before {
  content: "\e8c8";
}
.material-icons.severe_cold:before {
  content: "\ebd3";
}
.material-icons.share:before {
  content: "\e80d";
}
.material-icons.share_arrival_time:before {
  content: "\e524";
}
.material-icons.share_location:before {
  content: "\f05f";
}
.material-icons.shield:before {
  content: "\e9e0";
}
.material-icons.shield_moon:before {
  content: "\eaa9";
}
.material-icons.shop:before {
  content: "\e8c9";
}
.material-icons.shop_2:before {
  content: "\e19e";
}
.material-icons.shop_two:before {
  content: "\e8ca";
}
.material-icons.shopify:before {
  content: "\ea9d";
}
.material-icons.shopping_bag:before {
  content: "\f1cc";
}
.material-icons.shopping_basket:before {
  content: "\e8cb";
}
.material-icons.shopping_cart:before {
  content: "\e8cc";
}
.material-icons.shopping_cart_checkout:before {
  content: "\eb88";
}
.material-icons.short_text:before {
  content: "\e261";
}
.material-icons.shortcut:before {
  content: "\f060";
}
.material-icons.show_chart:before {
  content: "\e6e1";
}
.material-icons.shower:before {
  content: "\f061";
}
.material-icons.shuffle:before {
  content: "\e043";
}
.material-icons.shuffle_on:before {
  content: "\e9e1";
}
.material-icons.shutter_speed:before {
  content: "\e43d";
}
.material-icons.sick:before {
  content: "\f220";
}
.material-icons.sign_language:before {
  content: "\ebe5";
}
.material-icons.signal_cellular_0_bar:before {
  content: "\f0a8";
}
.material-icons.signal_cellular_4_bar:before {
  content: "\e1c8";
}
.material-icons.signal_cellular_alt:before {
  content: "\e202";
}
.material-icons.signal_cellular_alt_1_bar:before {
  content: "\ebdf";
}
.material-icons.signal_cellular_alt_2_bar:before {
  content: "\ebe3";
}
.material-icons.signal_cellular_connected_no_internet_0_bar:before {
  content: "\f0ac";
}
.material-icons.signal_cellular_connected_no_internet_4_bar:before {
  content: "\e1cd";
}
.material-icons.signal_cellular_no_sim:before {
  content: "\e1ce";
}
.material-icons.signal_cellular_nodata:before {
  content: "\f062";
}
.material-icons.signal_cellular_null:before {
  content: "\e1cf";
}
.material-icons.signal_cellular_off:before {
  content: "\e1d0";
}
.material-icons.signal_wifi_0_bar:before {
  content: "\f0b0";
}
.material-icons.signal_wifi_4_bar:before {
  content: "\e1d8";
}
.material-icons.signal_wifi_4_bar_lock:before {
  content: "\e1d9";
}
.material-icons.signal_wifi_bad:before {
  content: "\f063";
}
.material-icons.signal_wifi_connected_no_internet_4:before {
  content: "\f064";
}
.material-icons.signal_wifi_off:before {
  content: "\e1da";
}
.material-icons.signal_wifi_statusbar_4_bar:before {
  content: "\f065";
}
.material-icons.signal_wifi_statusbar_connected_no_internet_4:before {
  content: "\f066";
}
.material-icons.signal_wifi_statusbar_null:before {
  content: "\f067";
}
.material-icons.signpost:before {
  content: "\eb91";
}
.material-icons.sim_card:before {
  content: "\e32b";
}
.material-icons.sim_card_alert:before {
  content: "\e624";
}
.material-icons.sim_card_download:before {
  content: "\f068";
}
.material-icons.single_bed:before {
  content: "\ea48";
}
.material-icons.sip:before {
  content: "\f069";
}
.material-icons.skateboarding:before {
  content: "\e511";
}
.material-icons.skip_next:before {
  content: "\e044";
}
.material-icons.skip_previous:before {
  content: "\e045";
}
.material-icons.sledding:before {
  content: "\e512";
}
.material-icons.slideshow:before {
  content: "\e41b";
}
.material-icons.slow_motion_video:before {
  content: "\e068";
}
.material-icons.smart_button:before {
  content: "\f1c1";
}
.material-icons.smart_display:before {
  content: "\f06a";
}
.material-icons.smart_screen:before {
  content: "\f06b";
}
.material-icons.smart_toy:before {
  content: "\f06c";
}
.material-icons.smartphone:before {
  content: "\e32c";
}
.material-icons.smoke_free:before {
  content: "\eb4a";
}
.material-icons.smoking_rooms:before {
  content: "\eb4b";
}
.material-icons.sms:before {
  content: "\e625";
}
.material-icons.sms_failed:before {
  content: "\e626";
}
.material-icons.snapchat:before {
  content: "\ea6e";
}
.material-icons.snippet_folder:before {
  content: "\f1c7";
}
.material-icons.snooze:before {
  content: "\e046";
}
.material-icons.snowboarding:before {
  content: "\e513";
}
.material-icons.snowing:before {
  content: "\e80f";
}
.material-icons.snowmobile:before {
  content: "\e503";
}
.material-icons.snowshoeing:before {
  content: "\e514";
}
.material-icons.soap:before {
  content: "\f1b2";
}
.material-icons.social_distance:before {
  content: "\e1cb";
}
.material-icons.solar_power:before {
  content: "\ec0f";
}
.material-icons.sort:before {
  content: "\e164";
}
.material-icons.sort_by_alpha:before {
  content: "\e053";
}
.material-icons.sos:before {
  content: "\ebf7";
}
.material-icons.soup_kitchen:before {
  content: "\e7d3";
}
.material-icons.source:before {
  content: "\f1c4";
}
.material-icons.south:before {
  content: "\f1e3";
}
.material-icons.south_america:before {
  content: "\e7e4";
}
.material-icons.south_east:before {
  content: "\f1e4";
}
.material-icons.south_west:before {
  content: "\f1e5";
}
.material-icons.spa:before {
  content: "\eb4c";
}
.material-icons.space_bar:before {
  content: "\e256";
}
.material-icons.space_dashboard:before {
  content: "\e66b";
}
.material-icons.spatial_audio:before {
  content: "\ebeb";
}
.material-icons.spatial_audio_off:before {
  content: "\ebe8";
}
.material-icons.spatial_tracking:before {
  content: "\ebea";
}
.material-icons.speaker:before {
  content: "\e32d";
}
.material-icons.speaker_group:before {
  content: "\e32e";
}
.material-icons.speaker_notes:before {
  content: "\e8cd";
}
.material-icons.speaker_notes_off:before {
  content: "\e92a";
}
.material-icons.speaker_phone:before {
  content: "\e0d2";
}
.material-icons.speed:before {
  content: "\e9e4";
}
.material-icons.spellcheck:before {
  content: "\e8ce";
}
.material-icons.splitscreen:before {
  content: "\f06d";
}
.material-icons.spoke:before {
  content: "\e9a7";
}
.material-icons.sports:before {
  content: "\ea30";
}
.material-icons.sports_bar:before {
  content: "\f1f3";
}
.material-icons.sports_baseball:before {
  content: "\ea51";
}
.material-icons.sports_basketball:before {
  content: "\ea26";
}
.material-icons.sports_cricket:before {
  content: "\ea27";
}
.material-icons.sports_esports:before {
  content: "\ea28";
}
.material-icons.sports_football:before {
  content: "\ea29";
}
.material-icons.sports_golf:before {
  content: "\ea2a";
}
.material-icons.sports_gymnastics:before {
  content: "\ebc4";
}
.material-icons.sports_handball:before {
  content: "\ea33";
}
.material-icons.sports_hockey:before {
  content: "\ea2b";
}
.material-icons.sports_kabaddi:before {
  content: "\ea34";
}
.material-icons.sports_martial_arts:before {
  content: "\eae9";
}
.material-icons.sports_mma:before {
  content: "\ea2c";
}
.material-icons.sports_motorsports:before {
  content: "\ea2d";
}
.material-icons.sports_rugby:before {
  content: "\ea2e";
}
.material-icons.sports_score:before {
  content: "\f06e";
}
.material-icons.sports_soccer:before {
  content: "\ea2f";
}
.material-icons.sports_tennis:before {
  content: "\ea32";
}
.material-icons.sports_volleyball:before {
  content: "\ea31";
}
.material-icons.square:before {
  content: "\eb36";
}
.material-icons.square_foot:before {
  content: "\ea49";
}
.material-icons.ssid_chart:before {
  content: "\eb66";
}
.material-icons.stacked_bar_chart:before {
  content: "\e9e6";
}
.material-icons.stacked_line_chart:before {
  content: "\f22b";
}
.material-icons.stadium:before {
  content: "\eb90";
}
.material-icons.stairs:before {
  content: "\f1a9";
}
.material-icons.star:before {
  content: "\e838";
}
.material-icons.star_border:before {
  content: "\e83a";
}
.material-icons.star_border_purple500:before {
  content: "\f099";
}
.material-icons.star_half:before {
  content: "\e839";
}
.material-icons.star_outline:before {
  content: "\f06f";
}
.material-icons.star_purple500:before {
  content: "\f09a";
}
.material-icons.star_rate:before {
  content: "\f0ec";
}
.material-icons.stars:before {
  content: "\e8d0";
}
.material-icons.start:before {
  content: "\e089";
}
.material-icons.stay_current_landscape:before {
  content: "\e0d3";
}
.material-icons.stay_current_portrait:before {
  content: "\e0d4";
}
.material-icons.stay_primary_landscape:before {
  content: "\e0d5";
}
.material-icons.stay_primary_portrait:before {
  content: "\e0d6";
}
.material-icons.sticky_note_2:before {
  content: "\f1fc";
}
.material-icons.stop:before {
  content: "\e047";
}
.material-icons.stop_circle:before {
  content: "\ef71";
}
.material-icons.stop_screen_share:before {
  content: "\e0e3";
}
.material-icons.storage:before {
  content: "\e1db";
}
.material-icons.store:before {
  content: "\e8d1";
}
.material-icons.store_mall_directory:before {
  content: "\e563";
}
.material-icons.storefront:before {
  content: "\ea12";
}
.material-icons.storm:before {
  content: "\f070";
}
.material-icons.straight:before {
  content: "\eb95";
}
.material-icons.straighten:before {
  content: "\e41c";
}
.material-icons.stream:before {
  content: "\e9e9";
}
.material-icons.streetview:before {
  content: "\e56e";
}
.material-icons.strikethrough_s:before {
  content: "\e257";
}
.material-icons.stroller:before {
  content: "\f1ae";
}
.material-icons.style:before {
  content: "\e41d";
}
.material-icons.subdirectory_arrow_left:before {
  content: "\e5d9";
}
.material-icons.subdirectory_arrow_right:before {
  content: "\e5da";
}
.material-icons.subject:before {
  content: "\e8d2";
}
.material-icons.subscript:before {
  content: "\f111";
}
.material-icons.subscriptions:before {
  content: "\e064";
}
.material-icons.subtitles:before {
  content: "\e048";
}
.material-icons.subtitles_off:before {
  content: "\ef72";
}
.material-icons.subway:before {
  content: "\e56f";
}
.material-icons.summarize:before {
  content: "\f071";
}
.material-icons.sunny:before {
  content: "\e81a";
}
.material-icons.sunny_snowing:before {
  content: "\e819";
}
.material-icons.superscript:before {
  content: "\f112";
}
.material-icons.supervised_user_circle:before {
  content: "\e939";
}
.material-icons.supervisor_account:before {
  content: "\e8d3";
}
.material-icons.support:before {
  content: "\ef73";
}
.material-icons.support_agent:before {
  content: "\f0e2";
}
.material-icons.surfing:before {
  content: "\e515";
}
.material-icons.surround_sound:before {
  content: "\e049";
}
.material-icons.swap_calls:before {
  content: "\e0d7";
}
.material-icons.swap_horiz:before {
  content: "\e8d4";
}
.material-icons.swap_horizontal_circle:before {
  content: "\e933";
}
.material-icons.swap_vert:before {
  content: "\e8d5";
}
.material-icons.swap_vert_circle:before {
  content: "\e8d6";
}
.material-icons.swap_vertical_circle:before {
  content: "\e8d6";
}
.material-icons.swipe:before {
  content: "\e9ec";
}
.material-icons.swipe_down:before {
  content: "\eb53";
}
.material-icons.swipe_down_alt:before {
  content: "\eb30";
}
.material-icons.swipe_left:before {
  content: "\eb59";
}
.material-icons.swipe_left_alt:before {
  content: "\eb33";
}
.material-icons.swipe_right:before {
  content: "\eb52";
}
.material-icons.swipe_right_alt:before {
  content: "\eb56";
}
.material-icons.swipe_up:before {
  content: "\eb2e";
}
.material-icons.swipe_up_alt:before {
  content: "\eb35";
}
.material-icons.swipe_vertical:before {
  content: "\eb51";
}
.material-icons.switch_access_shortcut:before {
  content: "\e7e1";
}
.material-icons.switch_access_shortcut_add:before {
  content: "\e7e2";
}
.material-icons.switch_account:before {
  content: "\e9ed";
}
.material-icons.switch_camera:before {
  content: "\e41e";
}
.material-icons.switch_left:before {
  content: "\f1d1";
}
.material-icons.switch_right:before {
  content: "\f1d2";
}
.material-icons.switch_video:before {
  content: "\e41f";
}
.material-icons.synagogue:before {
  content: "\eab0";
}
.material-icons.sync:before {
  content: "\e627";
}
.material-icons.sync_alt:before {
  content: "\ea18";
}
.material-icons.sync_disabled:before {
  content: "\e628";
}
.material-icons.sync_lock:before {
  content: "\eaee";
}
.material-icons.sync_problem:before {
  content: "\e629";
}
.material-icons.system_security_update:before {
  content: "\f072";
}
.material-icons.system_security_update_good:before {
  content: "\f073";
}
.material-icons.system_security_update_warning:before {
  content: "\f074";
}
.material-icons.system_update:before {
  content: "\e62a";
}
.material-icons.system_update_alt:before {
  content: "\e8d7";
}
.material-icons.system_update_tv:before {
  content: "\e8d7";
}
.material-icons.tab:before {
  content: "\e8d8";
}
.material-icons.tab_unselected:before {
  content: "\e8d9";
}
.material-icons.table_bar:before {
  content: "\ead2";
}
.material-icons.table_chart:before {
  content: "\e265";
}
.material-icons.table_restaurant:before {
  content: "\eac6";
}
.material-icons.table_rows:before {
  content: "\f101";
}
.material-icons.table_view:before {
  content: "\f1be";
}
.material-icons.tablet:before {
  content: "\e32f";
}
.material-icons.tablet_android:before {
  content: "\e330";
}
.material-icons.tablet_mac:before {
  content: "\e331";
}
.material-icons.tag:before {
  content: "\e9ef";
}
.material-icons.tag_faces:before {
  content: "\e420";
}
.material-icons.takeout_dining:before {
  content: "\ea74";
}
.material-icons.tap_and_play:before {
  content: "\e62b";
}
.material-icons.tapas:before {
  content: "\f1e9";
}
.material-icons.task:before {
  content: "\f075";
}
.material-icons.task_alt:before {
  content: "\e2e6";
}
.material-icons.taxi_alert:before {
  content: "\ef74";
}
.material-icons.telegram:before {
  content: "\ea6b";
}
.material-icons.temple_buddhist:before {
  content: "\eab3";
}
.material-icons.temple_hindu:before {
  content: "\eaaf";
}
.material-icons.terminal:before {
  content: "\eb8e";
}
.material-icons.terrain:before {
  content: "\e564";
}
.material-icons.text_decrease:before {
  content: "\eadd";
}
.material-icons.text_fields:before {
  content: "\e262";
}
.material-icons.text_format:before {
  content: "\e165";
}
.material-icons.text_increase:before {
  content: "\eae2";
}
.material-icons.text_rotate_up:before {
  content: "\e93a";
}
.material-icons.text_rotate_vertical:before {
  content: "\e93b";
}
.material-icons.text_rotation_angledown:before {
  content: "\e93c";
}
.material-icons.text_rotation_angleup:before {
  content: "\e93d";
}
.material-icons.text_rotation_down:before {
  content: "\e93e";
}
.material-icons.text_rotation_none:before {
  content: "\e93f";
}
.material-icons.text_snippet:before {
  content: "\f1c6";
}
.material-icons.textsms:before {
  content: "\e0d8";
}
.material-icons.texture:before {
  content: "\e421";
}
.material-icons.theater_comedy:before {
  content: "\ea66";
}
.material-icons.theaters:before {
  content: "\e8da";
}
.material-icons.thermostat:before {
  content: "\f076";
}
.material-icons.thermostat_auto:before {
  content: "\f077";
}
.material-icons.thumb_down:before {
  content: "\e8db";
}
.material-icons.thumb_down_alt:before {
  content: "\e816";
}
.material-icons.thumb_down_off_alt:before {
  content: "\e9f2";
}
.material-icons.thumb_up:before {
  content: "\e8dc";
}
.material-icons.thumb_up_alt:before {
  content: "\e817";
}
.material-icons.thumb_up_off_alt:before {
  content: "\e9f3";
}
.material-icons.thumbs_up_down:before {
  content: "\e8dd";
}
.material-icons.thunderstorm:before {
  content: "\ebdb";
}
.material-icons.tiktok:before {
  content: "\ea7e";
}
.material-icons.time_to_leave:before {
  content: "\e62c";
}
.material-icons.timelapse:before {
  content: "\e422";
}
.material-icons.timeline:before {
  content: "\e922";
}
.material-icons.timer:before {
  content: "\e425";
}
.material-icons.timer_10:before {
  content: "\e423";
}
.material-icons.timer_10_select:before {
  content: "\f07a";
}
.material-icons.timer_3:before {
  content: "\e424";
}
.material-icons.timer_3_select:before {
  content: "\f07b";
}
.material-icons.timer_off:before {
  content: "\e426";
}
.material-icons.tips_and_updates:before {
  content: "\e79a";
}
.material-icons.tire_repair:before {
  content: "\ebc8";
}
.material-icons.title:before {
  content: "\e264";
}
.material-icons.toc:before {
  content: "\e8de";
}
.material-icons.today:before {
  content: "\e8df";
}
.material-icons.toggle_off:before {
  content: "\e9f5";
}
.material-icons.toggle_on:before {
  content: "\e9f6";
}
.material-icons.token:before {
  content: "\ea25";
}
.material-icons.toll:before {
  content: "\e8e0";
}
.material-icons.tonality:before {
  content: "\e427";
}
.material-icons.topic:before {
  content: "\f1c8";
}
.material-icons.tornado:before {
  content: "\e199";
}
.material-icons.touch_app:before {
  content: "\e913";
}
.material-icons.tour:before {
  content: "\ef75";
}
.material-icons.toys:before {
  content: "\e332";
}
.material-icons.track_changes:before {
  content: "\e8e1";
}
.material-icons.traffic:before {
  content: "\e565";
}
.material-icons.train:before {
  content: "\e570";
}
.material-icons.tram:before {
  content: "\e571";
}
.material-icons.transcribe:before {
  content: "\f8ec";
}
.material-icons.transfer_within_a_station:before {
  content: "\e572";
}
.material-icons.transform:before {
  content: "\e428";
}
.material-icons.transgender:before {
  content: "\e58d";
}
.material-icons.transit_enterexit:before {
  content: "\e579";
}
.material-icons.translate:before {
  content: "\e8e2";
}
.material-icons.travel_explore:before {
  content: "\e2db";
}
.material-icons.trending_down:before {
  content: "\e8e3";
}
.material-icons.trending_flat:before {
  content: "\e8e4";
}
.material-icons.trending_neutral:before {
  content: "\e8e4";
}
.material-icons.trending_up:before {
  content: "\e8e5";
}
.material-icons.trip_origin:before {
  content: "\e57b";
}
.material-icons.troubleshoot:before {
  content: "\e1d2";
}
.material-icons.try:before {
  content: "\f07c";
}
.material-icons.tsunami:before {
  content: "\ebd8";
}
.material-icons.tty:before {
  content: "\f1aa";
}
.material-icons.tune:before {
  content: "\e429";
}
.material-icons.tungsten:before {
  content: "\f07d";
}
.material-icons.turn_left:before {
  content: "\eba6";
}
.material-icons.turn_right:before {
  content: "\ebab";
}
.material-icons.turn_sharp_left:before {
  content: "\eba7";
}
.material-icons.turn_sharp_right:before {
  content: "\ebaa";
}
.material-icons.turn_slight_left:before {
  content: "\eba4";
}
.material-icons.turn_slight_right:before {
  content: "\eb9a";
}
.material-icons.turned_in:before {
  content: "\e8e6";
}
.material-icons.turned_in_not:before {
  content: "\e8e7";
}
.material-icons.tv:before {
  content: "\e333";
}
.material-icons.tv_off:before {
  content: "\e647";
}
.material-icons.two_wheeler:before {
  content: "\e9f9";
}
.material-icons.type_specimen:before {
  content: "\f8f0";
}
.material-icons.u_turn_left:before {
  content: "\eba1";
}
.material-icons.u_turn_right:before {
  content: "\eba2";
}
.material-icons.umbrella:before {
  content: "\f1ad";
}
.material-icons.unarchive:before {
  content: "\e169";
}
.material-icons.undo:before {
  content: "\e166";
}
.material-icons.unfold_less:before {
  content: "\e5d6";
}
.material-icons.unfold_more:before {
  content: "\e5d7";
}
.material-icons.unpublished:before {
  content: "\f236";
}
.material-icons.unsubscribe:before {
  content: "\e0eb";
}
.material-icons.upcoming:before {
  content: "\f07e";
}
.material-icons.update:before {
  content: "\e923";
}
.material-icons.update_disabled:before {
  content: "\e075";
}
.material-icons.upgrade:before {
  content: "\f0fb";
}
.material-icons.upload:before {
  content: "\f09b";
}
.material-icons.upload_file:before {
  content: "\e9fc";
}
.material-icons.usb:before {
  content: "\e1e0";
}
.material-icons.usb_off:before {
  content: "\e4fa";
}
.material-icons.vaccines:before {
  content: "\e138";
}
.material-icons.vape_free:before {
  content: "\ebc6";
}
.material-icons.vaping_rooms:before {
  content: "\ebcf";
}
.material-icons.verified:before {
  content: "\ef76";
}
.material-icons.verified_user:before {
  content: "\e8e8";
}
.material-icons.vertical_align_bottom:before {
  content: "\e258";
}
.material-icons.vertical_align_center:before {
  content: "\e259";
}
.material-icons.vertical_align_top:before {
  content: "\e25a";
}
.material-icons.vertical_distribute:before {
  content: "\e076";
}
.material-icons.vertical_shades:before {
  content: "\ec0e";
}
.material-icons.vertical_shades_closed:before {
  content: "\ec0d";
}
.material-icons.vertical_split:before {
  content: "\e949";
}
.material-icons.vibration:before {
  content: "\e62d";
}
.material-icons.video_call:before {
  content: "\e070";
}
.material-icons.video_camera_back:before {
  content: "\f07f";
}
.material-icons.video_camera_front:before {
  content: "\f080";
}
.material-icons.video_collection:before {
  content: "\e04a";
}
.material-icons.video_file:before {
  content: "\eb87";
}
.material-icons.video_label:before {
  content: "\e071";
}
.material-icons.video_library:before {
  content: "\e04a";
}
.material-icons.video_settings:before {
  content: "\ea75";
}
.material-icons.video_stable:before {
  content: "\f081";
}
.material-icons.videocam:before {
  content: "\e04b";
}
.material-icons.videocam_off:before {
  content: "\e04c";
}
.material-icons.videogame_asset:before {
  content: "\e338";
}
.material-icons.videogame_asset_off:before {
  content: "\e500";
}
.material-icons.view_agenda:before {
  content: "\e8e9";
}
.material-icons.view_array:before {
  content: "\e8ea";
}
.material-icons.view_carousel:before {
  content: "\e8eb";
}
.material-icons.view_column:before {
  content: "\e8ec";
}
.material-icons.view_comfortable:before {
  content: "\e42a";
}
.material-icons.view_comfy:before {
  content: "\e42a";
}
.material-icons.view_comfy_alt:before {
  content: "\eb73";
}
.material-icons.view_compact:before {
  content: "\e42b";
}
.material-icons.view_compact_alt:before {
  content: "\eb74";
}
.material-icons.view_cozy:before {
  content: "\eb75";
}
.material-icons.view_day:before {
  content: "\e8ed";
}
.material-icons.view_headline:before {
  content: "\e8ee";
}
.material-icons.view_in_ar:before {
  content: "\e9fe";
}
.material-icons.view_kanban:before {
  content: "\eb7f";
}
.material-icons.view_list:before {
  content: "\e8ef";
}
.material-icons.view_module:before {
  content: "\e8f0";
}
.material-icons.view_quilt:before {
  content: "\e8f1";
}
.material-icons.view_sidebar:before {
  content: "\f114";
}
.material-icons.view_stream:before {
  content: "\e8f2";
}
.material-icons.view_timeline:before {
  content: "\eb85";
}
.material-icons.view_week:before {
  content: "\e8f3";
}
.material-icons.vignette:before {
  content: "\e435";
}
.material-icons.villa:before {
  content: "\e586";
}
.material-icons.visibility:before {
  content: "\e8f4";
}
.material-icons.visibility_off:before {
  content: "\e8f5";
}
.material-icons.voice_chat:before {
  content: "\e62e";
}
.material-icons.voice_over_off:before {
  content: "\e94a";
}
.material-icons.voicemail:before {
  content: "\e0d9";
}
.material-icons.volcano:before {
  content: "\ebda";
}
.material-icons.volume_down:before {
  content: "\e04d";
}
.material-icons.volume_down_alt:before {
  content: "\e79c";
}
.material-icons.volume_mute:before {
  content: "\e04e";
}
.material-icons.volume_off:before {
  content: "\e04f";
}
.material-icons.volume_up:before {
  content: "\e050";
}
.material-icons.volunteer_activism:before {
  content: "\ea70";
}
.material-icons.vpn_key:before {
  content: "\e0da";
}
.material-icons.vpn_key_off:before {
  content: "\eb7a";
}
.material-icons.vpn_lock:before {
  content: "\e62f";
}
.material-icons.vrpano:before {
  content: "\f082";
}
.material-icons.wallet:before {
  content: "\f8ff";
}
.material-icons.wallet_giftcard:before {
  content: "\e8f6";
}
.material-icons.wallet_membership:before {
  content: "\e8f7";
}
.material-icons.wallet_travel:before {
  content: "\e8f8";
}
.material-icons.wallpaper:before {
  content: "\e1bc";
}
.material-icons.warehouse:before {
  content: "\ebb8";
}
.material-icons.warning:before {
  content: "\e002";
}
.material-icons.warning_amber:before {
  content: "\f083";
}
.material-icons.wash:before {
  content: "\f1b1";
}
.material-icons.watch:before {
  content: "\e334";
}
.material-icons.watch_later:before {
  content: "\e924";
}
.material-icons.watch_off:before {
  content: "\eae3";
}
.material-icons.water:before {
  content: "\f084";
}
.material-icons.water_damage:before {
  content: "\f203";
}
.material-icons.water_drop:before {
  content: "\e798";
}
.material-icons.waterfall_chart:before {
  content: "\ea00";
}
.material-icons.waves:before {
  content: "\e176";
}
.material-icons.waving_hand:before {
  content: "\e766";
}
.material-icons.wb_auto:before {
  content: "\e42c";
}
.material-icons.wb_cloudy:before {
  content: "\e42d";
}
.material-icons.wb_incandescent:before {
  content: "\e42e";
}
.material-icons.wb_iridescent:before {
  content: "\e436";
}
.material-icons.wb_shade:before {
  content: "\ea01";
}
.material-icons.wb_sunny:before {
  content: "\e430";
}
.material-icons.wb_twighlight:before {
  content: "\ea02";
}
.material-icons.wb_twilight:before {
  content: "\e1c6";
}
.material-icons.wc:before {
  content: "\e63d";
}
.material-icons.web:before {
  content: "\e051";
}
.material-icons.web_asset:before {
  content: "\e069";
}
.material-icons.web_asset_off:before {
  content: "\e4f7";
}
.material-icons.web_stories:before {
  content: "\e595";
}
.material-icons.webhook:before {
  content: "\eb92";
}
.material-icons.wechat:before {
  content: "\ea81";
}
.material-icons.weekend:before {
  content: "\e16b";
}
.material-icons.west:before {
  content: "\f1e6";
}
.material-icons.whatsapp:before {
  content: "\ea9c";
}
.material-icons.whatshot:before {
  content: "\e80e";
}
.material-icons.wheelchair_pickup:before {
  content: "\f1ab";
}
.material-icons.where_to_vote:before {
  content: "\e177";
}
.material-icons.widgets:before {
  content: "\e1bd";
}
.material-icons.width_full:before {
  content: "\f8f5";
}
.material-icons.width_normal:before {
  content: "\f8f6";
}
.material-icons.width_wide:before {
  content: "\f8f7";
}
.material-icons.wifi:before {
  content: "\e63e";
}
.material-icons.wifi_1_bar:before {
  content: "\e4ca";
}
.material-icons.wifi_2_bar:before {
  content: "\e4d9";
}
.material-icons.wifi_calling:before {
  content: "\ef77";
}
.material-icons.wifi_calling_3:before {
  content: "\f085";
}
.material-icons.wifi_channel:before {
  content: "\eb6a";
}
.material-icons.wifi_find:before {
  content: "\eb31";
}
.material-icons.wifi_lock:before {
  content: "\e1e1";
}
.material-icons.wifi_off:before {
  content: "\e648";
}
.material-icons.wifi_password:before {
  content: "\eb6b";
}
.material-icons.wifi_protected_setup:before {
  content: "\f0fc";
}
.material-icons.wifi_tethering:before {
  content: "\e1e2";
}
.material-icons.wifi_tethering_error:before {
  content: "\ead9";
}
.material-icons.wifi_tethering_error_rounded:before {
  content: "\f086";
}
.material-icons.wifi_tethering_off:before {
  content: "\f087";
}
.material-icons.wind_power:before {
  content: "\ec0c";
}
.material-icons.window:before {
  content: "\f088";
}
.material-icons.wine_bar:before {
  content: "\f1e8";
}
.material-icons.woman:before {
  content: "\e13e";
}
.material-icons.woo_commerce:before {
  content: "\ea6d";
}
.material-icons.wordpress:before {
  content: "\ea9f";
}
.material-icons.work:before {
  content: "\e8f9";
}
.material-icons.work_history:before {
  content: "\ec09";
}
.material-icons.work_off:before {
  content: "\e942";
}
.material-icons.work_outline:before {
  content: "\e943";
}
.material-icons.workspace_premium:before {
  content: "\e7af";
}
.material-icons.workspaces:before {
  content: "\e1a0";
}
.material-icons.workspaces_filled:before {
  content: "\ea0d";
}
.material-icons.workspaces_outline:before {
  content: "\ea0f";
}
.material-icons.wrap_text:before {
  content: "\e25b";
}
.material-icons.wrong_location:before {
  content: "\ef78";
}
.material-icons.wysiwyg:before {
  content: "\f1c3";
}
.material-icons.yard:before {
  content: "\f089";
}
.material-icons.youtube_searched_for:before {
  content: "\e8fa";
}
.material-icons.zoom_in:before {
  content: "\e8ff";
}
.material-icons.zoom_in_map:before {
  content: "\eb2d";
}
.material-icons.zoom_out:before {
  content: "\e900";
}
.material-icons.zoom_out_map:before {
  content: "\e56b";
}

.icone-nav {
  margin: 10px;
  padding: 0px 0px 0px 0px;
  cursor: pointer;
}

.espaco-nav {
  flex: 1 1 auto;
}

.mat-toolbar.header-primario .brand {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: flex-begin;
  align-content: flex-begin;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 0;
  position: relative;
}

.mat-toolbar.header-primario .logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: flex-begin;
  -ms-flex-pack: flex-begin;
  justify-content: flex-begin;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.mat-toolbar.header-primario .logo img {
  max-width: 180px;
  display: inline-block;
}

.mat-toolbar.header-primario {
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background: var(--secondary);
  background: linear-gradient(60deg, #032578 0%, #ff5a3a 100%);
}

.nav-primario {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  padding: 0;
  -ms-flex-line-pack: end;
  align-content: flex-end;
}

.nav-primario-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: flex-begin;
  align-content: flex-begin;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 10px;
  position: relative;
}

#profile-image1 {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border: 2px solid #fff;
}

.mat-sidenav {
  min-width: 250px;
  background: white;
  border-right: 7px solid var(--primary);
}
.mat-sidenav .mat-nav-list .mat-list-item:hover {
  background: var(--secondary);
}

.global-menu {
  background-color: #2f3333;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: darken;
  min-width: 480px;
}
.global-menu button,
.global-menu .mat-menu-item {
  color: white !important;
  text-decoration: none;
}
.global-menu .mat-menu-item-submenu-trigger::after {
  color: white !important;
}
.global-menu .mdc-list-item__primary-text {
  font-size: 14px !important;
}
.global-menu .mat-menu-submenu-icon {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  width: 5px;
  height: 10px;
  fill: white !important;
}
.global-menu .mat-menu-item-highlighted,
.global-menu .mat-menu-item:hover {
  background-color: rgba(215, 223, 35, 0.14);
  border-right: 4px solid var(--secondary);
}
.global-menu .mat-menu-content {
  height: 100%;
}

.sub-nav-nav {
  align-self: start;
  background-color: transparent;
  width: 100%;
  display: flex;
}
.sub-nav-nav .active {
  border: none !important;
}
.sub-nav-nav .active .submenu-icon {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sub-nav-nav button {
  color: white;
  display: flex;
  align-items: center;
  font-family: "Vorkurs";
  width: auto !important;
}
.sub-nav-nav button span {
  flex-grow: 1;
}
@media only screen and (max-width: 1030px) {
  .sub-nav-nav button {
    font-size: 11px !important;
  }
}
.sub-nav-nav button .submenu-icon {
  margin-right: 0;
}
.sub-nav-nav button:not(.has-subitem) .submenu-icon {
  display: none;
}
.sub-nav-nav .menu-nav:not(.open) {
  display: none;
}
.sub-nav-nav .mat-menu-item-highlighted,
.sub-nav-nav .active,
.sub-nav-nav .mat-menu-item:hover {
  background: linear-gradient(to right, var(--secondary), var(--primary) 100%);
  color: white;
  box-shadow: inset -1px 6px 20px 1px #101319;
}
.sub-nav-nav app-menu-nav .active {
  background: linear-gradient(to right, var(--secondary), var(--primary) 100%);
  color: white;
  box-shadow: inset -1px 6px 20px 1px #101319;
}
.sub-nav-nav .mat-menu-content {
  height: 100%;
}

.main-nav {
  background: #e0e0e0;
  display: flex;
  border-radius: 12px;
  font-family: "Vorkurs";
}
.main-nav > div {
  flex-grow: 1;
  display: flex;
  height: 40px;
  padding: 3px;
}
.main-nav button {
  border: none;
  flex-grow: 1;
  background: transparent;
  color: gray;
}
.main-nav .mat-menu-item-highlighted,
.main-nav .active,
.main-nav .mat-menu-item:hover {
  background-color: var(--primary);
  color: white;
  border-radius: 12px;
  font-weight: bold;
  box-shadow: 0px 0px 3px #737373;
}

app-menu-nav {
  background: rgba(255, 90, 58, 0.3);
}

app-menu-nav > app-menu-nav {
  width: 100%;
  align-self: flex-end;
}

.accordion {
  width: 100%;
  max-width: 360px;
  margin: auto;
  background: #fff;
  border-radius: 4px;
}

.accordion .menu {
  position: relative;
  padding: 15px 15px 15px 45px;
  color: #4d4d4d;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  transition: all 0.4s ease;
}

.accordion li:last-child .menu {
  border-bottom: 0;
}

.accordion li i {
  position: absolute;
  top: 1.2rem;
  left: 1rem;
  color: #595959;
  transition: all 0.4s ease;
}

.accordion li i.fa-chevron-down {
  right: 1rem;
  left: auto;
}

.accordion li.active i.fa-chevron-down {
  transform: rotate(180deg);
}

.accordion li.active .menu {
  color: #b63b4d;
}

.accordion li.active i {
  color: #b63b4d;
}

/* Show submenu */
.accordion li.active .submenu {
  /*
    height: 0 -> height: auto;는 transition이 동작하지 않는다.
    max-height: 임의의 높이;를 지정하면 transition이 동작하지만 타이밍이 망가진다.
    max-height: 1000px;과 max-height: 133px;을 비교해 보라!
    height를 1000px으로 transition할 시간에 실제로는 133px정도만 transition하므로 여는 시간이 닫는 시간보다 빠르다.
  */
  /* max-height: 1000px; */
  /* max-height: 133px; */
}

.submenu {
  height: 0;
  overflow: hidden;
  background: #444359;
  font-size: 14px;
  transition: height 0.4s ease;
}

.submenu li {
  border-bottom: 1px solid #4b4a5e;
}

.accordion li:last-child .submenu {
  border-radius: 0 0 4px 4px;
}

.accordion li:last-child .submenu li:last-child {
  border-bottom: 0;
}

.submenu a {
  display: block;
  text-decoration: none;
  color: #d9d9d9;
  padding: 12px;
  padding-left: 42px;
  transition: all 0.25s ease-in-out;
}

.submenu a:hover {
  background: #b63b4d;
  color: #fff;
}

.mat-table .td.mat-cell,
.mat-table td.mat-footer-cell,
.mat-table th.mat-header-cell {
  padding-bottom: 0px;
}

table {
  width: 100%;
}

.table-responsive .mat-mdc-table {
  overflow-x: scroll;
}
.table-responsive .mat-mdc-cell,
.table-responsive .mat-mdc-header-cell {
  word-wrap: initial;
  display: table-cell;
  padding: 0px 10px;
  line-break: unset;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
}
.table-responsive .mat-mdc-row,
.table-responsive .mat-header-row {
  display: table-row;
}

.toolbar-spacer {
  flex: 1 1 auto;
}

@font-face {
  font-family: "Vorkurs";
  src: url("../../../assets/fonts/ii-vorkurs-medium-webfont.woff2") format("woff2"), url("../../../assets/fonts/ii-vorkurs-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 800;
  line-height: 1.2;
  color: #032578;
}
h1[color=primary],
h2[color=primary],
h3[color=primary],
h4[color=primary],
h5[color=primary],
h6[color=primary],
.h1[color=primary],
.h2[color=primary],
.h3[color=primary],
.h4[color=primary],
.h5[color=primary],
.h6[color=primary] {
  color: var(--primary);
}
h1[color=primary] small,
h2[color=primary] small,
h3[color=primary] small,
h4[color=primary] small,
h5[color=primary] small,
h6[color=primary] small,
.h1[color=primary] small,
.h2[color=primary] small,
.h3[color=primary] small,
.h4[color=primary] small,
.h5[color=primary] small,
.h6[color=primary] small {
  color: var(--secondary);
}

h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 2rem;
}

h3,
.h3 {
  font-size: 1.75rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.hr-text {
  width: 100%;
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  border: none;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  padding-top: 24px;
  padding-bottom: 24px;
  align-items: center;
}
.hr-text::before {
  content: "";
  border-bottom: 1px solid gainsboro;
  flex: 1 0 auto;
  height: 0.5em;
  margin: 0;
  margin-top: -0.25em;
}
.hr-text::after {
  content: "";
  border-bottom: 1px solid gainsboro;
  flex: 1 0 auto;
  height: 0.5em;
  margin: 0;
  margin-top: -0.25em;
}
.hr-text span {
  text-align: center;
  flex: 0.2 0 auto;
  margin: 0;
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.mat-dialog-content a {
  color: var(--secondary);
}

.mat-dialog-title {
  color: var(--primary) !important;
}

.custom-modal {
  color: rgba(0, 0, 0, 0.56);
}
.custom-modal button {
  white-space: normal;
}
.custom-modal mat-divider {
  margin: 10px 0;
}
.custom-modal .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}
.custom-modal .header h1 {
  margin: 0;
  padding: 0;
  color: #3f4756;
}
.custom-modal .header mat-icon {
  background-color: transparent;
  background: transparent;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
}
.custom-modal .content {
  overflow-x: hidden;
}
.custom-modal .content input[type=file] {
  display: none;
}
.custom-modal .content h4 {
  font-size: 1rem;
}
.custom-modal .content .profile-photo {
  display: flex;
  justify-content: center;
  position: relative;
}
.custom-modal .content .profile-photo img,
.custom-modal .content .profile-photo .hover-image {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  object-fit: cover;
}
.custom-modal .content .profile-photo .hover-photo {
  height: auto;
  border-radius: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
}
.custom-modal .content .profile-photo .hover-photo:hover .photo-overlay,
.custom-modal .content .profile-photo .hover-photo:hover mat-icon,
.custom-modal .content .profile-photo .hover-photo:hover p {
  opacity: 1;
  transition: ease-in-out 0.4s;
}
.custom-modal .content .profile-photo .hover-photo:hover mat-icon {
  top: 35%;
}
.custom-modal .content .profile-photo .hover-photo:hover p {
  top: 60%;
}
.custom-modal .content .profile-photo .photo-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 100%;
  transition: ease-in-out 0.4s;
  opacity: 0;
}
.custom-modal .content .profile-photo mat-icon,
.custom-modal .content .profile-photo p {
  position: absolute;
  top: -100px;
  transition: ease-in-out 0.4s;
  opacity: 0;
  color: white;
}
.custom-modal .content label {
  margin: 0 10px 0 0;
}
.custom-modal .content.apontamento {
  padding: 0 24px 10px 24px !important;
}
.custom-modal .content.apontamento ngx-material-timepicker-toggle {
  position: absolute;
  top: 0px;
  right: 0px;
}
.custom-modal .content.apontamento ngx-material-timepicker-toggle svg {
  position: absolute;
  right: 5px;
  top: 6px;
  width: 15px;
  fill: #757575;
}
.custom-modal .content.apontamento .mat-divider {
  margin: 10px 0 0 0 !important;
}
.custom-modal .content.apontamento mat-select .mat-select-placeholder {
  position: absolute;
}
.custom-modal .content.apontamento mat-select .mat-select-value {
  height: 22px;
}
.custom-modal .content.apontamento textarea {
  resize: none;
}
.custom-modal .content .duas-colunas {
  display: flex;
}
.custom-modal .content .duas-colunas .item1 {
  padding-right: 5px;
  flex: 1;
}
.custom-modal .content .duas-colunas .item2 {
  padding-left: 5px;
  flex: 1;
}
@media only screen and (max-width: 767.98px) {
  .custom-modal .content .duas-colunas {
    flex-direction: column;
  }
  .custom-modal .content .duas-colunas .item1,
.custom-modal .content .duas-colunas .item2 {
    padding: 0;
  }
}
.custom-modal .ng-star-inserted,
.custom-modal .mat-input-element {
  color: rgba(0, 0, 0, 0.64);
}
.custom-modal mat-error {
  color: #ef5350 !important;
  font-size: 11.25px;
}

.icon-board {
  height: 15px;
  margin-right: 3px;
}

header .timer .circle-button {
  cursor: pointer;
  background: linear-gradient(to right, #fb5d38, #da2d0c) !important;
}
header .timer .circle-button .fas.fa-stop {
  color: var(--white) !important;
}
header .timer .circle-button:hover {
  background: #ffffff !important;
  color: #fb5d38;
}
header .timer .circle-button:hover .fas.fa-stop {
  color: var(--primary) !important;
}

.tarefa-item .timer .circle-button {
  cursor: pointer;
  background: linear-gradient(to right, #ccc, gainsboro) !important;
}
.tarefa-item .timer .circle-button:hover, .tarefa-item .timer .circle-button.btn-stop {
  background: linear-gradient(to right, #fb5d38, #da2d0c) !important;
  color: white;
}
.tarefa-item .timer .circle-button:hover .fas, .tarefa-item .timer .circle-button.btn-stop .fas {
  color: white !important;
}

.mat-button,
.mat-flat-button,
.mat-icon-button,
.mat-stroked-button {
  border-radius: 0px;
}

button.apontamento-add .mat-button-wrapper {
  position: absolute !important;
  bottom: -6px !important;
  left: 3px !important;
}
button.mat-fab, button.mat-mini-fab {
  box-shadow: none !important;
}
button.btn-rounded {
  border-radius: 25px;
}

@media print {
  .no-print {
    display: none !important;
  }
}