.mat-table {
  .td.mat-cell,
  td.mat-footer-cell,
  th.mat-header-cell {
    padding-bottom: 0px;
  }
}

table {
  width: 100%;
}

//Tables responsivas com scroll lateral;
.table-responsive {
  .mat-mdc-table {
    overflow-x: scroll;
  }

  .mat-mdc-cell,
  .mat-mdc-header-cell {
    word-wrap: initial;
    display: table-cell;
    padding: 0px 10px;
    line-break: unset;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: middle;
  }

  .mat-mdc-row,
  .mat-header-row {
    display: table-row;
  }
}