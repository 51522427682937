//Definir filtros na mesma linha;
.element-inline {
   h3 {
      margin-bottom: 0 !important;
   }

   display: flex;
   align-items: center;

   mat-form-field,
   mat-slide-toggle {
      flex: 1;
   }

   mat-form-field + mat-slide-toggle,
   mat-form-field + mat-form-field {
      margin-left: 15px;
   }

   @media screen and (max-width: 767.98px) {
      flex-direction: column;

      mat-form-field + mat-slide-toggle,
      mat-form-field + mat-form-field {
         margin-left: 0;
      }
   }
}

mat-form-field {
   width: 100%;

   .mat-form-field-wrapper {
      // top: -1.5em;
      padding-bottom: -10px;
   }
}

mat-card {
   &.grid-filters {
      margin-bottom: 0px;
   }

   .mat-form-field-flex {
      & > .mat-form-field-infix {
         padding: 0.4em 0px !important;
         height: 53px;
      }

      .mat-form-field-suffix {
         svg:not(.mat-datepicker-toggle-default-icon) {
            width: 1rem;
            fill: rgba(0, 0, 0, 0.54);
            position: absolute;
            bottom: -5px;
            left: -11px;
         }

         mat-icon {
            font-size: 1rem;
            color: rgba(0, 0, 0, 0.54);
            position: absolute;
            bottom: -11px;
            left: -18px;
         }
      }
   }

   // .mat-form-field:not(.no-margin) {
   //    .mat-form-field-label-wrapper {
   //       top: -1.5em;
   //    }
   // }

   .mat-form-field-wrapper {
      padding-bottom: 0.5em;
   }

   .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
      transform: translateY(-1.1em) scale(0.75);
      width: 133.33333%;
   }
   mat-card-actions {
      text-align: right;
   }
}

.timepicker__header {
   background: $secondary;
   background: linear-gradient(60deg, $secondary 0%, $primary 100%);
}

.clock-face__clock-hand {
   background-color: $primary !important;

   &::before {
      border: 4px solid $primary !important;
   }
}

.clock-face__number {
   .active {
      background-color: $primary !important;
   }
}

.timepicker-button,
.clock-hand-color {
   color: $primary !important;
}

//Definir filtros na mesma linha;
.element-inline {
   h3 {
      margin-bottom: 0 !important;
   }

   display: flex;
   align-items: center;

   mat-form-field,
   mat-slide-toggle {
      flex: 1;
   }

   mat-form-field + mat-slide-toggle,
   mat-form-field + mat-form-field {
      margin-left: 15px;
   }

   @media screen and (max-width: 767.98px) {
      flex-direction: column;

      mat-form-field + mat-slide-toggle,
      mat-form-field + mat-form-field {
         margin-left: 0;
      }
   }
}
