html,
body {
  width: 100%;
  height: 100%;
}

main {
  background: white;
}

header {
  .mat-expansion-panel-header-description,
  .mat-expansion-indicator::after {
    color: white;
  }
}

.mat-tab-label-content {
  font-family: "Vorkurs";
}

body {
  main {
    //padding-top: 25px;

    .mat-sidenav-content {
      background: white;
    }
  }

  app-header {
    position: relative;
    z-index: 9;
    .expansion-body {
      margin: -39px 0 0 0 !important;
      position: absolute !important;
      padding: 10px 0px 12px 10px;
      border-radius: 0 !important;
      border: 0 !important;
      box-shadow: 8px -7px 9px 2px #6384bf3b !important;
      background: lighten($secondary, 14%);
    }
  }

  :focus {
    outline: 0 !important;
  }

  table td {
    vertical-align: middle !important;
  }

  .mat-h2 {
    color: #455a64;
  }
}

.breadcrumb {
  font-size: 12px;
  padding: 0;
  border-radius: 0;
  background: transparent;
  margin: 0px;
}

.no-padding {
  padding: 0 !important;
}

mat-icon.help-icon {
  color: var(--primary);
  margin: 0 10px;
  cursor: pointer !important;
}

.position-help-icon {
  z-index: 999;
}

ngx-avatar .avatar-content {
  border-radius: 100% !important;
}

.sidebar-accordion {
  .mat-expansion-panel-content {
    > .mat-expansion-panel-body {
      padding: 0 !important;
    }
  }

  .mat-expansion-panel-header {
    .mat-content {
      overflow: unset !important;
    }

    &:hover {
      .mat-expansion-indicator:after {
        color: var(--secondary);
      }
    }

    .mat-expansion-indicator:after {
      color: var(--white);
    }
  }
}

.forum {
  .pergunta-container {
    .mat-content {
      padding: 10px 0;
    }
  }
}

.danger-snackbar {
  background-color: #ef5350 !important;
  white-space: pre-wrap;

  .mat-simple-snackbar-action {
    color: #fff !important;
  }
}

.success-snackbar {
  background-color: #28a745 !important;
  white-space: pre-wrap;

  .mat-simple-snackbar-action {
    color: #fff !important;
  }
}

.warning-snackbar {
  background-color: var(--primary);
  white-space: pre-wrap;
  color: white;

  .mat-simple-snackbar-action {
    color: white !important;
  }
}

.mat-card {
  .cotacao-filtros {
    .mat-form-field-wrapper {
      padding: 0;
    }
  }
}

tr.mat-header-row {
  height: 33px;
}

@media only screen and (min-width: 768px) {
  .mat-drawer-content {
    min-height: calc(100vh - 70px) !important;
  }
}

@media only screen and (min-width: 1024px) {
  // body {
  //   overflow: hidden;
  // }
}

.mat-drawer-side {
  border: 0;
}

.ps__rail-y {
  z-index: 10 !important;
}

.ng2-carouselamos {
  padding-left: 40px;
}

// .carousel-tarefas .ng2-carouselamos {
//   height: 75px;
// }

.ng2-carouselamos-wrapper {
  width: 100% !important;
}

.timepicker-backdrop-overlay,
.timepicker-overlay {
  z-index: 1000 !important;
}

.mat-dialog-content {
  padding: 10px 24px !important;
}

.ativo-inativo {
  width: 15px;
  height: 15px;
  border-radius: 100%;
}

.mat-column-ativo,
.mat-column-contabilizaHora,
.mat-column-clienteId,
.mat-column-admin,
.mat-column-progresso,
.mat-column-valor,
.mat-column-status,
.mat-column-dataSolicitacao,
.mat-column-dataPagamento {
  .mat-sort-header-container {
    justify-content: center !important;
  }
}

.cdk-overlay-container {
  z-index: 500 !important;
}

.ng2-carouselamos {
  padding-left: 39px;
}

.text-selection-none {
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.e-tooltip-wrap,
.e-tip-content {
  border-radius: 5px !important;
}

.quick-release {
  .mat-form-field-subscript-wrapper {
    margin-top: 1.2em !important;
  }
}

.devops-tfs,
.apontamento {
  .ng2-carouselamos-wrapper {
    background-color: lightgray;
  }

  .controls {
    height: 100% !important;

    button {
      height: 100%;
    }
  }

  .mat-slider-thumb-label-text {
    // opacity: 1 !important;
    font-size: 9px;
  }
  input[type="range"] {
    pointer-events: none;
  }
  input[type="range"]::-webkit-slider-thumb {
    pointer-events: all;
  }
  input[type="range"]::-moz-range-thumb {
    pointer-events: all;
  }
  input[type="range"]::-ms-thumb {
    pointer-events: all;
  }
}
// .mat-slider-thumb-label {
//     transform: rotate(45deg) !important;
//     border-radius: 50% 50% 0 !important;
//   }

// .mat-slider-thumb {
//     transform: scale(0) !important;
// }

.mat-expansion-panel-header-description {
  color: rgba(0, 0, 0, 0.87);
}

mat-card .mat-form-field-flex > .mat-form-field-infix {
  padding: 0.5em 0px !important;
}

.mat-expansion-panel-body {
  position: relative;
}

.mat-expansion-panel-description {
  align-items: center !important;
}

.td-field {
  padding: 0;

  .mat-form-field-wrapper {
    margin: 0;
  }

  &.td-data {
    width: 200px;

    .mat-form-field-infix {
      width: 135px;
    }
  }
}

.table-field {
  .mat-form-field-outline-start,
  .mat-form-field-outline-gap,
  .mat-form-field-outline-end {
    border: none !important;
  }

  & > div {
    padding: 0 !important;
  }

  .mat-checkbox-layout {
    margin-bottom: 0 !important;
  }
}

.filtros-apontamento {
  .mat-form-field-flex {
    max-width: 150px;
  }
}

.mat-form-field-subscript-wrapper {
  padding: 0 !important;
}

.multiline-tooltip {
  white-space: pre-line;
}

// //Kamban styles
// @import "~bootstrap/scss/functions";
// @import "~bootstrap/scss/variables";
// @import "~bootstrap/scss/mixins";
// @import "~bootstrap/scss/reboot";
