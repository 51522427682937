.icon-board {
  height: 15px;
  margin-right: 3px;
}

header {
  .timer .circle-button {
    cursor: pointer;
    .fas.fa-stop {
      color: var(--white) !important;
    }
    background: linear-gradient(to right, #fb5d38, #da2d0c) !important;
    &:hover {
      background: #ffffff !important;
      color: #fb5d38;
      .fas.fa-stop {
        color: var(--primary) !important;
      }
    }
  }
}

.tarefa-item {
  .timer .circle-button {
    cursor: pointer;
    background: linear-gradient(to right, #ccc, gainsboro) !important;

    &:hover,
    &.btn-stop {
      background: linear-gradient(to right, #fb5d38, #da2d0c) !important;
      color: white;
      .fas {
        color: white !important;
      }
    }
  }
}
