.mat-button,
.mat-flat-button,
.mat-icon-button,
.mat-stroked-button {
  border-radius: 0px;
}

button {
  &.apontamento-add {
    .mat-button-wrapper {
      position: absolute !important;
      bottom: -6px !important;
      left: 3px !important;
    }
  }

  &.mat-fab,
  &.mat-mini-fab {
    box-shadow: none !important;
  }

  &.btn-rounded {
    border-radius: 25px;
  }
}
