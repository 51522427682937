//--------------------------------------------------------
//--------------------------------------------------------
//-------------------------BOOTSTRAP----------------------
//--------------------------------------------------------
//--------------------------------------------------------
$primary: mat-color($app-theme-primary, default); //mat_color($app-theme-primary);
$secondary: mat-color($app-theme-accent, default); //mat-color($app-theme-accent);
//$primary: #042479; //mat_color($app-theme-primary);
//$secondary: #00b5e6; //mat-color($app-theme-accent);
$danger: mat-color($app-theme-warn);
$body-color: #666;

$font-family-sans-serif: Montserrat;
$font-family-monospace: Montserrat;
$font-family-base: $font-family-sans-serif !default;

$font-size-base: 1rem;
$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

$headings-color: $secondary;
$headings-font-weight: 800;

// importar bootstrap
@import "~bootstrap/scss/bootstrap.scss";
