.icone-nav {
  margin: 10px;
  padding: 0px 0px 0px 0px;
  cursor: pointer;
}

.espaco-nav {
  flex: 1 1 auto;
}

.mat-toolbar.header-primario .brand {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: flex-begin;
  align-content: flex-begin;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 0;
  position: relative;
}

.mat-toolbar.header-primario .logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: flex-begin;
  -ms-flex-pack: flex-begin;
  justify-content: flex-begin;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.mat-toolbar.header-primario .logo img {
  max-width: 180px;
  display: inline-block;
}

.mat-toolbar.header-primario {
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  //DEGRADE HEADER
  background: var(--secondary);
  background: linear-gradient(60deg, $secondary 0%, $primary 100%);
}

.nav-primario {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  padding: 0;
  -ms-flex-line-pack: end;
  align-content: flex-end;
}

.nav-primario-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: flex-begin;
  align-content: flex-begin;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 10px;
  position: relative;
}

#profile-image1 {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border: 2px solid #fff;
}

.mat-sidenav {
  min-width: 250px;
  background: white;
  border-right: 7px solid var(--primary);

  .mat-nav-list .mat-list-item:hover {
    background: var(--secondary);
  }
}

.global-menu {
  background-color: #2f3333;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: darken;
  min-width: 480px;

  button,
  .mat-menu-item {
    color: white !important;
    text-decoration: none;
  }

  .mat-menu-item-submenu-trigger::after {
    color: white !important;
  }

  .mdc-list-item__primary-text {
    // color: white !important;
    font-size: 14px !important;
  }

  .mat-menu-submenu-icon {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    width: 5px;
    height: 10px;
    fill: white !important;
    // color: white;
  }

  .mat-menu-item-highlighted,
  .mat-menu-item:hover {
    background-color: rgba(215, 223, 35, 0.14);
    border-right: 4px solid var(--secondary);
  }

  .mat-menu-content {
    height: 100%;
  }
}

.sub-nav-nav {
  align-self: start;
  background-color: transparent;
  width: 100%;
  display: flex;
  // flex-direction: column;
  .active {
    border: none !important;
    // border-bottom: 4px solid darken($primary, 20%) !important;
    // font-weight: 700;
    .submenu-icon {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
  button {
    color: white;
    display: flex;
    align-items: center;
    font-family: "Vorkurs";
    width: auto !important;
    span {
      flex-grow: 1;
    }

    @media only screen and (max-width: 1030px) {
      font-size: 11px !important;
    }

    .submenu-icon {
      margin-right: 0;
    }
    &:not(.has-subitem) {
      .submenu-icon {
        display: none;
      }
    }
  }
  .menu-nav:not(.open) {
    display: none;
  }

  .mat-menu-item-highlighted,
  .active,
  .mat-menu-item:hover {
    background: linear-gradient(to right, var(--secondary), var(--primary) 100%);
    color: white;
    box-shadow: inset -1px 6px 20px 1px #101319;
  }

  app-menu-nav .active {
    background: linear-gradient(to right, var(--secondary), var(--primary) 100%);
    color: white;
    box-shadow: inset -1px 6px 20px 1px #101319;
  }

  .mat-menu-content {
    height: 100%;
  }
}

.main-nav {
  background: #e0e0e0;
  display: flex;
  // border: 2px solid #d4d4d4;
  border-radius: 12px;
  font-family: "Vorkurs";
  > div {
    flex-grow: 1;
    display: flex;
    height: 40px;
    padding: 3px;
  }

  button {
    border: none;
    flex-grow: 1;
    background: transparent;
    color: gray;
  }

  .mat-menu-item-highlighted,
  .active,
  .mat-menu-item:hover {
    background-color: var(--primary);
    color: white;
    border-radius: 12px;
    font-weight: bold;
    box-shadow: 0px 0px 3px #737373;
    //background-color: rgba(215, 223, 35, 0.14);
    // border-right: 4px solid var(--secondary) !important;
    // color: var(--primary);
  }
}

app-menu-nav {
  background: transparentize($primary, 0.7);
}
app-menu-nav > app-menu-nav {
  width: 100%;
  align-self: flex-end;
}

// .sub-nav-nav {
//   @extend .global-menu;
//   background-color: gainsboro;
//   min-width: 100% !important;
//   width: 100%;
// }

.accordion {
  width: 100%;
  max-width: 360px;
  margin: auto;
  background: #fff;
  border-radius: 4px;
}

.accordion .menu {
  position: relative;
  padding: 15px 15px 15px 45px;
  color: #4d4d4d;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  transition: all 0.4s ease;
}

.accordion li:last-child .menu {
  border-bottom: 0;
}

.accordion li i {
  position: absolute;
  top: 1.2rem;
  left: 1rem;
  color: #595959;
  transition: all 0.4s ease;
}

.accordion li i.fa-chevron-down {
  right: 1rem;
  left: auto;
}

.accordion li.active i.fa-chevron-down {
  transform: rotate(180deg);
}

.accordion li.active .menu {
  color: #b63b4d;
}

.accordion li.active i {
  color: #b63b4d;
}

/* Show submenu */
.accordion li.active .submenu {
  /*
    height: 0 -> height: auto;는 transition이 동작하지 않는다.
    max-height: 임의의 높이;를 지정하면 transition이 동작하지만 타이밍이 망가진다.
    max-height: 1000px;과 max-height: 133px;을 비교해 보라!
    height를 1000px으로 transition할 시간에 실제로는 133px정도만 transition하므로 여는 시간이 닫는 시간보다 빠르다.
  */
  /* max-height: 1000px; */
  /* max-height: 133px; */
}

.submenu {
  height: 0;
  overflow: hidden;
  background: #444359;
  font-size: 14px;
  transition: height 0.4s ease;
}

.submenu li {
  border-bottom: 1px solid #4b4a5e;
}

.accordion li:last-child .submenu {
  border-radius: 0 0 4px 4px;
}

.accordion li:last-child .submenu li:last-child {
  border-bottom: 0;
}

.submenu a {
  display: block;
  text-decoration: none;
  color: #d9d9d9;
  padding: 12px;
  padding-left: 42px;
  transition: all 0.25s ease-in-out;
}

.submenu a:hover {
  background: #b63b4d;
  color: #fff;
}
